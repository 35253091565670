import isValidNationalIdentityNumber from '@core/form/utils/isValidNationalIdentityNumber';

import Validator from 'components/application/Validator';

export default Validator.extend({
    defaults: {
        message: ''
    },

    _validateValue(cpfCnpj) {
        return isValidNationalIdentityNumber(cpfCnpj);
    }
});
