import t from '@core/translations/translate';
import {
    CARD_HOLDER,
    ADDRESS,
    HOUSE_NUMBER,
    STREET,
    STATE,
    POSTAL_CODE,
    CITY,
    DNI_NUMBER,
    PHONE_NUMBER,
    COUNTRY_CODE
} from '@core/payment/forms/card/constants/fieldNames';

import RequiredValidator from 'components/application/validators/RequiredValidator';
import DniValidator from 'components/application/validators/DniValidator';
import StringValidator from 'components/application/validators/StringValidator';
import RegexpValidator from 'components/application/validators/RegexpValidator';
import currentUserCountry from '@core/user/profile/current/utils/currentUserCountry';
import CardHolderValidator from 'components/paymentPage/validators/CardHolderValidator';
import AddressValidator from './validators/AddressValidator';
import PhoneValidator from './validators/PhoneValidator';

/**
 * Translates for attributes for base translate
 * @type {object}
 */
const attributeTranslations = {
    [CARD_HOLDER]: () => t('paymentPage', 'text.name_on_card'),
    [ADDRESS]: () => t('paymentPage', 'text.address'),
    [HOUSE_NUMBER]: () => t('paymentPage', 'text.houseNumber'),
    [STREET]: () => t('paymentPage', 'text.street'),
    [POSTAL_CODE]: () => t('paymentPage', 'text.postal_code'),
    [STATE]: () => t('paymentPage', 'text.state'),
    [CITY]: () => t('paymentPage', 'text.city'),
    [PHONE_NUMBER]: () => t('paymentPage', 'text.phone_number'),
    [DNI_NUMBER]: () => t('paymentPage', 'text.cpf_number'),
    [COUNTRY_CODE]: () => t('paymentPage', 'text.country_code'),
};

/**
 * @param {string} formName
 * @return {object}
 * @private
 */
const getRequiredValidator = formName => {
    return {
        attributes: [formName],
        validator: new RequiredValidator({
            message: attributeTranslations[formName] ? t('paymentPage', 'error.is_required', {
                '{attribute}': attributeTranslations[formName](),
            }) : t('paymentPage', 'error.required_field'),
        }),
        scenario: 'initial'
    };
};

/**
 * @param {string} formName
 * @param {number} max
 * @param {string} key
 * @param {Boolean} shouldValidateChars
 * @return {object}
 * @private
 */
const getStringMaxValidator = (formName, max, key = '', shouldValidateChars = true) => {
    return {
        attributes: [formName],
        validator: new StringValidator({
            message: t('paymentPage', 'error.is_too_long', {
                '{attribute}': attributeTranslations[formName] ?
                    attributeTranslations[formName]() : '',
                '{max}': max,
            }),
            max,
            shouldValidateChars,
            key,
        }),
        scenario: 'initial',
    };
};

/**
 * @param {string} formName
 * @param {number} min
 * @param {string} key
 * @param {Boolean} shouldValidateChars
 * @return {object}
 * @private
 */
const getStringMinValidator = (formName, min, key = '', shouldValidateChars = true) => {
    return {
        attributes: [formName],
        validator: new StringValidator({
            message: t('paymentPage', 'error.is_too_short', {
                '{attribute}': attributeTranslations[formName](),
                '{min}': min,
            }),
            min,
            shouldValidateChars,
            key,
        }),
        scenario: 'initial',
    };
};

/**
 * @param {string} formName
 * @return {object}
 * @private
 */
const getWithoutSpacesValidator = formName => {
    return {
        attributes: [formName],
        validator: new RegexpValidator({
            message: t('paymentPage', 'error.is_incorrect', {
                '{attribute}': attributeTranslations[formName] ?
                    attributeTranslations[formName]() : '',
            }),
            regexp: new RegExp(/^\S*$/u),
        }),
        scenario: 'initial',
    };
};

/**
 * @param {string} fieldName
 * @param {boolean} isRequired
 * @param {number} maxVal
 * @return {[]}
 */
export const defaultMaxValidator = (fieldName, isRequired, maxVal) => {
    const additionalValidators = [];

    if (isRequired) {
        additionalValidators.push(getRequiredValidator(fieldName));
    }

    additionalValidators.push(getStringMaxValidator(fieldName, maxVal, ''));

    return additionalValidators;
};

/**
 * @param {string} fieldName
 * @param {boolean} isRequired
 * @param {number} maxVal
 * @return {[]}
 */
export const defaultAddressWithMaxValidator = (fieldName, isRequired, maxVal) => {
    const additionalValidators = [];

    if (isRequired) {
        additionalValidators.push(getRequiredValidator(fieldName));
    }

    additionalValidators.push({
        attributes: [fieldName],
        validator: new AddressValidator({
            message: t('paymentPage', 'error.invalid_characters', {
                '{attribute}': attributeTranslations[fieldName] ? attributeTranslations[fieldName]() : '',
            }),
            key: 'h1',
        }),
        scenario: 'initial',
    });
    additionalValidators.push(getStringMaxValidator(fieldName, maxVal, '', false));

    return additionalValidators;
};

/**
 * A class that contains all validators for additional fields custom from the backend
 * @class AdditionalFieldsValidationRules
 */
class AdditionalFieldsValidationRules {
    /**
     * @param {boolean} isRequired
     * @return {[]}
     */
    [CARD_HOLDER](isRequired) {
        const additionalValidators = [];

        if (isRequired) {
            additionalValidators.push(getRequiredValidator(CARD_HOLDER));
        }

        additionalValidators.push({
            attributes: [CARD_HOLDER],
            validator: new CardHolderValidator({
                message: t('paymentPage', 'text.invalid_name_on_card_selected'),
                key: 'h1',
            }),
            scenario: 'initial',
        });
        additionalValidators.push(getStringMinValidator(CARD_HOLDER, 4, 'h2'));
        additionalValidators.push(getStringMaxValidator(CARD_HOLDER, 64, 'h2'));

        return additionalValidators;
    }

    /**
     * @param {boolean} isRequired
     * @return {[]}
     */
    [ADDRESS](isRequired) {
        const additionalValidators = defaultAddressWithMaxValidator(ADDRESS, isRequired, 255);
        additionalValidators.push(getStringMinValidator(ADDRESS, 4, '', false));

        return additionalValidators;
    }

    /**
     * @param {boolean} isRequired
     * @return {[]}
     */
    [HOUSE_NUMBER](isRequired) {
        const additionalValidators = defaultMaxValidator(HOUSE_NUMBER, isRequired, 20);
        additionalValidators.push(getStringMinValidator(HOUSE_NUMBER, 1, ''));

        return additionalValidators;
    }

    /**
     * @param {boolean} isRequired
     * @return {[]}
     */
    [STREET](isRequired) {
        const additionalValidators = defaultAddressWithMaxValidator(STREET, isRequired, 40);
        additionalValidators.push(getStringMinValidator(STREET, 1, '', false));

        return additionalValidators;
    }

    /**
     * @param {boolean} isRequired
     * @return {[]}
     */
    [POSTAL_CODE](isRequired) {
        const additionalValidators = defaultAddressWithMaxValidator(POSTAL_CODE, isRequired, 20);
        additionalValidators.push(getStringMinValidator(POSTAL_CODE, 1, '', false));

        return additionalValidators;
    }

    /**
     * @param {boolean} isRequired
     * @return {[]}
     */
    [STATE](isRequired) {
        return defaultAddressWithMaxValidator(STATE, isRequired, 40);
    }

    /**
     * @param {boolean} isRequired
     * @return {[]}
     */
    [CITY](isRequired) {
        const additionalValidators = defaultAddressWithMaxValidator(CITY, isRequired, 40);
        additionalValidators.push(getStringMinValidator(CITY, 3, '', false));

        return additionalValidators;
    }

    /**
     * @param {boolean} isRequired
     * @return {[]}
     */
    [COUNTRY_CODE](isRequired) {
        return defaultMaxValidator(COUNTRY_CODE, isRequired, 40);
    }

    /**
     * @param {boolean} isRequired
     * @return {[]}
     */
    [PHONE_NUMBER](isRequired) {
        const additionalValidators = [];

        if (isRequired) {
            additionalValidators.push(getRequiredValidator(PHONE_NUMBER));
        }

        additionalValidators.push({
            attributes: [PHONE_NUMBER],
            validator: new PhoneValidator({
                message: t('paymentPage', 'error.invalid_characters', {
                    '{attribute}': attributeTranslations[PHONE_NUMBER] ? attributeTranslations[PHONE_NUMBER]() : '',
                }),
                key: 'h1',
            }),
            scenario: 'initial',
        });

        additionalValidators.push(getStringMaxValidator(PHONE_NUMBER, 15, ''));
        additionalValidators.push(getWithoutSpacesValidator(PHONE_NUMBER));

        return additionalValidators;
    }

    /**
     * @param {boolean} isRequired
     * @return {[]}
     */
    [DNI_NUMBER](isRequired) {
        const additionalValidators = [];

        if (isRequired) {
            additionalValidators.push(getRequiredValidator(DNI_NUMBER));
        }

        if (currentUserCountry() === 'BRA') {
            additionalValidators.push({
                attributes: ['dniNumber'],
                validator: new DniValidator({
                    message: t('paymentPage', 'error.is_incorrect', {
                        '{attribute}': attributeTranslations[DNI_NUMBER](),
                    })
                }),
                scenario: 'initial'
            });
        } else {
            additionalValidators.concat([{
                attributes: ['dniNumber'],
                validator: new StringValidator({
                    message: t('paymentPage', 'error.is_too_short', {
                        '{attribute}': attributeTranslations[DNI_NUMBER](),
                        '{min}': 4,
                    }),
                    min: 4,
                }),
                scenario: 'initial'
            }, {
                attributes: ['dniNumber'],
                validator: new StringValidator({
                    message: t('paymentPage', 'error.is_too_long', {
                        '{attribute}': attributeTranslations[DNI_NUMBER](),
                        '{max}': 20,
                    }),
                    max: 20,
                }),
                scenario: 'initial'
            }]);
        }

        return additionalValidators;
    }
}

export default AdditionalFieldsValidationRules;
