import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import PaymentOfferPopupLayout from '@phoenix/payment/widgets/paymentOfferPopup/components/PaymentOfferPopupLayout';
import TRACKING_NAME from '@phoenix/payment/widgets/paymentOfferPopup/constants/trackingName';

/**
 * open offer popup
 * @param {object} model
 * @param {object} data
 * @param {function} next
 */
const PaymentPagePackageOffer = (model, data, next) =>
    PopupService.openPopup(
        <PaymentOfferPopupLayout
            template={data.template}
            discount={50}
            price={{
                formatted: {
                    amount: data.discountedAmount?.amount || data.amount.amount,
                },
                currency: {
                    prefix: data.currencySign,
                },
            }}
            onClick={() => {
                model.set({
                    package_id: data.stockId,
                    currencyCode: data.currencySign,
                });

                PopupService.closePopup();
            }}
        />,
        {
            onClose: next,
            small: true,
            autoLoadTracking: true,
            trackingName: `payment_${TRACKING_NAME[data.template] ?? ''}Offer`,
        }
    )

export default PaymentPagePackageOffer;
