import PaymentPageAlternativePaymentModel from 'components/paymentPageExternal/models/PaymentPageAlternativePaymentModel';
import RequiredValidator from 'components/application/validators/RequiredValidator';
import RegexpValidator from 'components/application/validators/RegexpValidator';
import NumberValidator from 'components/application/validators/NumberValidator';
import StringValidator from 'components/application/validators/StringValidator';

/**
 * @class PaymentPageKonbiniPaymentModel
 */
export default PaymentPageAlternativePaymentModel.extend({

    rules: function() {
        return [
            {
                attributes: ['email'],
                validator: new RegexpValidator({
                    message: yiiT.t('paymentPage', 'error.email.invalid'),
                    regexp: new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')
                }),
                scenario: 'initial'
            },
            {
                attributes: ['firstName', 'lastName', 'phoneNumber', 'convenienceStore', 'email'],
                validator: new RequiredValidator({
                    message: yiiT.t('paymentPage', 'error.required_field')
                }),
                scenario: 'initial'
            },
            {
                attributes: ['phoneNumber'],
                validator: new StringValidator({
                    message: yiiT.t('paymentPage', 'error.phone.length', {
                        '{min}': 9,
                        '{max}': 11
                    }),
                    min: 9,
                    max: 11
                }),
                scenario: 'initial'
            },
            {
                attributes: ['phoneNumber'],
                validator: new NumberValidator({
                    message: yiiT.t('paymentPage', 'error.phone.invalid')
                }),
                scenario: 'initial'
            }

        ];
    }

});