import { IBAN_ONE_CLICK_FORM_NAME } from '@core/payment/payProcess/constants/formNames';

import PaymentPageAlternativePaymentModel from './PaymentPageAlternativePaymentModel';

/**
 * @class PaymentPageIbanPaymentModel
 * @see PaymentPageAlternativePaymentModel
 */
const PaymentPageIbanPaymentModel = PaymentPageAlternativePaymentModel.extend({
    /**
     * @override
     * @return {String}
     */
    url: function () {
        const urlRoot = this.__isIbanOneClickMethod() ? '/pay/ibanOneClick' : this.urlRoot;

        return `${urlRoot}?via=${this.get('via')}&prevVia=${this.get('prevVia')}`;
    },

    /**
    * @private
    * @return {String}
    */
    getFormName: function () {
        if (this.__isIbanOneClickMethod()) {
            return IBAN_ONE_CLICK_FORM_NAME;
        }

        return _.upperFirst(this._getMethod()) + this.FORM_NAME_SUFFIX;
    },

    /**
     * check if its oneClick and allowed method for iban api
     * @return {boolean}
     * @private
     */
    __isIbanOneClickMethod: function () {
        return this.isAltOneClick();
    },
});

export default PaymentPageIbanPaymentModel;
