import PaymentScenario from '@core/payment/common/constants/paymentScenario';

import PaymentPageUserFieldsPaymentModel from 'app/mobSite/components/paymentPageUserFields/models/PaymentPageUserFieldsPaymentModel';

var PaymentPageIdealPaymentModel = PaymentPageUserFieldsPaymentModel.extend({

    data: function() {
        const data = PaymentPageUserFieldsPaymentModel.prototype.data.call(this, arguments);
        const form = data[this.getFormName()];

        if (form.sepaMandateFields && form.scenario === PaymentScenario.ONECLICK) {
            form.isMaskedForm = true;
        }
        /**
         * Don't need in request
         */
        delete form.sepaMandateFields;

        delete form.altMethodsSettings;
        if (!form.bic) {
            delete form.bic;
        }

        return data;
    },

});
export default PaymentPageIdealPaymentModel;
