import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';

import MethodModel from './MethodModel';

/**
 * Manages active methods as well as their names
 * @class MethodsCollection
 * @see BaseCollection
 */
const MethodsCollection = BaseCollection.extend({
    model: MethodModel,

    /**
     * To delete models that did not come from the backend, and are no longer relevant in the collection,
     * it is simply impossible to rewrite the collection, since active methods may remain
     * @public
     * @param {array} methodList
     */
    removeNotReceived(methodList) {
        const methodsToRemove = difference(this.models.map(model => model.get('originalMethod')), methodList);

        methodsToRemove.forEach(originalMethod => {
            const model = this.find({
                originalMethod,
            });
            this.stopListening(model, 'change:isActive', this.__onChange);

            this.remove(model);
        });
    },

    /**
     * Adds only new methods to the collection so as not to change the activity status of existing ones
     * and not to duplicate existing ones
     * @public
     * @param {object} data
     */
    addNew(data) {
        const existsMethod = this.find(data);

        if (isEmpty(existsMethod)) {
            const model = this.add(data);

            this.listenTo(model, 'change:isActive', this.__onChange);
        }
    },

    /**
     * Sets active method (for active tab)
     * @public
     * @param {string} method
     */
    setActive(method) {
        this.each(model => model.set('isActive', model.get('originalMethod') === method));
    },

    /**
     * If the activity status has changed, a trigger is needed, otherwise not, so that there are no
     * rerender when the activity status is unchanged
     * @param {MethodModel} model
     * @private
     */
    __onChange(model) {
        if (model.get('isActive')) {
            this.trigger('activeMethodSet');
        }
    },

    /**
     * Gets the active entity of the method
     * @public
     * @return {MethodModel}
     */
    getActive() {
        return this.find({
            isActive: true,
        });
    },

    /**
     * Get method name which may not contain the original name, since used when cloning a method
     * @public
     * @return {string}
     */
    getActiveMethod() {
        const active = this.getActive();

        if (isEmpty(active)) {
            return '';
        }

        return active.get('method');
    },

    /**
     * Get original unique method name
     * @public
     * @return {string}
     */
    getActiveOriginalMethod() {
        const active = this.getActive();

        if (isEmpty(active)) {
            return '';
        }

        return active.get('originalMethod');
    },
});

export default MethodsCollection;
