import Validator from 'app/components/application/Validator';

/**
 * AddressValidator
 * @extends Validator
 */
const AddressValidator = Validator.extend({
    defaults: {
        message: ''
    },

    /**
     * @param {string} value
     * @return {boolean}
     * @protected
     */
    _validateValue(value) {
        return /^[\(\)№" ,/\\0-9A-Za-z]*$/.test(value);
    },
});

export default AddressValidator;
