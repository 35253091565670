const PaymentPageBinInfo = function (model, data, next) {
    const { acceptedCardLengths, financialServicePlatform, debitType, cardTypeLogo } = data;
    const bin = model.get('card_number').substring(0, 6);

    model.acceptedCardLengths(bin, acceptedCardLengths);
    model.setBinEntity({ bin, financialServicePlatform, debitType, cardTypeLogo });

    next();
};

export default PaymentPageBinInfo;
