import getThemeStyles from './getThemeStyles';
import Region from 'components/application/RegionLayout';
import tpl from 'components/application/tpl';

const themeStyles = getThemeStyles(['Loader']);

// The super method takes two parameters: a method name
// and an array of arguments to pass to the overridden method.
// This is to optimize for the common case of passing 'arguments'.
function _super(methodName, args) {

    // Keep track of how far up the prototype chain we have traversed,
    // in order to handle nested calls to _super.
    this._superCallObjects || (this._superCallObjects = {});
    var currentObject = this._superCallObjects[methodName] || this,
        parentObject  = findSuper(methodName, currentObject);
    this._superCallObjects[methodName] = parentObject;

    var result = parentObject[methodName].apply(this, args);
    delete this._superCallObjects[methodName];
    return result;
}

// Find the next object up the prototype chain that has a
// different implementation of the method.
function findSuper(methodName, childObject) {
    var object = childObject;
    while (object[methodName] === childObject[methodName]) {
        object = object.constructor.__super__;
    }
    return object;
}

var _configure = Backbone.View.prototype._configure;

// Saving Backbone original setElement function
var originalSetElementFunction = Backbone.View.prototype.setElement;

/**
 * @class BaseView
 * @type {Backbone.View}
 * @property {Object} collectionEvents
 * @property {Backbone.View} childView
 */
var BaseView = Backbone.View.extend({
    /**
     * Collection of modifications that will be processed by getCSSModifiers method.
     * Any value inside must return TRUE of FALSE
     * @example
     *
     * cssModifiers: {
     *     foo: true,
     *     bar: false
     *  }
     *
     * @see BaseView._setCSSModifiers()
     */
    cssModifiers: {},

    _super: _super,

    /**
     * @override
     * @return {string}
     * @protected
     */
    template: function () {
        return '';
    },

    placeholderCount: 1,

    /**
     * Renders React component into specified element using portal.
     * @param {HTMLElement} el - html element container
     * @param {Object} jsx - jsx to render
     * @param {string|number} [portalKey] - portal key. Use unique value to render multiple portals in the sme view.
     */
    renderPortal(el, jsx, portalKey = 0) {
        this.portals[portalKey]?.();
        this.portals[portalKey] = this.region.addPortal(el, jsx);
    },

    /**
     * @param options
     * @private
     */
    constructor: function(options) {
        /**
         * @private
         * Storage of 'removePortal' functions to cleanup elements created by {@see renderPortal}.
         */
        this.portals = {};
        var viewOptions = ['model', 'collection', 'el', 'id', 'attributes', 'className', 'tagName', 'events'];
        this.cid = _.uniqueId('view');
        if (this.options) options = _.extend({}, _.result(this, 'options'), options);
        _.extend(this, _.pick(options, viewOptions));
        this.options = options;
        this._initRegions();
        this._ensureElement();
        this.initialize.apply(this, arguments);
    },

    /**
     * @todo Check usage of that method. Remove if unused.
     * @param options
     * @private
     */
    _configure: function(options) {
        var recSetOptions = function(self) {
            var superOptions = self.constructor.__super__ ? recSetOptions(self.constructor.__super__) : {};
            return $.extend(true, superOptions, self.options);
        };
        this.options = recSetOptions(this);
        _configure.apply(this, arguments);
    },

    setOptions: function(options) {
        options && $.extend(true, this.options, options);
        return this;
    },

    permissionAction: function(data) {
        switch(data.type) {
            case 'message':
            case 'notification':
                var permission = data.permission;
                if( ! permission)
                    return;

                if(permission.send) {
                    window.app.router.navigate('/chat/with/'+ data.id, {
                        trigger: true
                    });
                    return;
                }
                if(permission.upgrade_url) {
                    window.app.goToPP(permission.upgrade_url);
                }

                break;
        }
    },

    /**
     * Get template for the view instance.
     * Useful to conditionally return different templates.
     *
     * @return {Function|void}
     */
    getTemplate: function () {
        return this.template;
    },

    /**
     * Get template for the view instance.
     * Useful to conditionally return different templates.
     *
     * @return {Function|void}
     */
    getPlaceholderTemplate: function () {
        return this.placeholderTemplate;
    },

    /**
     * @return jQuery selector
     */
    getPlaceholderContainer: function () {
        return this.placeholderContainer ? this.$(this.placeholderContainer) : this.$el;
    },

    /**
     * @param data
     * @return {BaseView}
     * @protected
     */
    renderTemplate: function(data) {
        this.closeRegions();
        this.$el.html(tpl.render(this.getTemplate(), data || {}));
        return this;
    },

    /**
     * @return {BaseView}
     * @protected
     */
    renderPlaceholder: function(data = {}) {
        const placeHolderTemplate = this.getPlaceholderTemplate();

        if (!placeHolderTemplate) {
            return this;
        }

        let placeholder = tpl.render(placeHolderTemplate, data);
        placeholder = Array(this.placeholderCount + 1).join(placeholder);
        this.getPlaceholderContainer().html(placeholder);

        this.region.ready();
        return this;
    },

    /**
     * Close all inner regions
     * @protected
     */
    closeRegions: function () {
        _.each(this.regions, function (region) {
            region.close();
        });
    },

    /**
      * You can lock button to avoid double tap or click
      * This method add only flag
      * You need to manual add checking for this flag with isButtonLock
      *
      * @example
      * var TestView = BaseView.extend({
     *     events: {
     *         'click ._save': 'save'
     *     },
     *
     *     save: function() {
     *         if (this.isButtonLock('save')) {
     *             return;
     *         }
     *         this.model.save(null, {
     *             beforeSend: this.lockButton.bind(this, 'save'),
     *             complete: this.unlockButton.bind(this, 'save')
     *         });
     *     },
     * });
     *
     * @param key {string} button unique key
     * @param {Object} [options]
     * @returns {BaseView}
     */
    lockButton: function(key, options) {
        if (!this.isButtonLock(key)) {
            this._getLockButtons()[key] = options;
        }
        return this;
    },

    /**
     * Lock button handler for protection double tap or click
     *
     * @example
     *
     *var TestView = BaseView.extend({
     *     //'profileSaveClick' - it is handler of click and it's the lock buttonKey
     *     //
     *     events: {
     *         'click #profileInfoSave': function(event){this.lockHandler('profileSaveClick', event)},
     *         'click ._save': function(event){this.lockHandler('save', event, options)},
     *     },
     *
     *     options.buttonKey - Alias for lock button
     *     options.onLock - function that call when we lock button
     *     options.onUnlock - function that call when we unlock button
     *
     *     //event - required param
     *     //event.buttonKey = profileSaveClick
     *     profileSaveClick: function(event) {
     *         event.preventDefault();
     *         this.model.save(null, {
     *             complete: event.unlockButton
     *         });
     *     },
     *
     *     //event.buttonKey = saveKey
     *     save: function(event) {
     *         this.model.save(null, {
     *             complete: event.unlockButton
     *         });
     *     },
     *});
     *
     *
     * @param handler
     * @param event
     * @param {Object} [options]
     * @param {String} [options.buttonKey]
     * @param {Function} [options.onLock]
     * @param {Function} [options.onUnlock]
     */
    lockHandler: function(handler, event, options = {}) {
        let buttonKey = options.buttonKey;
        buttonKey = buttonKey || handler;
        if (this.isButtonLock(buttonKey)) return;
        this.lockButton(buttonKey, options);
        /**
         * Call callback if exists
         */
        _.result(options, 'onLock');

        _.extend(event, {
            buttonKey : buttonKey,
            unlockButton: this.unlockButton.bind(this, buttonKey)
        });

        if (_.isFunction(handler)) {
            handler(event);
        } else {
            this[handler](event);
        }
    },

    /**
     * Unlock button
     * This method only remove flag
     * @see BaseView.lockButton example of use
     * @param key button unique key
     * @returns {BaseView}
     */
    unlockButton: function(key) {
        const options = this._getLockButtons()[key];
        delete this._getLockButtons()[key];

        /**
         * Call callback if exists
         */
        _.result(options, 'onUnlock');
        return this;
    },

    /**
     * This method only check flag
     * @see BaseView.lockButton example of use
     * @param key button unique key
     * @returns {boolean} is button lock
     */
    isButtonLock: function(key) {
        return _.has(this._getLockButtons(), key);
    },

    /**
     * @returns {Array} array of lock buttons
     * @private
     */
    _getLockButtons: function() {
        if (!this._lockButtons) {
            this._lockButtons = {};
        }
        return this._lockButtons;
    },

    /**
     * Clear region
     * @protected
     */
    _resetElement: function() {
        this._removeElement();
        this._ensureElement();
    },

     /**
      * Helper method for composing CSS-modules classes on wrapping div created with backbone.
      * Using BaseView.cssModifiers tests them, and for all truthful creates modifier.
      * Then apply modifiers to this.$el
      */
     _setCSSModifiers: function() {
        var modifiers = [];

        for (var modifier in this.cssModifiers) {
            if (this.cssModifiers[modifier]) {
                modifiers.push(modifier);
            }
        }

        modifiers = modifiers.join(' ');

        if (!_.isEmpty(modifiers)) {
            this.$el.addClass(modifiers);
        }
    },

     /**
      * Remove css modifiers
      */
     _resetCSSModifiers: function() {
        var modifiers = [];

        for (var modifier in this.cssModifiers) {
            if (this.cssModifiers[modifier] === false) {
                modifiers.push(modifier);
            }
        }

        modifiers = modifiers.join(' ');

        if (!_.isEmpty(modifiers)) {
            this.$el.removeClass(modifiers);
        }
    },

    applyAccordion: function() {
        var elements = this.$('[data-accordion]');
        elements.each(function() {
            var self = $(this);
            self.on('click', '[data-accordion-label]', function() {
                if(self.hasClass('active')) {
                    self.removeClass('active');
                    return;
                }
                elements.removeClass('active');
                self.addClass('active');
            });
        });
    },

    selectRange: function() {
        var to = this.$('[data-select=to] select'),
            from = this.$('[data-select=from] select');

        to.on('change', function(){
            var toValue = to.val();
            if(toValue < from.val()) {
                from.val(toValue);
                from.trigger('change');
            }
        });

        from.on('change', function(){
            var fromValue = from.val();
            if(fromValue > to.val()) {
                to.val(fromValue);
                to.trigger('change');
            }
        });
    },

    selectWrap: function() {
        var selects = this.$('[data-select]');
        selects.each(function(){
            var self = $(this),
                select = self.find('select');
            select.on('change', function() {
                self.find('[data-select-value]').html(select.find("option:selected").text());
            });
            select.trigger('change');
        });
        this.selectRange();
    },

    radioButton: function() {
        var $radioBlock = this.$('[data-radio]');
        $radioBlock.find('input').on('change', function(){
            $radioBlock.find('[data-check]').removeClass('active');
            var $checked = $radioBlock.find('input:checked');
            if( ! $checked.length)
                $radioBlock.find('input:first').trigger('click');
            $radioBlock.find('input:checked').closest('[data-check]').addClass('active');
        });
        $radioBlock.find('input:first').trigger('change');
    },

    initCheckbox: function() {
        var $inputs = this.$('[data-element=checkbox] input');
        $inputs.on('change', function() {
            var $container = $(this).closest('[data-check]');
            if ($(this).attr('checked')) {
                $container.addClass('active');
            } else {
                $container.removeClass('active');
            }
        });
        $inputs.trigger('change');
    },

    initTabs: function() {
        var tabs = this.$('[data-tabs-list] li');
        var content = this.$("[data-tabs-content]");
        tabs.on('click', function(){
            tabs.removeClass('active');
            content.find('[data-tab]').removeClass('active');
            $(this).addClass('active');
            var data = $(this).attr('data-tabs-opener');
            content.find('[data-tab='+data+']').addClass('active');
        });
        this.$('[data-tabs-list] li:first').trigger('click');
    },

    renderCustomToolbar: function(data) {
        Backbone.trigger('CustomToolbar', data);
    },

    touchEvent: function(e) {
        return e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
    },

    initInputEvents: function() {
        this.$('input,textarea')
            .on('focus', function(){
                Backbone.trigger('input:focus', {elem: this});
            })
            .on('blur', function(){
                Backbone.trigger('input:blur', {elem: this});
            });
    },

    /**
     * @private
     */
    _render: function() {
        this._setCSSModifiers();
        return this.render();
    },

    _initRegions: function () {
        this.region = (this.options || {}).region;

        var regions = {};

        if(this.regions) {
            _.each(this.regions, _.bind(function(value, key) {
                regions[key] = new Region(value, this.$el, this.options.addPortal || this.region.addPortal);
            }, this));

            this.regions = regions;
        }
    },

    /**
     * @private
     */
    renewalRegionsParentEl: function() {
        if (!this.regions) return;
        _.each(this.regions, _.bind(function(region) {
            region.$parentEl = this.$el;
        }, this));
    },

    // Change the view's element (`this.el` property), including event
    // re-delegation.
    setElement: function(element, delegate) {
        // call original setElement method
        originalSetElementFunction.apply(this, arguments);

        this.renewalRegionsParentEl();

        return this;
    },

    /**
     * @param {bool} preserveDOM
     *   If true - no DOM manipulations will be performed.
     *   This attribute is needed to decrease quantity of DOM removings on
     *   recursive region close.
     *   For e.g. when user navigate from search to chat without this feature
     *   will be performed ~1500 DOM removings. With this - around 500.
     *
     *   For more info:
     *   @see RegionLayout::close
     *
     * @overriden
     */
    remove: function (preserveDOM = false) {
        this.removePortals();

        this.trigger('remove');

        _.each(this.regions, function(region) {
            region.close(true);
        });

        this.region.views = _.without(this.region.views, this);
        this.region.widgets = _.without(this.region.widgets, this);

        this.undelegateEvents();

        if (!preserveDOM) {
            this._removeElement();
        }

        this.stopListening();
    },

    /**
     * @public
     *  @param {String} key
     */
    removePortal(key) {
        this.portals[key]?.();
    },

    /**
     *  Remove all portals created by renderPortal.
     */
    removePortals: function() {
        Object.keys(this.portals).forEach(portalKey => {
            this.removePortal(portalKey);
        });
        this.portals = {};
    },

    _removeElement: function() {
        if (this.$el.get(0) != this.region.$el().get(0)) {
            this.$el.remove();
        } else {
            this.$el.empty();
        }
    },

    loading: function () {
        this.$el.addClass(themeStyles.loader.active);
    },

    ready: function () {
        this.$el.removeClass(themeStyles.loader.active);
    },

    _setAttributes: function (attributes) {
        if (_.isArray(attributes['class'])) {
            attributes['class'] = attributes['class'].join(' ');
        }
        Backbone.View.prototype._setAttributes.call(this, attributes);
    },

});

export default BaseView;
