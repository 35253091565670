import PaymentPageModel from 'app/mobSite/components/paymentPage/models/PaymentPageModel.js';
import PaymentPageFeaturesModel from 'app/mobSite/components/paymentPage/models/PaymentPageFeaturesModel.js';
import PaymentPageRemarketingOfferModel from 'app/mobSite/components/paymentPageRemarketingOffer/models/PaymentPageRemarketingOfferModel';
import PaymentPageMicroFeaturesModel from '@legacy/application/mobSite/components/paymentPage/models/PaymentPageMicroFeaturesModel';
import PaymentPageCreditModel from 'app/mobSite/components/paymentPage/models/PaymentPageCreditModel';
import isNull from 'lodash/isNull';
import logger from '@core/logger';
import { getClientInstance } from '@core/graphql/client';
import IS_PAID_QUERY from '@phoenix/application/graphql/queries/isPaid.gql';

/**
 * Get payment page model, according user payment status
 * @public
 * @param {object} params
 * @param {null|bool} isPaid - Need to determinate valid model by param, when returning by back button,
 *                             you need to bind the model to the url parameters, and not to the status
 * @return {PaymentPageModel}
 */
const getModelByStatus = (params = {}, isPaid = null) => {
    let isUserPaid = isPaid;
    if (isNull(isPaid)) {
        const data = getClientInstance().readQuery({ query: IS_PAID_QUERY });
        isUserPaid = data.payment.accountStatus.isPaid;
    }

    return isUserPaid ?
        window.app.instanceRegister.get('PaymentPageFeaturesModel', PaymentPageFeaturesModel, params) :
        window.app.instanceRegister.get('PaymentPageModel', PaymentPageModel, params);
};

var PaymentPageModelFactory = function() {};

_.extend(PaymentPageModelFactory.prototype, {
    /**
     * @public
     * @param paymentStage {string}
     * @param params {origin}
     * @return {*|BaseModel|*}
     */
    getModel: function (paymentStage, params = {}) {
        if (!paymentStage) {
            logger.sendWarning('Trying to run PaymentPageModelFactory with undefined paymentStage');
        }

        switch (paymentStage) {
            case 'features':
                return window.app.instanceRegister.get('PaymentPageFeaturesModel', PaymentPageFeaturesModel, params);
            case 'remarketingOffer':
                return window.app.instanceRegister.get('PaymentPageRemarketingOfferModel', PaymentPageRemarketingOfferModel, params);
            case 'microFeatures':
                return window.app.instanceRegister.get('PaymentPageMicroFeaturesModel', PaymentPageMicroFeaturesModel, params);
            case 'credit':
                return window.app.instanceRegister.get('PaymentPageCreditModel', PaymentPageCreditModel, params);
            default:
                return window.app.instanceRegister.get('PaymentPageModel', PaymentPageModel, params);
        }
    },

    /**
     * @public
     * @see getModelByStatus function
     */
    getModelByStatus,
});

/**
 * @static
 * @public
 * @see getModelByStatus function
 * @return {PaymentPageModel}
 */
PaymentPageModelFactory.getModelByStatus = getModelByStatus;

export default PaymentPageModelFactory;
