import isEmpty from 'lodash/isEmpty';

import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import currentUserCountry from '@core/user/profile/current/utils/currentUserCountry';

import currentUserId from 'components/cache/currentUserId';
import locale from 'components/cache/locale';
import siteName from 'components/cache/siteName';
import PaymentPagePaymentBaseModel from 'components/paymentPage/models/PaymentPagePaymentBaseModel';

const PaymentPageAlternativePaymentModel = PaymentPagePaymentBaseModel.extend({
    urlRoot: '/pay/alternative/isAjaxMode/true',

    defaults: {
        /** pointer on payment stage (membership, features, etc.) */
        via: '',
    },

    url: function () {
        return `${this.urlRoot}?via=${this.get('via')}&prevVia=${this.get('prevVia')}`;
    },


    data: function () {
        const data = this.toJSON();

        if (data.withRedirectPayment) {
            delete data.withRedirectPayment;
        }

        if (isEmpty(data.selectedAdditionalPackages)) {
            delete data.selectedAdditionalPackages;
        }

        return {
            [this.getFormName()]: data,
            forceRedirect: this.FORCE_REDIRECT,
            processor: this._getMethod(),
        };
    },

    /**
     * @public
     * @return {boolean}
     */
    isOneclick: function () {
        return this.get('scenario') === PaymentScenario.ONECLICK;
    },

    /**
     * @param selectedPackage {object}
     * @param {Array} selectedAdditionalPackages
     * @param completeCallback {function}
     * @public
     */
    pay: function (selectedPackage, selectedAdditionalPackages, completeCallback, prevVia) {
        this.save({
            package_id: selectedPackage.get('packageId'),
            selectedAdditionalPackages,
            user_id: currentUserId(),
            product_id: selectedPackage.get('package').permission_set_id,
            domain: siteName(),
            currency_code: currentUserCountry(),
            locale: locale(),
            prevVia,
        }, {
            complete: completeCallback,
        });
    }

});

export default PaymentPageAlternativePaymentModel;
