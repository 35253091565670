/**
 * User interface used on payment page.
 */
var PaymentPageUserModel = BaseModel.extend({
    defaults: {
        id: null,
        screenName: null,
        age: null,
        geo: null,
        statuses: null,
        photos: {
            url: null,
            count: null,
            attributes: null
        }
    },

    /**
     * Get current user photo level
     * @returns {number} Current photo level
     */
    getPhotoLevel: function () {
        const photos = this.get('photos');
        return photos.attributes && photos.attributes.level || 0;
    },

}, {
    fromUserModel: function(model) {
        const user = model.get('user');

        return new PaymentPageUserModel({
            id: user.id,
            screenName: user.screenName,
            age: user.age,
            geo: user.geo,
            statuses: user.statuses,
            photos: {
                url: model.get('userPrimaryPhoto').avatar,
                attributes: model.get('userPrimaryPhoto').attributes,
                count: model.get('userPhotos').length
            }
        });
    },

    fromMessengerModel: function(model) {
        const user = model.get('user');

        return new PaymentPageUserModel({
            id: user.id,
            screenName: user.login,
            age: user.age,
            geo: user.geo,
            statuses: user.statuses,
            photos: {
                url: user.photos.url,
                attributes: user.photos.attributes,
                count: user.photos.count
            }
        });
    },

    fromNotificationModel: function(model) {
        const userId = model.get('action') === 'webcam' ? model.get('bindProfileId') : model.get('user_id');

        return new PaymentPageUserModel({
            id: userId,
            screenName: model.get('screenname'),
            age: model.get('age'),
            geo: model.get('geo'),
            // Backend don't send any data about statuses, because user is online anytime
            statuses: {
                online: true
            },
            photos: {
                url: model.get('photo'),
                attributes: model.get('photoAttributes')
            }
        });
    },

    fromUserListWidgetModel: function(model) {
        // check userId for livecam
        const userId = model.has('promo') ? model.get('userId') : model.get('id');

        return new PaymentPageUserModel({
            id: userId,
            screenName: model.get('login'),
            age: model.get('age'),
            geo: model.get('geo'),
            statuses: model.get('statuses'),
            photos: {
                url: model.get('photos').url,
                attributes: model.get('photos').attributes
            }
        });
    },

    fromHONUser: function(model) {
        var primaryPhoto = _.find(model.get('photos'), function(photo) {
            return photo.is_primary;
        });

        return new PaymentPageUserModel({
            id: model.get('id'),
            screenName: model.get('name'),
            age: model.get('age'),
            geo: model.get('geo'),
            statuses: model.get('userStatus'),
            photos: {
                url: primaryPhoto ? primaryPhoto.avatar : model.get('photo'),
                attributes: primaryPhoto ? primaryPhoto.attributes : {}
            }
        });
    },

    fromVideoChatModel: function(model) {
        return new PaymentPageUserModel({
            id: model.get('opponentInfo').id,
            screenName: model.get('opponentInfo').screenName,
            age: model.get('opponentInfo').age,
            geo: model.get('opponentInfo').geo,
            statuses: model.get('opponentInfo').statuses,
            photos: {
                url: model.get('opponentInfo').photoUrl,
                attributes: model.get('opponentInfo').photoAttributes
            }
        });
    },
});
export default PaymentPageUserModel;
