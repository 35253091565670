/**
 * TODO: to think about DI for frontend
 * used for resolving problems with singletons when file is added few times while js minimization
 * in such cases object can be initialized few times and some static data can be lost
 */
var InstanceRegister = function() {
    /**
     * Container for instances
     */
    this.container = {}
};

InstanceRegister.prototype = {
    /**
     * Get object instance by name (string)
     * @public
     * @return {}
     */
    get: function (objectNameString, importedObject, attributes, options, isNew) {
        if (!this.container[objectNameString] || isNew) {
            this.container[objectNameString] = new importedObject(attributes, options);
        } else if (options || attributes) {
            this.container[objectNameString].set(options || attributes);
        }

        return this.container[objectNameString];
    }
};
export default InstanceRegister;