const css = require('../styles/CircleProgress.css');

export default class CircleProgressView extends BaseView.extend({

    template: require('../templates/CircleProgress.pug'),
    className: css.loader,

    MIN_PROGRESS_PART: 0.05,

    options: {
        primaryColorValue: true,
        withOverlay: true,
        viewBox: 60,
    },

}) {

    get __$progressCircle() {
        return this.__$progressCircleCache = this.__$progressCircleCache || this.$('._progressCircle');
    }

    get __circleLength() {
        return this.__circleLengthCache = this.__circleLengthCache ||
            this.__$progressCircle[0].r.baseVal.value * 2 * Math.PI;
    }

    get __startOffset() {
        return this.__startOffsetCache = this.__startOffsetCache ||
            this.__circleLength * (3 + this.MIN_PROGRESS_PART);
    }

    initialize() {
        this.listenTo(this.model, this.model.UPLOAD_PROGRESS_NAME, this.__onProgress);
    }

    render() {
        this.renderTemplate({
            viewBox: this.options.viewBox,
        });
        this.options.withOverlay && this.$el.addClass(css.withOverlay);
        this.__$progressCircle.css({
            'stroke-dasharray': this.__circleLength,
            'stroke-dashoffset': this.__startOffset,
        });
        this.__onProgress({ share: this.model.get('share') || 0 });
        return this;
    }

    /**
     * @param data
     * @param data.share = {Number}
     * @private
     */
    __onProgress({ share }) {
        this.__setPercentage(this.model.asPercent(share));
        const offset = this.__startOffset + share * (1 - this.MIN_PROGRESS_PART) * this.__circleLength;
        this.__$progressCircle.css({
            'stroke-dashoffset': offset,
        });
    }

    __setPercentage(percent) {
        this.__$percentageOrTime = this.__$percentageOrTime || this.$('._percentageOrTime');
        this.__$percentageOrTime.text(`${percent}%`);
    }
};
