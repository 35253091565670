import Validator from 'app/mobSite/components/application/Validator.js';

var NumberValidator = Validator.extend({

    defaults: {
        message: '',
        length: null, // Option to validate EXACT string length
        min: null,
        max: null,
    },

    /**
     * @override
     */
    _validateValue: function(value) {
        const str = String(value);

        if (!/^\d+$/.test(str)) {
            return false;
        }

        if (this.options.length !== null && str.length !== this.options.length) {
            return false;
        }

        if (this.options.min !== null && parseInt(value) < this.options.min) {
            return false;
        }

        if (this.options.max !== null && parseInt(value) > this.options.max) {
            return false;
        }

        return true;
    }
});
export default NumberValidator;
