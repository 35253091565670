import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import first from 'lodash/first';
import last from 'lodash/last';
import find from 'lodash/find';
import includes from 'lodash/includes';
import PaymentPagePaymentBaseModel from 'components/paymentPage/models/PaymentPagePaymentBaseModel';
import InvalidBinValidator from 'components/paymentPage/validators/InvalidBinValidator';
import AdditionalFieldsValidationRules, { defaultMaxValidator } from '../AdditionalFieldsValidationRules';

import RequiredValidator from 'components/application/validators/RequiredValidator';
import CardValidator from 'components/paymentPage/validators/CardValidator';
import StringValidator from 'components/application/validators/StringValidator';
import DateValidator from 'components/application/validators/DateValidator';
import NumberValidator from 'components/application/validators/NumberValidator';
import getBrowserDetails from '@core/payment/payProcess/utils/getBrowserDetails';
import clearCardValue from '@core/payment/forms/card/utils/clearCardValue';
import { CVV_MIN_LENGTH } from '@core/payment/forms/common/validations/securityNumber';
import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import PAYMENT_ONECLICK_FLOWS from '@core/payment/common/constants/paymentOneclickFlows';
import {
    CARD_NUMBER,
    EXPIRATION_DATE_M,
    EXPIRATION_DATE_Y,
    SECURITY_NUMBER
} from '@core/payment/forms/card/constants/fieldNames';
import logger from '@core/logger';

const CVV_MAX_LENGTH = 4;

/**
 * @class PaymentPageCardPaymentModel
 * @see PaymentPagePaymentBaseModel
 */
var PaymentPageCardPaymentModel = PaymentPagePaymentBaseModel.extend({

    /**
     * @see Backbone.Model.urlRoot
     */
    urlRoot: '/pay/pay/isJsonMode/true',

    defaults: {
        via: '',
        user_id: '',
        domain: '',
        locale: '',
        country_code: '',
        package_id: '',
        product_id: '',
        currency_code: '',
        hidePaymentForm: 0,
        prevVia: '',
        // This field use to inform pay model about additional fields
        // It doesn't take part in data formation.
        additionalFields: null,
        isInstantRepeat: null,
        cardTypeRules: [],

        cardType: {
            cardType: null,
            cardTypeLogo: null,
        },
    },

    /** banned bins(cards) list */
    bannedBins: [],

    /** Non-local bins(cards) list - need for Lat america*/
    nonLocalBins: [],

    // Bin with debitType and financialServicePlatform(neo-bank processing)
    binEntities: [],

    /** Accepted card length for bin */
    acceptedCardLengthsByBins: {},

    /** Accepted cvv length */
    cvvLength: null,

    /**
     * Return url according page type
     * @todo: add back url and via
     *
     * @returns {string}
     */
    url: function() {
        if (!this.get('via')) {
            logger.sendWarning('[PaymentPageCardPaymentModel] There is no via in request');
        }

        return `${this.urlRoot}?via=${this.get('via')}&prevVia=${this.get('prevVia')}`;
    },

    data() {
        // Add browser information into data
        const data = {
            ...this.toJSON(),
            scenario: this.getScenario(),
            browserDetails: getBrowserDetails(),
        };

        // This field use to inform pay model about additional fields
        // It doesn't take part in data formation for sever.
        delete data.additionalFields;

        delete data.currentBinEntity;
        delete data.cardType;
        delete data.cardTypeRules;

        if (isEmpty(data.selectedAdditionalPackages)) {
            delete data.selectedAdditionalPackages;
        }

        if (isNil(data.isInstantRepeat)) {
            delete data.isInstantRepeat;
        }

        if (data.scenario !== PaymentScenario.ONECLICK && data.oneClickFlow) {
            delete data.oneClickFlow;
        }

        return { [this.getFormName()]: data };
    },

    securityNumberRules() {
        return [
            {
                attributes: [SECURITY_NUMBER],
                validator: new RequiredValidator({
                    message: yiiT.t('paymentPage', 'text.security_number_is_required')
                }),
                scenario: 'initial'
            },
            {
                attributes: [SECURITY_NUMBER],
                validator: new StringValidator({
                    message: yiiT.t('paymentPage', 'text.security_number_should_be_3-4_characters_long', {
                        '{length}': this.cvvLength || CVV_MIN_LENGTH,
                    }),
                    min: CVV_MIN_LENGTH,
                    max: CVV_MAX_LENGTH,
                    length: this.cvvLength,
                }),
                scenario: 'initial'
            },
            {
                attributes: [SECURITY_NUMBER],
                validator: new NumberValidator({
                    message: yiiT.t('paymentPage', 'text.invalid_security_number_selected')
                }),
                scenario: 'initial'
            },
        ];
    },

    rules: function() {
        return [
            {
                attributes: [CARD_NUMBER],
                validator: new RequiredValidator({
                    message: yiiT.t('paymentPage', 'text.card_number_is_required')
                }),
                scenario: 'initial'
            },
            {
                attributes: [CARD_NUMBER],
                validator: new CardValidator({
                    message: yiiT.t('paymentPage', 'text.invalid_card_number_selected'),
                    acceptedCardLengthsByBins: this.acceptedCardLengthsByBins,
                    key: 'c1'
                }),
                scenario: 'initial'
            },
            {
                attributes: [CARD_NUMBER],
                validator: new StringValidator({
                    message: yiiT.t('paymentPage', 'text.invalid_card_number_selected'),
                    min: 13,
                    max: 19,
                    key: 'c1'
                }),
                scenario: 'initial'
            },
            {
                attributes: [CARD_NUMBER],
                validator: new InvalidBinValidator({
                    message: yiiT.t('paymentPage', 'text.banned_card_bin'),
                    invalidBins: this.bannedBins,
                    key: 'c2'
                }),
                scenario: 'initial'
            },
            {
                attributes: [CARD_NUMBER],
                validator: new InvalidBinValidator({
                    message: yiiT.t('paymentPage', 'text.non_local_card_bin'),
                    invalidBins: this.nonLocalBins,
                    key: 'c3'
                }),
                scenario: 'initial'
            },
            {
                attributes: [CARD_NUMBER],
                validator: new NumberValidator({
                    message: yiiT.t('paymentPage', 'text.invalid_card_number_selected'),
                    key: 'c1'
                }),
                scenario: 'initial'
            },
            ...this.securityNumberRules(),
            {
                attributes: [EXPIRATION_DATE_Y],
                validator: new DateValidator({
                    message: yiiT.t('paymentPage', 'text.invalid_expiry_date_selected'),
                    monthAttribute: EXPIRATION_DATE_M,
                    yearAttribute: EXPIRATION_DATE_Y,
                    skipOnNotNumber: true,
                    minDate: new Date()
                }),
                scenario: 'initial'
            },
            {
                attributes: [EXPIRATION_DATE_M],
                validator: new DateValidator({
                    message: yiiT.t('paymentPage', 'text.invalid_expiry_date_selected'),
                    monthAttribute: EXPIRATION_DATE_M,
                    yearAttribute: EXPIRATION_DATE_Y,
                    skipOnNotNumber: true,
                    minDate: new Date()
                }),
                scenario: 'initial'
            },
            {
                attributes: [EXPIRATION_DATE_Y],
                validator: new NumberValidator({
                    message: yiiT.t('paymentPage', 'text.invalid_expiry_date_selected')
                }),
                scenario: 'initial'
            },
            {
                attributes: [EXPIRATION_DATE_M],
                validator: new NumberValidator({
                    message: yiiT.t('paymentPage', 'text.invalid_expiry_date_selected')
                }),
                scenario: 'initial'
            },
            ...this.__getAdditionalFieldsValidators(this.get('additionalFields')),
        ];
    },

    validate(attributes, options) {
        this.clearErrors();

        if (this.getScenario() === PaymentScenario.ONECLICK && this.get('oneClickFlow') === PAYMENT_ONECLICK_FLOWS.USE_CVV) {
            this.securityNumberRules().forEach(rule => {
                rule.validator.validate(this, rule.attributes, attributes);
            });

            if (this.hasErrors()) {
                return this.getErrors();
            }
        }

        return PaymentPagePaymentBaseModel.prototype.validate.call(this, attributes, options);
    },

    /**
     * Return validation rules for long form fields
     * @return {object} validators for all additional fields
     * @param {array} additionalFields
     * @private
     */
    __getAdditionalFieldsValidators(additionalFields) {
        if (!additionalFields) {
            return [];
        }

        const validationRules = new AdditionalFieldsValidationRules();
        /**
         * According to the list of available fields (additionalFields)
         * we get the implementation of validators for the long form from the class AdditionalFieldsValidationRules
         * @type {array}
         */
        const validators = additionalFields.map(
            field => {
                const rule = validationRules[field.longFormField];

                if (!rule) {
                    return defaultMaxValidator(field.longFormField, field.isRequired, 40);
                }

                return rule(field.isRequired);
            }
        );

        return flatten(validators);
    },

    /**
     * remember bin as banned and show card number error
     * @public
     * @param bin {string}
     */
    invalidateCardBin: function (bin) {
        this.bannedBins.push(bin);
        return this;
    },

    /**
     * remember bin as non local and show card number error
     * @public
     * @param bin {string}
     */
    nonLocalCardBin: function (bin) {
        this.nonLocalBins.push(bin);
        return this;
    },

    /**
     * @public
     */
    resetBannedBins: function () {
        /**
         * Can't assign empty array because we pass
         * this value by reference inside validator
         * @see InvalidBinValidator
         */
        this.bannedBins.length = 0;
        return this;
    },

    /**
     * There is mismatch between base realisation of method, founded on concatenating 'method'
     * name with payment form suffix, and form name on server side.
     * @override
     * @return {string}
     */
    getFormName: function () {
        return 'CreditCardPaymentForm';
    },

    /**
     * Remember accepted card length for bin and show card number error
     * @public
     * @param bin {string}
     * @param acceptedCardLengths {Array<int>}
     */
    acceptedCardLengths: function (bin, acceptedCardLengths) {
        this.acceptedCardLengthsByBins[bin] = _.map(acceptedCardLengths, (val) => parseInt(val));
        return this;
    },

    /**
     * @public
     * @override
     */
    getBannedBins() {
        return this.bannedBins;
    },

    /**
     * @public
     * @param {Object} binEntity
     */
    setBinEntity: function (binEntity) {
        if (isEmpty(binEntity)) {
            return;
        }

        const currentBinEntity = find(this.binEntities, ({ bin }) => {
            // @TODO bin and binEntity.bin must be the same type
            return includes(String(bin), String(binEntity.bin));
        });

        if (currentBinEntity) {
            if (binEntity.cardTypeLogo) {
                currentBinEntity.cardTypeLogo = binEntity.cardTypeLogo;
            }

            return;
        }

        this.binEntities.push(binEntity);
    },

    /**
     * @public
     * @return {Object|Null}
     */
    getLastBinEntity: function () {
        return last(this.binEntities) || null;
    },

    acceptedCvvLength(cvvLength) {
        this.cvvLength = cvvLength;
        return this;
    },


    updateCardType(cardNumber) {
        const filteredBinEntities = this.binEntities.filter(({ bin }) => {
            return clearCardValue(cardNumber).startsWith(bin);
        });

        let cardType;

        _.each(this.get('cardTypeRules'), (rule, name) => {
            /** for type conversion */
            rule = eval(rule);

            if (rule.test(cardNumber)) {
                cardType = name;
            }
        });

        this.set('cardType', {
            cardTypeLogo: first(filteredBinEntities)?.cardTypeLogo || cardType,
            cardType,
        });
    },

    clearCurrentBinEntity() {
        this.set('currentBinEntity', {});
    },
});

export default PaymentPageCardPaymentModel;
