import PaymentPageModel from 'app/mobSite/components/paymentPage/models/PaymentPageModel.js';

/**
 * @class PaymentPageFeaturesModel
 * @see PaymentPageModel
 */
export default PaymentPageModel.extend({

    urlRoot: '/pay/features/isJsonMode/true',

    actionId: 'features',

    getCorrectedScene: function() {
        return 'features';
    },

    /**
     * @override
     * @returns {*|Object}
     */
    getPackageForBenefits: function () {
        return this.getSelectedPackage();
    }
});