var LoadOnDemandBehavior = {
    applyFetch: function () {
        this.fetch.apply(this, arguments);
    },

    finishLoad: function () {
        // set to default value
        this.set('isRequestChangeState', false);
        // if we have a new request with new params
        // and current completed request need only to save state
        // we do not fire ready events
        // and call LoadOnDemandBehavior::run method again to load new data
        //
        // this block need to fix problem with simultaneous requests
        if (this.get('saveStateOnly')) {
            this.set('loading', false);
            _.defer(function () {
                this.set('saveStateOnly', false);
                this.run();
            }.bind(this));
            return;
        }
        // We must set loading and ready together because there is place when we listen and then call run() method
        // but we not set 'ready' param yet. That's because we send two identical request.
        this.set({ loading: false, ready: true });
        _.defer(this.trigger.bind(this), 'ready', this);
    },

    run: function () {
        if (this.get('ready')) {
            // defer for similar behaviour for ajax request and ready data for trigger ready
            _.defer(this.trigger.bind(this), 'ready', this);
            return;
        }

        if (this.get('loading')) {
            // this block need to fix problem with simultaneous requests
            if (!this.get('isRequestChangeState')) {
                // if request doesn't change state at server - abort it
                this.abortSync();
            } else {
                // if request changes state at server - mark it as saveStateOnly and abort
                // We call LoadOnDemandBehavior::run method again when this request is completed
                this.set('saveStateOnly', true);
                return;
            }
        }

        this.set('loading', true);
        this.listenToOnce(this, 'sync', this.finishLoad.bind(this));

        this.applyFetch();
    },

    resetCollection: function () {
        this.collection && this.collection.reset();
        this.set('ready', false);
        return this;
    }
};
export default LoadOnDemandBehavior;
