import first from 'lodash/first';
import getPaymentPackageShortTranslation from 'app/components/paymentPagePackage/utils/getPaymentPackageShortTranslation';
import getSubtitleTranslate from '../utils/getSubtitleTranslate';
import compactPriceValue, { PRICE_CHAR_THOUSANDS_VALUE } from '@core/billingHistory/utils/compactPriceValue';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import PackageTypes from '@core/payment/widgets/package/constants/packageTypes';
import {WEEK_SPEAK_PACKAGE_NAME} from "@phoenix/coins/specialOffer/constants/specialOfferPackageNames";

/**
 * @class PaymentPageModel
 * @see BaseModel
 */
var PaymentPagePackageModel = BaseModel.extend({

    parse: function (data) {
        data.is_default = Boolean(data.is_default*1);

        data.is_active = data.is_default;

        this._unifyPrice(data.price);

        // Clear discount after refetch PaymentPage after discount pedal expired
        if (data.discounted_price) {
            this._unifyPrice(data.discounted_price);
        } else {
            this.resetDiscountedPrice();
        }

        // replace type if alternative exists
        if (data.package.alter_type) {
            data.package.type = data.package.alter_type;
        }

        return data;
    },

    /**
     * unify different price formats
     * @param price
     * @private
     */
    _unifyPrice: function (price) {
        if (price.per_period) return;

        if (!price.hi && !price.lo) {
            price.hi = price.amount.split('.')[0];
            price.lo = price.amount.split('.')[1];
        }

        price.per_period = {
            hi: price.hi,
            lo: price.lo
        };
    },

    /**
     * Format price
     * @param price
     * @return Object {
     *     amount: {number}
     *     per_period: {
     *         hi: {number}
     *         lo: {number}
     *     }
     * }
     * @private
     */
    __compactPrice: function (price) {
        if (!price || !this.get('package').isShortPriceEnabled) {
            return price;
        }

        let per_period = {};

        if (price.per_period.hi.length >= PRICE_CHAR_THOUSANDS_VALUE) {
            per_period.hi = this.compactPriceValue(price.per_period.hi);
            per_period.lo = '';
        } else {
            per_period = price.per_period;
        }

        return {
            amount: this.compactPriceValue(price.amount.split('.')[0]),
            per_period,
        };
    },

    /**
     * @param value {String}
     * @return {String}
     * @public
     */
    compactPriceValue: function (value) {
        if (!this.get('package').isShortPriceEnabled) {
            return value;
        }

        return compactPriceValue(value);
    },

    /**
     * Get difference in per day price between cheapest and the most expensive packages in percents
     * @return {number}
     * @public
     */
    getSaveAmount: function () {
        if (!this.get('discounted_price') || !this.get('baseAmount')) {
            return 0;
        }

        /** for package with base price display base discount */
        if (this.get('isBasePricePackage')) {
            return this.get('baseDiscount');
        }

        var discount = this.__getPerDayAmount() / this.get('baseAmount');

        return Math.round((1 - discount) * 20 ) * 5;
    },

    /**
     * Reset discount price.
     * Need to render usual price instead of discount price.
     * @see PaymentPageFormView
     */
    resetDiscountedPrice: function () {
        this.set('discounted_price', null);
    },

    /**
     * @return {number}
     * @private
     */
    __getPerDayAmount: function () {
        /** Temp fix for Japan */
        var amount = this.get('discounted_price').amount.replace(',', '');

        return amount / this.get('package').interval;
    },

    /**
     * @public
     * @return {boolean}
     */
    isTrialPackage() {
        return this.get('package').packageTypes.includes(PackageTypes.TRIAL);
    },

    /**
     * @public
     * @return {boolean}
     */
    isWeekTrialPackage() {
        return this.get('package').interval === 7 && this.isTrialPackage();
    },

    /**
     * @return {string}
     */
    getTitle() {
        if (this.get('package_name') === WEEK_SPEAK_PACKAGE_NAME) return this.get('package').title;

        return getPaymentPackageShortTranslation(this.get('package').title, this.get('package_name'));
    },

    /**
     * @return {string}
     */
    getSubtitle() {
        return getSubtitleTranslate(this.get('package').title);
    },

    /**
     * @public
     * @return {string}
     */
    getImageBag() {
        return first(this.get('package').title.split('_')).toLowerCase();
    },

    /**
     * @public
     * @return {string}
     */
    getIconFeature() {
        switch (this.get('package').title) {
            case 'Premium dater-VIP':
                return 'heart';
            case 'Extra security-Premium':
                return 'incognito';
            case 'Chataholic-Popular':
                return 'chat';
            default:
                return '';
        }
    },

    /**
     * get package price
     * @return {object}
     * @public
     */
    getPrice: function () {
        const currentPrice = this.get('discounted_price') || this.get('price');
        const oldPrice = this.get('discounted_price') ? this.get('price') : false;

        return {
            current: this.__compactPrice(currentPrice),
            old: this.__compactPrice(oldPrice),
        };
    },

    /**
     * @public
     * @return {string}
     */
    getFormattedPrice() {
        return this.formatPrice(this.getPrice().current.amount);
    },

    /**
     * @public
     * @param {number} amount
     * @return {string}
     */
    formatPrice(amount) {
        const literal = this.get('price').currency.literal;

        return (literal.prefix || '') + amount + (literal.suffix || '');
    },

    /**
     * @public
     */
    setActive() {
        this.collection.setActive(this.get('id'), this.get('originalMethod'));
    },

    /**
     * @param {number|string} price
     * @public
     */
    hasCommaDelimiter: function (price) {
        return String(price).indexOf(',') >= 0;
    },
});
export default PaymentPagePackageModel;
