import PaymentScenario from '@core/payment/common/constants/paymentScenario';

import PaymentPageIbanPaymentModel from './PaymentPageIbanPaymentModel';

/**
 * @class PaymentPageSofortPaymentModel
 * @see PaymentPageIbanPaymentModel
 */
export default PaymentPageIbanPaymentModel.extend({
    data: function() {
        const data = PaymentPageIbanPaymentModel.prototype.data.call(this, arguments);
        const form = data[this.getFormName()];

        if (form.sepaMandateFields && form.scenario === PaymentScenario.ONECLICK) {
            form.isMaskedForm = true;
        }
        /**
         * Don't need in request
         */
        delete form.sepaMandateFields;

        return data;
    },

    /**
     * @override
     */
    FORCE_REDIRECT: 0,
});
