import isEmpty from 'lodash/isEmpty';

import Validator from 'components/application/Validator';

/**
 *
 */
var StringValidator = Validator.extend({
    defaults: {
        message: '',

        /**
         * @var {string} key for tracking to define message
         */
        key: '',

        /**
         * @var integer|null Specifies the length limit of the value to be validated
         */
        length: null,

        /**
         * @var integer|null Specifies the minimum length of the value to be validated
         */
        min: null,

        /**
         * @var integer|null Specifies the maximum length limit of the value to be validated
         */
        max: null,

        /**
         * @var {boolean} Indicates if we should validate chars
         */
        shouldValidateChars: false
    },

    _validateValue(value) {
        /**
         * Because it is not a validator for a required field
         * for required field validation use RequiredValidator
         */
        if (isEmpty(value)) {
            return true;
        }

        return this._checkString(value) &&
            this._checkLength(value) &&
            this._checkMax(value) &&
            this._checkMin(value) &&
            this._checkChars(value);
    },

    _checkString: function(value) {
        return _.isString(value);
    },

    _checkLength: function(value) {
        if (_.isNumber(this.options.length)) {
            return value.length == this.options.length;
        }

        return true;
    },

    _checkMax: function(value) {
        if (_.isNumber(this.options.max)) {
            return value.length <= this.options.max;
        }

        return true;
    },

    _checkMin: function(value) {
        if (_.isNumber(this.options.min)) {
            return value.length >= this.options.min;
        }

        return true;
    },

    _checkChars: function(value) {
        if (this.options.shouldValidateChars) {
            return /^[a-zA-Z0-9 .,\-\s]+$/.test(value.trim());
        }

        return true;
    }
});
export default StringValidator;
