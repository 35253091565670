import t from '@core/translations/translate';
import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import EMAIL_REGEXP from '@phoenix/payment/forms/common/constants/emailRegExp';
import {NAME_MAX_LENGTH, NAME_MIN_LENGTH} from '@phoenix/payment/forms/common/constants/nameFieldLength';
import {EMAIL, FIRST_NAME, LAST_NAME} from "@phoenix/payment/forms/common/constants/fieldNames";
import RegexpValidator from 'app/components/application/validators/RegexpValidator';
import StringValidator from '../../../mobSite/components/application/validators/StringValidator';
import PaymentPageUserFieldsPaymentModel
    from "./PaymentPageUserFieldsPaymentModel";

const PaymentPageNuveiMyBankPaymentModel = PaymentPageUserFieldsPaymentModel.extend({
    defaults: {
        package_id: null,
        firstName: null,
        lastName: null,
        email: null,
    },

    data: function () {
        const data = PaymentPageUserFieldsPaymentModel.prototype.data.call(this, arguments);
        const form = data[this.getFormName()];

        if (form.sepaMandateFields && form.scenario === PaymentScenario.ONECLICK) {
            form.isMaskedForm = true;
        }
        /**
         * Don't need in request
         */
        delete form.sepaMandateFields;

        delete form.altMethodsSettings;
        if (!form.email) {
            delete form.email;
        }

        return data;
    },

    /**
     *
     * @return {Array}
     * @private
     */
    __getRequiredFields: function () {
        return this.get('altMethodsSettings')?.requiredFields || [];
    },

    /**
     *
     * @return {Array}
     * @private
     */
    _requiredFields: function () {
        return this.__getRequiredFields() || [];
    },

    rules: function () {
        const rules = this.getRequiredFieldsRules();
        const requiredFields = this.__getRequiredFields();

        if (requiredFields.includes(FIRST_NAME) && requiredFields.includes(LAST_NAME)) {
            rules.push({
                attributes: ['firstName', 'lastName'],
                validator: new StringValidator({
                    message: t('paymentPage', 'text.your_name_length', {
                        '{min}': NAME_MIN_LENGTH,
                        '{max}': NAME_MAX_LENGTH,
                    }),
                    min: NAME_MIN_LENGTH,
                    max: NAME_MAX_LENGTH,
                }),
                scenario: this.VALIDATION_SCENARIO,
            });
            rules.push({
                attributes: ['firstName', 'lastName'],
                validator: new RegexpValidator({
                    message: t('paymentPage', 'text.use_only_letters'),
                    regexp: new RegExp('^[A-Za-zÂÀÁÄÉÈÊËÍÏÖÓÜäáèàâêéëïíóöüß ]+$'),
                }),
                scenario: this.VALIDATION_SCENARIO,
            });
        }

        if (requiredFields.includes(EMAIL)) {
            rules.push({
                attributes: ['email'],
                validator: new RegexpValidator({
                    message: t('paymentPage', 'error.email.invalid'),
                    regexp: EMAIL_REGEXP,
                }),
                scenario: this.VALIDATION_SCENARIO,
            });
        }

        return rules;
    },
});

export default PaymentPageNuveiMyBankPaymentModel;
