import PaymentPagePackageModel from 'app/mobSite/components/paymentPagePackage/models/PaymentPagePackageModel.js';

/**
 * @class PaymentPageModel
 * @see BaseModel
 */
var PaymentPagePackageCollection = BaseCollection.extend({

    model: PaymentPagePackageModel,

    /**
     * Set is_active in packages for each method separately
     * @public
     */
     setActive: function(id, method) {
        if (!this.isPackageExist(id)) {
            return;
        }

        method = method || this.findWhere({
            id: id,
        }).get('originalMethod');

        _.each(this.where({
            originalMethod: method,
        }), function (model) {
            model.set('is_active', model.get('id') === id);
        });
    },

    /**
     * @public
     * @param id {string}
     * @return {boolean}
     */
    isPackageExist: function (id) {
        return !!this.findWhere({id: id});
    },

  /**
   * @public
   * @param id {string}
   * @return {boolean}
   */
  isPackageExistViaStockId: function (id) {
    return !!this.findWhere({packageId: id});
  }
});
export default PaymentPagePackageCollection;
