import omit from 'lodash/omit';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import result from 'lodash/result';

import Backbone from 'backbone';
import {parsePathname} from '@core/utils/url';
import AbstractRouter from './AbstractRouter';

/**
 * Bridge between Backbone and React applications
 * In few words - this router does NOTHING.
 * It recieves updates from 'react-router-dom'.
 *
 * @see createLegacyRouter.js
 *
 * @class LegacyRouter
 * @extends Router
 */
export default AbstractRouter.extend({

    currentRoute: {
      action: null,
      name: null,
    },

    /**
     * @overriden
     */
    initialize(history) {
        this.history = history;
        this.initializeHistoryListener();
    },

    /**
     * @overriden
     */
    navigateByRoute(fragment, options, params) {
        const replace = result(options, 'replace', false);
        let state;
        if (isObject(options)) {
          /**
           * Remove all backbone router attributes
           */
          const clearedOptions = omit({
            ...options,
            ...params,
          }, ['replace', 'trigger']);
            state = isEmpty(clearedOptions) ? state: clearedOptions;
        }

        const path = this.preparePath(fragment);

        replace ?
          this.history.replace(path, state):
          this.history.push(path, state);
    },

    /**
     * If new path hasn't '/' in begin, router replaces only last part of path.
     *
     * For example: we have path '/pay/membership'. If we navigate with newPath === 'search'
     * router navigates us to '/pay/search'
     * @param path
     * @return {string}
     */
    preparePath(path = '') {
        return path.startsWith('/') ? path : `/${path}`;
    },

    runHistoryChange(route) {
        const controllerRunPayload = {
            action: route.action,
            name: route.controller,
            previousAction: this.currentRoute.action,
            previousName: this.currentRoute.name,
        };

        // Save those variables because old app uses them.
        this.place = route.controller;
        this.prevPlace = this.currentRoute.name;
        // Save previous result. Must be done before triggers because calls fatal errors.
        this.currentRoute = {action: route.action, name: route.controller};


        // Imitate 'controller run' and 'route' events
        Backbone.trigger('ControllerRun', controllerRunPayload);
        this.trigger('route', 'run', [route.controller, route.action, null]);
    },

    /**
     * @param {string} controller
     * @param {string} action
     * @param {boolean} replace
     */
    run(controller, action, replace) {
        const route = `/${controller}${action ? `/${action}` : ''}`;

        if (replace) {
            this.history.replace(route);
        } else {
            this.history.push(route);
        }
    },

    /**
     * Method for outside usage. We need to trigger change on first bootstrap. Before any route was activated.
     * @public
     * @see createLegacyRouter.js
     */
    triggerHistoryChange() {
        this.runHistoryChange(
            parsePathname(this.history.location)
        );
    },

    /**
     * Listen changes in react-router history and mirror
     * changes, imitating legacy backbone router.
     */
    initializeHistoryListener() {
        this.history.listen((location) => {
            this.runHistoryChange(
                parsePathname(location)
            );
        });
    }

});
