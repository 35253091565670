import isEmpty from 'lodash/isEmpty';

import PaymentPagePaymentBaseModel from 'components/paymentPage/models/PaymentPagePaymentBaseModel';
import { REMARKETING_POPUP } from '@phoenix/remarketing/offerPopup/constants/remarketingViaList';
import PopupType from '@phoenix/remarketing/offerPopup/constants/PopupType';
import getBrowserDetails from '@core/payment/payProcess/utils/getBrowserDetails';

/**
 * @class RemarketingPopupPayModel
 * @classdesc Pay model, that sends to server information about selected package via hash
 * @extends BaseModel
 */
export default PaymentPagePaymentBaseModel.extend({

    urlRoot: '/pay/pay/isJsonMode/true',

    url: function () {
        return `${this.urlRoot}?via=${this.get('via')}&prevVia=${REMARKETING_POPUP}`;
    },

    defaults: {
        /**
         * For payment and decline url
         */
        via: 'membership',
        package_id: '',
    },

    data: function () {
        const data = {
            package_id: this.get('package_id'),
            prevVia: REMARKETING_POPUP,
            hidePaymentForm: 1,
            popupType: PopupType.REMARKETING_POPUP,
            browserDetails: getBrowserDetails(),
        };

        if (!isEmpty(this.get('selectedAdditionalPackages'))) {
            data.selectedAdditionalPackages = this.get('selectedAdditionalPackages');
        }

        return {
            CreditCardPaymentForm: data,
        };
    },
});
