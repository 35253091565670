import { WITH_SECOND_STEP_ALT_METHODS } from '@core/payment/common/constants/paymentMethods';
import getActionByMethod from '@core/payment/common/utils/getActionByMethod';

/**
 * @public
 * @type {object}
 */
export const PAY_RESULT_ROUTE = {
    action: 'result',
    name: 'pay',
};

export const DONT_PUSH_ROUTES = [
    {
        action: "report",
        name: "user"
    },
    {
        action: "info",
        name: "user"
    },
    {
        action: "trusted",
        name: "pay"
    },
    {
        action: 'reported',
        name: 'account',
    },
    PAY_RESULT_ROUTE,
    {
        action: 'retryPermission',
        name: 'pay',
    },
    /**
     * To prevent circle back on sepa prefilled logic on second step of alt methods, it works like mobUnify pp with two
     * steps, but with different actions
     */
    ...(WITH_SECOND_STEP_ALT_METHODS.map(method => ({
        name: 'pay',
        action: getActionByMethod(method),
    }))),
];

export default DONT_PUSH_ROUTES;
