import MicrofeaturesPaymentModel from 'app/mobSite/components/microfeatures/models/MicrofeaturesPaymentModel';
import { DECLINE_VIA } from '@core/payment/common/constants/declineVia';
import URI from '@core/utils/url';

/**
 * @class CreditsExchangeModel
 * @extends MicrofeaturesPaymentModel
 */
export default MicrofeaturesPaymentModel.extend({
    url: '/pay/exchangeCredit/isJsonMode/true',

    defaults: {
        packageId: null,
        feature: '',
    },

    /**
     * @override
     */
    data() {
        return {
            packageId: this.get('packageId'),
        };
    },

    /**
     * @override
     */
    getDeclineUrl() {
        const url = URI('/pay/credit');
        url.setSearch({
            via: DECLINE_VIA,
            packageId: this.get('packageId'),
            returnPath: window.location.pathname,
        });

        const feature = this.get('feature');
        feature && url.setSearch('prevVia', feature);

        return url.resource();
    }
});
