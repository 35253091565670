import t from '@core/translations/translate';
import EMAIL_REGEXP from '@phoenix/payment/forms/common/constants/emailRegExp';
import RegexpValidator from 'app/components/application/validators/RegexpValidator';
import PaymentPageIdealPaymentModel from './PaymentPageIdealPaymentModel';
import StringValidator from '../../../mobSite/components/application/validators/StringValidator';
import { NAME_MAX_LENGTH, NAME_MIN_LENGTH } from '@phoenix/payment/forms/common/constants/nameFieldLength';

const PaymentPageNuveiIdealPaymentModel = PaymentPageIdealPaymentModel.extend({
    defaults: {
        package_id: null,
        firstName: null,
        lastName: null,
        email: null,
    },

    _requiredFields: function () {
        return [
            'firstName',
            'lastName',
            'email',
        ];
    },

    rules: function () {
        const rules = this.getRequiredFieldsRules();

        return rules.concat([
            {
                attributes: [ 'firstName', 'lastName' ],
                validator: new StringValidator({
                    message: t('paymentPage', 'text.your_name_length', {
                        '{min}': NAME_MIN_LENGTH,
                        '{max}': NAME_MAX_LENGTH,
                    }),
                    min: NAME_MIN_LENGTH,
                    max: NAME_MAX_LENGTH,
                }),
                scenario: this.VALIDATION_SCENARIO,
            },
            {
                attributes: [ 'firstName', 'lastName' ],
                validator: new RegexpValidator({
                    message: t('paymentPage', 'text.use_only_letters'),
                    regexp: new RegExp('^[A-Za-zÂÀÁÄÉÈÊËÍÏÖÓÜäáèàâêéëïíóöüß ]+$'),
                }),
                scenario: this.VALIDATION_SCENARIO,
            },
            {
                attributes: ['email'],
                validator: new RegexpValidator({
                    message: t('paymentPage', 'error.email.invalid'),
                    regexp: EMAIL_REGEXP,
                }),
                scenario: this.VALIDATION_SCENARIO,
            }
        ]);
    },
});

export default PaymentPageNuveiIdealPaymentModel;
