import { NAME_MIN_LENGTH, NAME_MAX_LENGTH } from '@phoenix/payment/forms/common/constants/nameFieldLength';
import PaymentPageIbanPaymentModel from 'components/paymentPageExternal/models/PaymentPageIbanPaymentModel';
import RequiredValidator from 'components/application/validators/RequiredValidator';
import NotZeroValidator from 'components/application/validators/NotZeroValidator';
import RegexpValidator from 'components/application/validators/RegexpValidator';
import StringValidator from '../../../mobSite/components/application/validators/StringValidator';
import getFieldsTranslate from '../getFieldsTranslate';
import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import { NAME_FIRST, NAME_LAST, STREET, POSTAL_CODE, CITY } from '@phoenix/payment/forms/common/constants/fieldNames';

/**
 * @TODO: add NUMBER_HOUSE and HOUSE_NUBMER constants to fieldNames
 */
const HOUSE_NUMBER = 'houseNumber';

/**
 * @class PaymentPageUserFieldsPaymentModel
 * @extends PaymentPageIbanPaymentModel
 * @author Pavel Davydov <pavel.davydov@together.com>
 */
export default class PaymentPageUserFieldsPaymentModel extends PaymentPageIbanPaymentModel.extend({

    /**
     * @const {String[]}
     * @protected
     */
    VALIDATION_SCENARIO: ['initial'],

    _requiredFields() {
        return [
            'nameFirst',
            'nameLast',
            'street',
            'houseNumber',
            'postalCode',
            'city'
        ];
    },

    defaults: {
        package_id: null,
        nameFirst: null,
        nameLast: null,
        street: null,
        houseNumber: null,
        postalCode: null,
        city: null,
        /**
         * Setting in model while creating specimen
         * @see paymentPageModel.getCurrentPaymentStage()
         */
        via: '',
    }

}) {

    initialize() {
        super.initialize();
        this.on('error', this.__onUserFieldsError);
    }

    parse(attrs) {
        if (_.includes([ 'black', 'yellow', 'red' ], attrs.status)) {
            attrs.actionStatus = attrs.status;
            // Emulate "error" status when risk check is failed.
            attrs.status = false;
        }

        return attrs;
    }

    data() {
        const data = PaymentPageIbanPaymentModel.prototype.data.call(this);

        if (data[this.getFormName()].scenario === PaymentScenario.ONECLICK) {
            // Delete default values
            [ NAME_FIRST, NAME_LAST, STREET, HOUSE_NUMBER, POSTAL_CODE, CITY ]
                .forEach(key => delete data[this.getFormName()][key]);
        }

        return data;
    }

    getRequiredFieldsRules() {
        const rules = [];

        this._requiredFields().forEach(attribute => {
            const isRequiredMessage = yiiT.t('paymentPage', 'error.is_required', {
                '{attribute}': getFieldsTranslate(attribute),
            });

            rules.push({
                attributes: [attribute],
                validator: new RequiredValidator({
                    message: isRequiredMessage
                }),
                scenario: 'initial',
            }, {
                attributes: [attribute],
                validator: new NotZeroValidator({
                    message: isRequiredMessage
                }),
                scenario: 'initial',
            });
        });

        return rules;
    }

    rules() {
        const rules = this.getRequiredFieldsRules();

        rules.push({
            attributes: [ 'nameFirst', 'nameLast' ],
            validator: new StringValidator({
                message: yiiT.t('paymentPage', 'text.your_name_length', {
                    '{min}': NAME_MIN_LENGTH,
                    '{max}': NAME_MAX_LENGTH,
                }),
                min: NAME_MIN_LENGTH,
                max: NAME_MAX_LENGTH,
            }),
            scenario: 'initial'
        });

        rules.push({
            attributes: [ 'nameFirst', 'nameLast' ],
            validator: new RegexpValidator({
                message: yiiT.t('paymentPage', 'text.use_only_letters'),
                regexp: new RegExp('^[A-Za-zÂÀÁÄÉÈÊËÍÏÖÓÜäáèàâêéëïíóöüß ]+$'),
            }),
            scenario: 'initial',
        });

        return rules;
    }

    /**
     * Check if error response contains one of fields from form.
     * If it have at least one - it's fully validation error
     * @private
     */
    __onUserFieldsError(model, response) {
        if (_.intersection(_.keys(response), this._requiredFields()).length) {
            this.set('haveValidationErrors', true);
        }
    }

};
