import css from '../styles/PaymentPageExternal.css';

/**
 * Realization of popup with close confirm message, to inform user about payment processing
 * @class PaymentPageExternalBaseView
 * @see BaseView
 */
export default BaseView.extend({

    className: css.external,

    events: {
        'click ._paymentPageExternalCloseFrame': '__onCloseFrameClick',
        'click ._paymentPageExternalCloseConfirm': '__onCloseConfirmClick',
        'click ._closePopup': 'closePopup',
    },


    template: require('../templates/PaymentPageExternalLayout.pug'),


    render: function () {
        this.renderTemplate();
        this._renderForm();
        this.region.ready();

        return this;
    },

    /**
     * Realization in children
     * @protected
     */
    _renderForm: function () {},

    /**
     * Close 3ds iframe and open confirm form
     * @private
     */
    __onCloseFrameClick: function () {
        this.toggleContent();
    },

    /**
     * Close confirm form and open 3ds iframe
     * @private
     */
    __onCloseConfirmClick: function () {
        this.toggleContent();
    },

    /**
     * Switch between contents inside popup
     * @public
     */
    toggleContent: function () {
        this.$('#paymentPageExternalCloseConfirm').toggleClass(css.hidden);
        this.$('#paymentPageExternalFrame').toggleClass(css.hidden);
    },

    /**
     * @public
     */
    closePopup: function () {
        window.app.popup.closePopup();
    },
});
