import isUndefined from 'lodash/isUndefined';
import PaymentPageUserFieldsPaymentModel from './PaymentPageUserFieldsPaymentModel';
import IbanValidator from 'components/paymentPage/validators/IbanValidator';
import StringWithNumberValidator from 'components/application/validators/StringWithNumberValidator';
import EqualValidator from 'app/components/application/validators/EqualValidator';
import { ACCOUNT_NUMBER, BANK_CODE, EXTRA_TAB_NAME } from '../../paymentPage/constants/sepaTabs';
import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import { IBAN } from '@phoenix/payment/forms/common/constants/fieldNames';

/**
 * @class PaymentPageSepaPaymentModel
 * @extends PaymentPageUserFieldsPaymentModel
 * @author Pavel Davydov <pavel.davydov@together.com>
 */
export default class PaymentPageSepaPaymentModel extends PaymentPageUserFieldsPaymentModel.extend({

    /**
     * Can't simple extend array adding new value. So we make a copy of parent one,
     * push new value and return all.
     * @override
     */
    _requiredFields() {
        let fields = PaymentPageUserFieldsPaymentModel.prototype._requiredFields.call(this).slice();

        fields = this._addAdditionalFields(fields);

        /**
         * To disable autofill, need to change attribute houseNumber to numberHouse
         * TODO when moving to react, need to create a task on core team so that the server can accepts the numberHouse field
         */
        fields.splice(fields.indexOf('houseNumber'), 1, 'numberHouse');

        return fields;
    },

    /**
     * @param {Array} fields
     * @private
     */
    _addAdditionalFields(fields) {
        fields.push('iban');
        return fields;
    },

    /**
     * @override
     */
    defaults: Object.assign({}, PaymentPageUserFieldsPaymentModel.prototype.defaults, {
        iban: null,

        /**
         * @type {Object|null}
         */
        sepaMandateFields: null,
    }),

}) {
    /**
     * @override
     * @return {object}
     */
    data() {
        const data = this._formatedData();
        const form = data[this.getFormName()];

        delete form[ACCOUNT_NUMBER];
        delete form[BANK_CODE];
        delete form['countryCode'];
        delete form[EXTRA_TAB_NAME];

        if (form.sepaMandateFields && form.scenario === PaymentScenario.ONECLICK) {
            form.isMaskedForm = true;
        }
        /**
         * Don't need in request
         */
        delete form.sepaMandateFields;

        if (form.scenario === PaymentScenario.ONECLICK) {
            delete form[IBAN];
        }

        data[this.getFormName()] = form;

        return data;
    }

    /**
     * @return {object}
     * @protected
     */
    _formatedData() {
        const data = PaymentPageUserFieldsPaymentModel.prototype.data.apply(this, arguments);

        /**
         * Assigned value numberHouse to houseNumber for correct validation on backend
         * and remove temp value numberHouse
         */
        if (!isUndefined(data[this.getFormName()].numberHouse)) {
            data[this.getFormName()].houseNumber = data[this.getFormName()].numberHouse;
            delete data[this.getFormName()].numberHouse;
        }

        return data;
    }

    /**
     * Extend parent class rules with SEPA extra fields
     * @override
     */
    rules() {
        return this._addAdditionalRules(super.rules());
    }

    /**
     * @param {Array} rules
     * @return {Array}
     * @rpotected
     */
    _addAdditionalRules(rules) {
        rules.push({
            attributes: ['iban'],
            validator: new IbanValidator({
                message: yiiT.t('paymentPageCommDoo', 'error.correct_your_data'),
            }),
            scenario: 'initial',
        });

        rules.push({
            attributes: ['numberHouse'],
            validator: new StringWithNumberValidator({
                message: yiiT.t('paymentPage', 'error.number_house'),
            }),
            scenario: 'initial',
        });

        rules.push({
            attributes: ['nameFirst'],
            validator: new EqualValidator({
                message: yiiT.t('paymentPage', 'error.equal_first_and_last'),
                unequalFields: ['nameLast'],
            }),
            scenario: 'initial',
        });

        rules.push({
            attributes: ['nameLast'],
            validator: new EqualValidator({
                message: yiiT.t('paymentPage', 'error.equal_first_and_last'),
                unequalFields: ['nameFirst'],
            }),
            scenario: 'initial',
        });

        return rules;
    }
}
