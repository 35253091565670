import isEmpty from 'lodash/isEmpty';

import currentUserId from 'components/cache/currentUserId';
import locale from 'components/cache/locale';
import siteName from 'components/cache/siteName';

import PaymentPagePaymentBaseModel from '../../paymentPage/models/PaymentPagePaymentBaseModel';

const PaymentPageAlternativePaymentModel = PaymentPagePaymentBaseModel.extend({
    urlRoot: '/pay/alternative/isAjaxMode/true',

    url() {
        return `${this.urlRoot}?via=${this.get('via')}&prevVia=${this.get('prevVia')}`;
    },

    data() {
        const data = this.toJSON();

        if (data.withRedirectPayment) {
            delete data.withRedirectPayment;
        }

        if (isEmpty(data.selectedAdditionalPackages)) {
            delete data.selectedAdditionalPackages;
        }

        return {
            [this.getFormName()]: data,
            forceRedirect: this.FORCE_REDIRECT,
            processor: this._getMethod(),
        };
    },

    /**
     * @param {BaseModel} selectedPackage
     * @param {Array} selectedAdditionalPackages
     * @param {Function} completeCallback
     * @param {string} prevVia
     * @public
     */
    pay(
        selectedPackage,
        selectedAdditionalPackages,
        completeCallback,
        prevVia
    ) {
        this.save({
            package_id: selectedPackage.get('packageId'),
            selectedAdditionalPackages,
            user_id: currentUserId(),
            product_id: selectedPackage.get('package').permission_set_id,
            domain: siteName(),
            currency_code: selectedPackage.get('price').currency.literal.code,
            locale: locale(),
            prevVia,
        }, {
            complete: (_, textStatus) => {
                completeCallback(textStatus);
            }
        });
    }
});
export default PaymentPageAlternativePaymentModel;
