import isUndefined from 'lodash/isUndefined';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import PaymentPageCardPaymentModel from 'app/mobSite/components/paymentPage/models/PaymentPageCardPaymentModel';
import PaymentPageSepaPaymentModel from 'app/mobSite/components/paymentPageUserFields/models/PaymentPageSepaPaymentModel';
import WalletPayPaymentModel from 'app/components/paymentPage/models/WalletPayPaymentModel';
import PaymentPageKonbiniPaymentModel from 'app/mobSite/components/paymentPageUserFields/models/PaymentPageKonbiniPaymentModel';
import PaymentPageIdealPaymentModel from 'app/mobSite/components/paymentPageUserFields/models/PaymentPageIdealPaymentModel';
import PaymentPageNuveiMyBankPaymentModel from 'app/mobSite/components/paymentPageUserFields/models/PaymentPageNuveiMyBankPaymentModel';
import PaymentPageNuveiIdealPaymentModel from 'app/mobSite/components/paymentPageUserFields/models/PaymentPageNuveiIdealPaymentModel';
import PaymentPageSofortPaymentModel from 'app/mobSite/components/paymentPageExternal/models/PaymentPageSofortPaymentModel';
import PaymentPageCCBillPaymentModel from 'app/components/paymentPageExternal/models/PaymentPageCCBillPaymentModel';
import PaymentPagePaypalPaymentModel from 'app/components/paymentPageExternal/models/PaymentPagePaypalPaymentModel';
import PaymentPageAlternativePaymentModel from 'app/mobSite/components/paymentPageExternal/models/PaymentPageAlternativePaymentModel';
import MidasPayPaymentModel from 'app/components/paymentPage/models/MidasPayPaymentModel';

var PaymentPagePayModelFactory = BaseModel.extend({
    /**
     * @const {string}
     */
    DEFAULT_SCENARIO: 'initial',

    /**
     * @param {boolean} clearPayModel - if true clear model before return
     * @param {object} params - model params
     * @param {boolean} withNewInstance - create new instance instead exist
     * @return {PaymentPagePaymentBaseModel}
     */
    getModel: function(clearPayModel, params, withNewInstance) {
        var method = params.method;

        if (!method) {
            return;
        }

        if (withNewInstance) {
            const payModel = this.__createPayModel(method);

            this.__fillModelParams(payModel, params);

            return payModel;
        }

        this.__payModels = this.__payModels || {};

        this.__payModels[method] = this.__payModels[method] || this.__createPayModel(method);

        if (clearPayModel) {
            this.__payModels[method].clear();
        }

        this.__fillModelParams(this.__payModels[method], params);
        return this.__payModels[method];
    },

    __createPayModel: function (method) {
        switch (method) {
            case PAYMENT_METHODS.CARD:
                return new PaymentPageCardPaymentModel();
            case PAYMENT_METHODS.APPLE_PAY:
            case PAYMENT_METHODS.GOOGLE_PAY:
                return new WalletPayPaymentModel({
                    cardWalletType: method,
                });
            case PAYMENT_METHODS.MIDAS:
                return new MidasPayPaymentModel();
            case PAYMENT_METHODS.TRUST_PAY_SEPA:
            case PAYMENT_METHODS.SALT_EDGE_AIS:
            case PAYMENT_METHODS.COMM_DOO_SEPA:
            case PAYMENT_METHODS.MICRO_PAYMENT_SEPA:
            case PAYMENT_METHODS.NOVAL_NET_SEPA:
            case PAYMENT_METHODS.VENDO_SEPA:
                return new PaymentPageSepaPaymentModel();
            case PAYMENT_METHODS.TRUST_PAY_IDEAL:
                return new PaymentPageIdealPaymentModel();
            case PAYMENT_METHODS.NUVEI_IDEAL:
                return new PaymentPageNuveiIdealPaymentModel();
            case PAYMENT_METHODS.NUVEI_MY_BANK:
                return new PaymentPageNuveiMyBankPaymentModel();
            case PAYMENT_METHODS.SOFORT:
                return new PaymentPageSofortPaymentModel();
            case PAYMENT_METHODS.KONBINI:
                return new PaymentPageKonbiniPaymentModel();
            case PAYMENT_METHODS.PAY_PAL:
            case PAYMENT_METHODS.PAY_PAL_V2:
                return new PaymentPagePaypalPaymentModel();
            case PAYMENT_METHODS.CCBILL:
                return new PaymentPageCCBillPaymentModel();
            default:
                return new PaymentPageAlternativePaymentModel();
        }
    },

    __fillModelParams: function(model, params) {
        const { method, via, isEmptyFormAfterDecline, declineScreenTemplate } = params;
        let scenario = this.DEFAULT_SCENARIO;

        if (params.altMethodsScenario && params.altMethodsScenario[params.method]) {
            scenario = params.altMethodsScenario[params.method];
        }

        if (params.scenario) {
            scenario = params.scenario;
        }

        const attributes = {
            via,
            method,
            scenario,
            declineScreenTemplate,
            isEmptyFormAfterDecline,
        };

        if (!isUndefined(params.oneLinePP)) {
            attributes.oneLinePP = params.oneLinePP;
        }

        if (params.altMethodsSettings && params.altMethodsSettings[params.method]) {
            attributes.altMethodsSettings = params.altMethodsSettings[params.method];
        }

        if (!isUndefined(params.isInstantRepeat)) {
            attributes.isInstantRepeat = params.isInstantRepeat;
        }

        if (params.additionalFields) {
            attributes.additionalFields = params.additionalFields;
        }

        if (params.sepaMandateFields) {
            attributes.sepaMandateFields = params.sepaMandateFields;
        }

        if (params.isAccountAndBank && params.country_code) {
            attributes.country_code = params.country_code;
        }

        if (params.isAccountAndBank) {
            attributes.isAccountAndBank = params.isAccountAndBank;
        }

        if (params.sepaAutofillAccess) {
            attributes.sepaAutofillAccess = params.sepaAutofillAccess;
        }

        if (params.formattedPhonePrefix) {
            model.formattedPhonePrefix = params.formattedPhonePrefix;
        }

        if (params.formName) {
            attributes.formName = params.formName;
        }

        if (params.additionalProducts) {
            attributes.additionalProducts = params.additionalProducts;
        }

        model.set(attributes);
        model.setScenario(scenario);
    }
});
export default PaymentPagePayModelFactory;
