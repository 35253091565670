import isUndefined from 'lodash/isUndefined';
import PaymentPageUserFieldsPaymentModel from 'app/mobSite/components/paymentPageUserFields/models/PaymentPageUserFieldsPaymentModel.js';
import IbanValidator from 'app/mobSite/components/paymentPage/validators/IbanValidator.js';
import StringWithNumberValidator from 'app/mobSite/components/application/validators/StringWithNumberValidator.js';
import EqualValidator from 'app/components/application/validators/EqualValidator';
import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import { IBAN } from '@phoenix/payment/forms/common/constants/fieldNames';

var PaymentPageSepaPaymentModel = PaymentPageUserFieldsPaymentModel.extend({

    /**
     * This methods allow hidden fields logic
     * @type {Array}
     */
    defaults: _.extend({}, PaymentPageUserFieldsPaymentModel.prototype.defaults, {
        iban: null,

        /**
         * @type {Object|null}
         */
        sepaMandateFields: null,
    }),

    /**
     * @override
     * @return {{data: string}}
     */
    data: function () {
        var data = PaymentPageUserFieldsPaymentModel.prototype.data.call(this);

        if (
            data[this.getFormName()].sepaMandateFields &&
            data[this.getFormName()].scenario === PaymentScenario.ONECLICK
        ) {
            data[this.getFormName()].isMaskedForm = true;
        }
        /**
         * Don't need in request
         */
        delete data[this.getFormName()].sepaMandateFields;

        /**
         * Assigned value numberHouse to houseNumber for correct validation on backend
         * and remove temp value numberHouse
         */
        if (!isUndefined(data[this.getFormName()].numberHouse)) {
            data[this.getFormName()].houseNumber = data[this.getFormName()].numberHouse;
            delete data[this.getFormName()].numberHouse;
        }

        if (data[this.getFormName()].scenario === PaymentScenario.ONECLICK) {
            delete data[this.getFormName()][IBAN];
        }

        return data;
    },

    /**
     * @override
     */
    _requiredFields: function () {
        const fields = PaymentPageUserFieldsPaymentModel.prototype._requiredFields.call(this);

        /**
         * To disable autofill, need to change attribute houseNumber to numberHouse
         * TODO when moving to react, need to create a task on core team so that the server can accepts the numberHouse field
         */
        fields.splice(fields.indexOf('houseNumber'), 1, 'numberHouse');

        fields.push('iban');

        return fields;
    },

    /**
     * @override
     */
    rules: function () {
        const rules = PaymentPageUserFieldsPaymentModel.prototype.rules.apply(this);

        return rules.concat([{
            attributes: ['iban'],
            validator: new IbanValidator({
                message: yiiT.t('paymentPageCommDoo', 'error.correct_your_data'),
            }),
            scenario: this.VALIDATION_SCENARIO,
        }, {
            attributes: ['numberHouse'],
            validator: new StringWithNumberValidator({
                message: yiiT.t('paymentPage', 'error.number_house'),
            }),
            scenario: this.VALIDATION_SCENARIO,
        }, {
            attributes: ['nameFirst'],
            validator: new EqualValidator({
                message: yiiT.t('paymentPage', 'error.equal_first_and_last'),
                unequalFields: ['nameLast'],
            }),
            scenario: this.VALIDATION_SCENARIO,
        }, {
            attributes: ['nameLast'],
            validator: new EqualValidator({
                message: yiiT.t('paymentPage', 'error.equal_first_and_last'),
                unequalFields: ['nameFirst'],
            }),
            scenario: this.VALIDATION_SCENARIO,
        }]);
    },
});
export default PaymentPageSepaPaymentModel;
