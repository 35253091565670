import Validator from 'app/components/application/Validator';

/**
 * PhoneValidator
 * @extends Validator
 */
const PhoneValidator = Validator.extend({
    defaults: {
        message: ''
    },

    /**
     * @param {string} value
     * @return {boolean}
     * @protected
     */
    _validateValue(value) {
        return /^[0-9]*$/.test(value);
    },
});

export default PhoneValidator;
