import Validator from 'app/mobSite/components/application/Validator.js';
import LocalStorage from 'app/components/storage/storage.js';
import IBAN from 'iban';

/**
 * International Bank Account Number validator
 * It's just an adaptation of plugin (https://github.com/arhs/iban.js/) into internal validation system
 */
var IbanValidator = Validator.extend({
    VALIDATE_SKIP_KEY: 'IbanValidation::skip',

    defaults: {
        message: ''
    },

    /**
     * @overriden
     */
    initialize: function() {
        this.storage = new LocalStorage();
    },

    /**
     * @overriden
     */
    _validateValue: function (value) {
        /**
         * @todo This is needed for testing purposes
         */
        if (this.storage.getItem(this.VALIDATE_SKIP_KEY)) {
            return true;
        }

        return IBAN.isValid(value);
    }

});
export default IbanValidator;
