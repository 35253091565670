import Validator from 'components/application/Validator';

/**
 *
 */
var DateValidator = Validator.extend({
    defaults: {
        message: '',

        /**
         * @var name of attribute with month value
         */
        monthAttribute: '',

        /**
         * @var name of attribute with year value
         */
        yearAttribute: '',

        /**
         * @var instance of Date object or null if skip this check
         * if not null check attribute that it biggest than given at this option date
         */
        minDate: null,

        /**
         * @var Date|null instance of Date object or null if skip this check
         * if not null check attribute that it smaller than given at this option date
         */
        maxDate: null,

        /**
         * if true don't validate non number values
         */
        skipOnNotNumber: false
    },

    _validateAttribute: function(model, attribute, attributes) {
        var year = attributes[this.options.yearAttribute];
        var month = attributes[this.options.monthAttribute];

        if (!this._validateValue(month, year)) {
            model.addError(attribute, this.options);
        }
    },

    _validateValue: function(month, year) {
        if (!this._isNumber(month) || !this._isNumber(year)) {
            return this.options.skipOnNotNumber;
        }

        if (!this._checkYear(year) || !this._checkMonth(month)) {
            return false;
        }

        var value = new Date(year, month);

        return  this._checkMaxDate(value) && this._checkMinDate(value);
    },

    _checkMinDate: function(value) {
        if (this.options.minDate instanceof Date) {
            return value.getTime() > this.options.minDate.getTime();
        }

        return true;
    },

    _checkMaxDate: function(value) {
        if (this.options.maxDate instanceof Date) {
            return value.getTime() < this.options.maxDate.getTime();
        }

        return true;
    },

    _isNumber: function(value) {
        return String(value).match(/^\d+$/);
    },

    _checkYear: function(value) {
        return value > 1000 && value < 3000;
    },

    _checkMonth: function(value) {
        return value >=1 && value <= 12;
    }
});
export default DateValidator;
