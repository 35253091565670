import getTheme from '@core/application/utils/getTheme';

/**
 * @const {Object}
 */
const THEME_NAME = {
    DEFAULT: 'material',
    FRESH: 'fresh',
    MANEKO: 'maneko',
    FORCE: 'force',
    RISE: 'rise',
    GLASS: 'glass',
    LOVEIS: 'loveis',
    MOON: 'moon',
    SKY: 'sky',
    MATERIAL_INVERSE: 'materialInverse',
};

/**
 * @type {string[]}
 */
const DEFAULT_STYLES_BY_THEME_COMPONENTS_LIST = {
    [THEME_NAME.MANEKO]: [ 'Card', 'Checkbox', 'Loader', 'Popover', 'Radio', 'Tab', 'Tag', 'Tooltip' ],
    [THEME_NAME.FORCE]: [ 'Card', 'Checkbox', 'Loader', 'Popover', 'Radio', 'Tab', 'Tag', 'Tooltip' ],
    [THEME_NAME.GLASS]: [ 'Checkbox', 'Loader', 'Popover', 'Radio', 'Steps', 'Tab', 'Tag', 'Tooltip' ],
    [THEME_NAME.LOVEIS]: [ 'Card', 'Checkbox', 'Loader', 'Radio', 'Tab', 'Tag', 'Tooltip' ],
    [THEME_NAME.MOON]: [ 'Card', 'Checkbox', 'Loader', 'Radio', 'Steps', 'Tab', 'Tag', 'Tooltip' ],
    [THEME_NAME.SKY]: [ 'Card', 'Checkbox', 'Loader', 'Popover', 'Radio', 'Tab', 'Tag', 'Tooltip' ],
};

/**
 *
 * @type {function(Array): {}}
 * @param styleNameList
 * @return {{}}
 */
const getThemeStyles = styleNameList => {
    const theme = getTheme();
    const list = {};

    styleNameList.forEach(key => {
        const name = key.toLowerCase();

        // Since the UI is the same
        if (theme.ui === THEME_NAME.RISE ) {
            theme.ui = THEME_NAME.FORCE;
        }

        const ifThemeInDefaultStyles = DEFAULT_STYLES_BY_THEME_COMPONENTS_LIST[theme.ui];

        if ( ifThemeInDefaultStyles && ifThemeInDefaultStyles.includes(key)) {
            list[name] = require(`components/mainStyles/styles/${THEME_NAME.DEFAULT}/${key}.css`);
        } else {
            list[name] = require(`components/mainStyles/styles/${theme.ui}/${key}.css`);
        }
    });

    return list;
};

export default getThemeStyles;
