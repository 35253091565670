import {gql} from '@apollo/client';
import {getClientInstance} from '@core/graphql/client';

/**
 * Returns current locale from apollo cache.
 * @deprecated
 * @return {string}
 */
export default () =>
  getClientInstance().readQuery({
    query: gql`query {
      locale
    }`
  }).locale;
