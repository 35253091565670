import isUndefined from 'lodash/isUndefined';

import PaymentPageCardPaymentModel from 'components/paymentPage/models/PaymentPageCardPaymentModel';
import PaymentPageAlternativePaymentModel from 'components/paymentPageExternal/models/PaymentPageAlternativePaymentModel';
import PaymentPagePaypalPaymentModel from 'components/paymentPageExternal/models/PaymentPagePaypalPaymentModel';
import PaymentPageCCBillPaymentModel from 'components/paymentPageExternal/models/PaymentPageCCBillPaymentModel';
import PaymentPageSofortPaymentModel from 'components/paymentPageExternal/models/PaymentPageSofortPaymentModel';
import PaymentPageKonbiniPaymentModel from 'components/paymentPageUserFields/models/PaymentPageKonbiniPaymentModel';
import PaymentPageSepaPaymentModel from 'app/components/paymentPageUserFields/models/PaymentPageSepaPaymentModel';
import PaymentPageIdealPaymentModel from 'components/paymentPageUserFields/models/PaymentPageIdealPaymentModel';
import PaymentPageNuveiIdealPaymentModel from 'components/paymentPageUserFields/models/PaymentPageNuveiIdealPaymentModel';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import PaymentPageNuveiMyBankPaymentModel from "components/paymentPageUserFields/models/PaymentPageNuveiMyBankPaymentModel";

/**
 * @class PaymentPagePayModelFactory
 * @classdesc Default factory for pay models
 */
export default class PaymentPagePayModelFactory {
    static get DEFAULT_SCENARIO() {
        return 'initial';
    }

    /**
     * @param isClearModel {boolean} - if true clear model before return
     * @param params.altMethodsScenario {object}
     * @param params.method {string}
     * @param params.via {string}
     * @param params.isAccountAndBank {boolean}
     * @public
     */
    static getModel(isClearModel, params) {
        const modelIndex = params.method;

        if (!modelIndex) {
            return;
        }

        this._payModels = this._payModels || {};

        this._payModels[modelIndex] = this._payModels[modelIndex] ||
            PaymentPagePayModelFactory.__createPayModel(modelIndex);

        if (isClearModel) {
            this._payModels[modelIndex].clear();
        }

        this.__fillModelParams(this._payModels[modelIndex], params);

        return this._payModels[modelIndex];
    }

    /**
     * @param {string} modelIndex
     * @return {BaseModel}
     * @private
     */
    static __createPayModel(modelIndex) {
        switch (modelIndex) {
            case PAYMENT_METHODS.CARD:
                return new PaymentPageCardPaymentModel();
            case PAYMENT_METHODS.NOVAL_NET_SEPA:
            case PAYMENT_METHODS.COMM_DOO_SEPA:
            case PAYMENT_METHODS.TRUST_PAY_SEPA:
            case PAYMENT_METHODS.SALT_EDGE_AIS:
            case PAYMENT_METHODS.MICRO_PAYMENT_SEPA:
            case PAYMENT_METHODS.VENDO_SEPA:
                return new PaymentPageSepaPaymentModel();
            case PAYMENT_METHODS.TRUST_PAY_IDEAL:
                return new PaymentPageIdealPaymentModel();
            case PAYMENT_METHODS.NUVEI_IDEAL:
                return new PaymentPageNuveiIdealPaymentModel();
            case PAYMENT_METHODS.NUVEI_MY_BANK:
                return new PaymentPageNuveiMyBankPaymentModel();
            case PAYMENT_METHODS.SOFORT:
                return new PaymentPageSofortPaymentModel();
            case PAYMENT_METHODS.KONBINI:
                return new PaymentPageKonbiniPaymentModel();
            case PAYMENT_METHODS.PAY_PAL:
            case PAYMENT_METHODS.PAY_PAL_V2:
                return new PaymentPagePaypalPaymentModel();
            case PAYMENT_METHODS.CCBILL:
                return new PaymentPageCCBillPaymentModel();
            default:
                return new PaymentPageAlternativePaymentModel();
        }
    }

    /**
     * @param model {model}
     * @param params.altMethodsScenario {object}
     * @param params.userAttributes {object}
     * @param params.method {string}
     * @param params.formName {string}
     * @param params.via {string}
     * @private
     */
    static __fillModelParams(model, params) {
        const {
            altMethodsScenario,
            method,
            via,
            additionalFields,
            sepaAutofillAccess,
            isIdealAutofillData,
            formattedPhonePrefix,
            formName,
            isAccountAndBank,
            sepaMandateFields,
            isEmptyFormAfterDecline,
        } = params;
        let scenario = (altMethodsScenario && altMethodsScenario[method]) ?
            altMethodsScenario[method] : this.DEFAULT_SCENARIO;

        if (params.scenario) {
            scenario = params.scenario;
        }

        model.setScenario(scenario);

        const attributes = {
            method,
            scenario,
            via,
            isEmptyFormAfterDecline,
        };

        if (additionalFields) {
            attributes.additionalFields = additionalFields;
        }

        if (sepaMandateFields) {
            attributes.sepaMandateFields = sepaMandateFields;
        }

        if (isAccountAndBank) {
            attributes.isAccountAndBank = isAccountAndBank;
        }

        if (params.altMethodsSettings && params.altMethodsSettings[params.method]) {
            attributes.altMethodsSettings = params.altMethodsSettings[params.method];
        }

        if (sepaAutofillAccess) {
            attributes.sepaAutofillAccess = sepaAutofillAccess;
        }

        if (!isUndefined(params.isInstantRepeat)) {
           attributes.isInstantRepeat = params.isInstantRepeat;
        }

        if (isIdealAutofillData) {
            attributes.isIdealAutofillData = isIdealAutofillData;
        }

        if (formName) {
            attributes.formName = formName;
        }

        // This field needs for correct work of input mask and validation, there's no need to send this field to server
        if (formattedPhonePrefix) {
            model.formattedPhonePrefix = formattedPhonePrefix;
        }

        model.set(attributes);
    }
}
