import getThemeStyles from './getThemeStyles';
const themeStyles = getThemeStyles(['Loader']);

/**
 * @class RegionLayout
 * @param {String} selector
 * @param {String} $parentEl
 * @param {Function} addPortal
 * @constructor
 */

var Region = function(selector, $parentEl, addPortal) {
    this.selector = selector;
    this.views = [];
    this.widgets = [];
    this.$parentEl = $parentEl;
    this.$appendEl = null;
    this.$prependEl = null;
    this.addPortal = addPortal;
};

_.extend(Region.prototype, Backbone.Events, {
    /**
     * This add mode is used to collect all views and insert them into the DOM at once.
     */
    ADD_MODE_WIDGET: 'widget',

    /**
     * Normal add mode. Used by default.
     */
    ADD_MODE_APPEND: 'append',

    /**
     * Reverse add mode.
     */
    ADD_MODE_PREPEND: 'prepend',

    READY_EVENT_NAME: 'ready',

    //return jQuery element of region
    $el: function() {
        if (this.$parentEl !== undefined) {
            return this.$parentEl.find(this.selector);
        }

        return $(this.selector);
    },

    /**
     * render view or layout into this.$el() and initialize view sub-regions
     * @param view
     * @param options
     * @param isPreClose
     * @returns {view}
     */
    render: function(view, options, isPreClose) {
        var instance;

        if(isPreClose === undefined) {
            isPreClose = true;
        }

        if(typeof view !== 'function') throw new TypeError(`Region can render Backbone.View or Backbone.Layout. You've passed: ${typeof view}`);

        if(isPreClose) {
            this.close();
        }

        options = options || {};

        _.extend(options, {
            region: this
        });

        instance = new view(options);

        instance._render();
        this._addView(instance);

        this._appendElement(instance.el);

        return instance;
    },

    widget: function(view, options) {
        var instance;

        if(typeof view !== 'function') throw new TypeError('Region can render Backbone.View or Backbone.Layout');

        options = options || {};

        _.extend(options, {
            region: this
        });

        instance = new view(options);
        instance._render();

        this._addView(instance);

        return instance;
    },

    /**
     * Insert all collected widgets in DOM.
     *
     * @param data - Optional array of data to render using this widgets.
     * @param view - Optional widget view for each element of the data.
     * @param viewOptionsCallback - Optional callback to provide the view with options.
     *                              Will be called for each item of data.
     * @returns {BaseView[]}
     */
    renderWidgets: function(data, view, viewOptionsCallback) {
        var widgets = this.widgets;
        if ((_.isArray(data) || data instanceof Backbone.Collection) && !_.isUndefined(view)) {
            data.forEach(function(item, index) {
                this.addWidget(view, _.isFunction(viewOptionsCallback) ?
                    viewOptionsCallback(item, index) :
                    { model: item instanceof Backbone.Model ? item : new BaseModel(item) });
            }.bind(this));
        }

        if(_.isBoolean(data) && data) {
            this._prependElement(this.widgets);
        } else {
            this._appendElement(this.widgets);
        }

        this.widgets = [];
        return widgets;
    },

    /**
     * Sets wrapper to element
     *
     * @private
     * @param el - DOM object
     * @param className - css className string
     * @returns {Object} - jQuery object
     */
    _setWrapper: function(el, className) {
        return $('<div class="' + className + '"></div>').append(el);
    },

    /**
     * Add view to this region.
     *
     * @param view - View name.
     * @param options - Options for the view.
     * @param mode - Add mode. One of this.ADD_MODE_*.
     * @param cssWrapperClass - css className String to wrap each widget
     */
    addView: function(view, options, mode, cssWrapperClass) {
        var instance;
        var $el;

        if(typeof view !== 'function') throw new TypeError('Region can render Backbone.View or Backbone.Layout');

        options = options || {};

        _.extend(options, {
            region: this
        });

        instance = new view(options);

        this._addView(instance, mode);

        instance._render();

        if (cssWrapperClass) {
            $el = this._setWrapper(instance.el, cssWrapperClass);
        } else {
            $el = instance.el;
        }

        switch (mode) {
            case this.ADD_MODE_WIDGET:
                this.widgets.push($el);
                break;

            case this.ADD_MODE_PREPEND:
                this._prependElement($el);
                break;

            case this.ADD_MODE_APPEND:
            default:
                this._appendElement($el);
                break;
        }

        return instance;
    },

    /**
     * Alias for addView with widget use case.
     */
    addWidget: function(view, options, cssWrapperClass) {
        return this.addView(view, options, this.ADD_MODE_WIDGET, cssWrapperClass);
    },

    _addView: function(instance, mode) {
        if (mode == this.ADD_MODE_PREPEND) {
            this.views.unshift(instance);
        } else {
            this.views.push(instance);
        }
    },

    /**
     * @public function
     * Check views in region
     * return boolean
     * */
    isEmpty: function () {
        return !this.views.length;
    },

    /**
     * @param {bool} preserveDOM
     *   If true - no DOM manipulations will be performed.
     *   This attribute is needed to decrease quantity of DOM removings on
     *   recursive region close.
     *   For e.g. when user navigate from search to chat without this feature
     *   will be performed ~1500 DOM removings. With this - around 500.
     *
     * @public
     */
    close: function (preserveDOM  = false) {
        _.each(this.views, function(view) {
            view.remove(true);
        });

        this.views = [];
        this.widgets = [];

        if (!preserveDOM) {
            this.$el().empty();
        }

        this.$appendEl = null;
        this.$prependEl = null;

        return this;
    },

    saving: function() {
        this.$el().addClass('is-saving');

        return this;
    },

    loading: function() {
        this.$el().addClass(themeStyles.loader.active);

        return this;
    },

    ready: function() {
        this.$el().removeClass([ 'is-saving', themeStyles.loader.active ].join(' '));
        this.trigger(this.READY_EVENT_NAME);
        return this;
    },

    setAppendElement: function ($element) {
        this.$appendEl = $element;
    },

    setPrependElement: function ($element) {
        this.$prependEl = $element;
    },

    /**
     * Invoke method with arguments (if those were set) by each view in region and return their results
     * For details
     * @see https://lodash.com/docs/4.17.5#invokeMap
     * @param args
     */
    invokeChild(...args) {
        return _.invokeMap(this.views, ...args);
    },

    _appendElement: function($element) {
        if (this.$appendEl) {
            this.__insertElement(this.$appendEl, 'beforebegin', $element);
        } else {
            this.__insertElement(this.$el(), 'beforeend', $element);
        }
    },

    _prependElement: function($element) {
        if (this.$prependEl) {
            this.__insertElement(this.$prependEl, 'afterend', $element);
        } else {
            this.__insertElement(this.$el(), 'afterbegin', $element);
        }
    },

    __insertElement: function($container, position, $element) {
        if (!$container.length) {
            return false;
        }

        const container = $container.get(0);
        // in some cases html already insert
        if (container !== $element) {
            // insertAdjacentElement not support in old Firefox
            if (container.insertAdjacentElement) {
                const elements = (_.isArray($element) ? $element : [$element])
                    .map(element => (element instanceof $) ? element.get(0) : element);

                _.each(elements, element => container.insertAdjacentElement(position, element));
            // fallback for Firefox 47-
            } else {
                $container[this.__getMethodByPosition(position)]($element);
            }
        }

        // triggered for all views after add to dom view el
        this.trigger('afterInsert');

        return true;
    },

    /**
     * Get jQuery method by insertAdjacent position
     *
     * @param position {string} insertAdjacent* position
     * @return {string} jQuery method name
     * @private
     */
    __getMethodByPosition: function (position) {
        switch(position) {
            case 'afterend':
                return 'after';
            case 'beforebegin':
                return 'before';
            case 'beforeend':
                return 'append';
            case 'afterbegin':
                return 'prepend';
        }

        throw new Error('Region Layout: Undefined position (for old browser without insertAdjacentElement)');
    },
});

export default Region;
