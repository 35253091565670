import { gql } from '@apollo/client';

import { getClientInstance } from '@core/graphql/client';
import { SAFEDATING_LINK } from '@core/application/constants/staticLinks';
import { encodeLink } from '@core/application/utils/hashedStaticLinks';
import isCompactViewportCached from '@core/responsive/isCompactViewportCached';
import logger from '@core/logger';
import URI from '@core/utils/url';

import SafeMode from '@phoenix/user/profile/current/constants/SafeMode';
import SEARCH_SAFE_MODE_QUERY from '@phoenix/search/list/graphql/queries/searchSafeMode.gql';

import SafeModeModel from 'components/safeMode/models/SafeModeModel';
import PaymentPageModelFactory from 'components/paymentPage/PaymentPageModelFactory';
import PaymentPageModelFactoryMobile from 'app/mobSite/components/paymentPage/PaymentPageModelFactory';
import BackButton from 'components/backButton/BackButton';
import {DONT_PUSH_ROUTES as DONT_PUSH_ROUTES_WEB} from 'app/components/backButton/WebConstants.js';
import {DONT_PUSH_ROUTES as DONT_PUSH_ROUTES_MOB} from 'app/components/backButton/MobConstants.js';
import clearUrl from 'components/application/clearUrl';
import css from '@phoenix/application/styles/AppLayout.css';

import 'normalize.css';

/**
 * Polyfills for Explorer support. Because webpack doesn't polyfill them.
 * @see https://github.com/zloirock/core-js/issues/354
 * @see https://github.com/roderickhsiao/react-in-viewport#polyfill
 */
import 'events-polyfill';
import 'element-matches';
import 'intersection-observer';

import isPayUrl from '@core/utils/url/isPayUrl';
import disableClickHandler from 'components/application/disableClickHandler';

require('components/application/polyfills');
require('plugins/completeChange/completeChange');
require('components/appInit/addBodyClasses');

/**
 * Use React + GraphQL instead.
 * @deprecated
 * @class AppView
 * @extends BaseView
 * @todo Replace all window.open() calls with window.app.gotoUrl(link)
 */
export default BaseView.extend({
    id: 'root',
    className: css.root,
    router: null,

    regions: {
        content: '#contentContainer',
    },

    _isAllowed() {
        const { isBanned, isAuthorizedZone } = getClientInstance().readQuery({
            query: gql`query {
              isBanned
              isAuthorizedZone
            }`,
        });
        return !isBanned && isAuthorizedZone;
    },

    /**
     * @override
     */
    ready: function() {},

    initialize: function initialize() {
        if (typeof this.options.sitename !== 'string') {
            // TODO: Provide a default theme.
            logger.sendError('No theme found for ' + this.options.sitename + ' domain!');
        }

        /**
         * Keep some props like `router` of `window.app` created by {@see renderApp}.
         */
        Object.assign(this, window.app);

        /**
         * Override window.app here to avoid errors during some services
         * synchronous initialize. For example {@see __initBackButton}.
         */
        window.app = this;

        this.onTranslationsReady();
    },

    render: function () {
        return this;
    },

    onTranslationsReady: function () {
        // initRouter() should be called before ready() since router is used in ready()
        this.__initRouter();
        this.ready();
        /**
         * Trigger ControllerRun after ready call
         * Because there is listener on change history in ready method logic
         */
        this.router.triggerHistoryChange();

        this._initGlobalEvents();
    },

    /**
     * @private
     */
    __initRouter: function () {
      if (this._isAllowed()) {
        this.__initRouterDependentServices();
      }

      this.regions.content.ready();
    },

    /**
     * @deprecated can be removed after the complete transfer payment page to react
     */
    initSafeMode: function () {
      const data = getClientInstance().readQuery({query: SEARCH_SAFE_MODE_QUERY});
      this.safeMode = new SafeModeModel({
        available: true,
        availableModes: Object.values(SafeMode),
        mode: data.userFeatures?.safeMode,
        tipLink: encodeLink(SAFEDATING_LINK),
      });
    },

    /**
     * Temporary solution to remove a global event listener on each link
     * @todo remove after all bb <a> tags will be deleted
     * @deprecated Cannot be transferred because it is used in different mob and web models in which the logic is
     * significantly different, and since there is no understanding of where the method will be called from which model,
     * at this stage it is impossible to remove from the app view
     * @public
     * @param {Event} event
     */
    handleBackboneLinkClick: function (event) {
        const url = clearUrl($(event.currentTarget).attr('href'));

        if (url) {
            event.preventDefault();

            if (!url.startsWith('/')) {
                this.gotoUrl(url);
            } else {
                this.router.navigate(url, {
                    trigger: true
                });
            }
        }
    },

    /**
     * @deprecated Can be removed after move all app to react
     * @private
     */
    _initGlobalEvents: function () {
        /**
         * Apply global UI helpers.
         * @todo Move each helper to the corresponding view.
         */
        require('components/application/uiHelpers');
    },

    /**
     * @deprecated can be removed after the complete transfer payment page to react
     * @private
     */
    _initPaymentPageModelFactory: function () {
        if (isCompactViewportCached()) {
            this.paymentPageModelFactory = new PaymentPageModelFactoryMobile();
        } else {
            this.paymentPageModelFactory = new PaymentPageModelFactory();
        }
    },

    /**
     * @deprecated Cannot be transferred because it is used in different mob and web models in which the logic is
     * significantly different, and since there is no understanding of where the method will be called from which model,
     * at this stage it is impossible to remove from the app view
     * @param {Object|String} url
     * @param {Boolean} blank
     * @param {Object|void} params
     */
    gotoUrl: function (url, blank, params) {
        if (_.isUndefined(url) || _.isNull(url)) {
            logger.sendWarning('Can\'t go to undefined URL');
            return;
        }
        if (_.isObject(url)) {
            url = `${url[0]}${url['via'] ? '?via=' + url['via'] : ''}`;
        }

        if (isPayUrl(url)) {
            this.goToPP(url, blank, params);
            return;
        }

        if (blank) {
            window.open(url);
        } else {
            // Check if provided url belongs to the current domain
            if (URI().host() !== URI(url).host()) {
                document.addEventListener('click', disableClickHandler, true);
            }
            $.gotoUrl(url);
        }
    },

    /**
     * @deprecated Cannot be transferred because it is used in different mob and web models in which the logic is
     * significantly different, and since there is no understanding of where the method will be called from which model,
     * at this stage it is impossible to remove from the app view
     * @param {Object|String} url
     * @param {Boolean} blank
     * @param {Object} params
     */
    goToPP: function (url, blank, params) {
        if (isCompactViewportCached()) {
            this.__goToPPMob(url, blank, params);
        } else {
            this.__goToPPWeb(url, blank, params);
        }
    },

    __goToPPWeb: function (url, blank, params) {
        params = params || {};

        document.removeEventListener('click', disableClickHandler, true);

        url = clearUrl(url);

        // Decide to reload or note page inside react routing. Here redirect everytime with router
        this.router.navigate(url, {
            trigger: true
        }, params);
    },

    __goToPPMob: function (url, blank, params) {
        params = params || {};

        document.removeEventListener('click', disableClickHandler, true);

        url = clearUrl(url);
        var disabledVias = [ 'profiles_limit', 'paidfunnel_utm_sub_reqpaid3d' ];
        var disableReturnPath = new RegExp(disabledVias.join('|')).test(url);

        params.returnPath = disableReturnPath ? false : params.returnPath;

        if (!isPayUrl(url)) {
            document.addEventListener('click', disableClickHandler, true);
        }

        // Decide to reload page or not inside react routing. Here redirect every time with router
        this.router.navigate(url, {
            trigger: true
        }, params);
    },

    /**
     * @deprecated can be removed after the complete transfer payment page to react
     * @private
     */
    __initBackButton: function () {
        const DONT_PUSH_ROUTES = isCompactViewportCached() ? DONT_PUSH_ROUTES_MOB : DONT_PUSH_ROUTES_WEB;
        this.backButton = new BackButton({
            defaultRoute: '/search',
            DONT_PUSH_ROUTES
        });
    },

    /**
     * Initalize all routing-dependet services.
     * @deprecated Can't remove now use for init logics for backbone, remove after move all app to react
     * @private
     */
    __initRouterDependentServices() {
        this.initSafeMode();
        this.__initBackButton();
    },
});
