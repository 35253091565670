import PaymentPageModel from 'components/paymentPage/models/PaymentPageModel';

/**
 * @class PaymentPageFeaturesModel
 * @see PaymentPageModel
 */
export default PaymentPageModel.extend({

    urlRoot: '/pay/features/isJsonMode/true',

    actionId: 'features',

    getCorrectedScene: function() {
        return 'features';
    }
});
