import { NAME_MIN_LENGTH, NAME_MAX_LENGTH } from '@phoenix/payment/forms/common/constants/nameFieldLength';
import PaymentPageIbanPaymentModel from 'app/mobSite/components/paymentPageExternal/models/PaymentPageIbanPaymentModel';
import RequiredValidator from 'app/mobSite/components/application/validators/RequiredValidator.js';
import NotZeroValidator from 'app/mobSite/components/application/validators/NotZeroValidator.js';
import RegexpValidator from 'app/mobSite/components/application/validators/RegexpValidator.js';
import StringValidator from '../../application/validators/StringValidator';
import getFieldsTranslate from 'components/paymentPageUserFields/getFieldsTranslate';
import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import { NAME_FIRST, NAME_LAST, STREET, POSTAL_CODE, CITY } from '@phoenix/payment/forms/common/constants/fieldNames';

/**
 * @TODO: add NUMBER_HOUSE and HOUSE_NUBMER constants to fieldNames
 */
const HOUSE_NUMBER = 'houseNumber';

var PaymentPageUserFieldsPaymentModel = PaymentPageIbanPaymentModel.extend({

    defaults: {
        package_id: null,
        nameFirst: null,
        nameLast: null,
        street: null,
        houseNumber: null,
        postalCode: null,
        city: null,
        /**
         * Setting in model while creating specimen
         * @see paymentPageModel.getCurrentPaymentStage()
         */
        via: '',
    },

    /**
     * @const {String[]}
     * @protected
     */
    VALIDATION_SCENARIO: ['initial'],

    /**
     * @return {String[]|Array<{{attribute: String, scenario: {String}}}>>} an array of required fields
     * @protected
     */
    _requiredFields: function () {
        return [
            'nameFirst',
            'nameLast',
            'street',
            'houseNumber',
            'postalCode',
            'city',
        ];
    },

    initialize: function () {
        PaymentPageIbanPaymentModel.prototype.initialize.call(this);
        this.on('error', this.onError);
    },

    parse: function (attrs) {
        if (_.includes([ 'black', 'yellow', 'red' ], attrs.status)) {
            attrs.actionStatus = attrs.status;
            // Emulate "error" status when risk check is failed.
            attrs.status = false;
        }

        return attrs;
    },

    data() {
        const data = PaymentPageIbanPaymentModel.prototype.data.call(this);

        if (data[this.getFormName()].scenario === PaymentScenario.ONECLICK) {
            // Delete default values
            [ NAME_FIRST, NAME_LAST, STREET, HOUSE_NUMBER, POSTAL_CODE, CITY ]
                .forEach(key => delete data[this.getFormName()][key]);
        }

        return data;
    },

    getRequiredFieldsRules: function () {
        const rules = [];

        this._requiredFields().forEach((_attribute) => {
            let attribute, scenario = this.VALIDATION_SCENARIO;

            /**
             * Required field can be either an {attribute: String, scenario: String} or String.
             */
            if (_.isObject(_attribute)) {
                attribute = _attribute.attribute;
                /**
                 * There is a case when attribute should be validated on specific scenario only
                 * (which differs from default scenario)
                 *
                 * This is needed because `rules` are cached (this.rules is called only once),
                 * so there is no other way besides scenario to validate field in the specific cases.
                 * By the way, scenario **is** designed specifically for that case.
                 *
                 * @type {String}
                 */
                scenario = _attribute.scenario || this.VALIDATION_SCENARIO;
            } else {
                attribute = _attribute;
            }

            const isRequiredMessage = yiiT.t('paymentPage', 'error.is_required', {
                '{attribute}': getFieldsTranslate(attribute),
            });

            rules.push({
                attributes: [attribute],
                validator: new RequiredValidator({
                    message: isRequiredMessage
                }),
                scenario: scenario,
            }, {
                attributes: [attribute],
                validator: new NotZeroValidator({
                    message: isRequiredMessage
                }),
                scenario: scenario,
            });
        });

        return rules;
    },

    rules: function () {
        const rules = this.getRequiredFieldsRules();

        rules.push({
            attributes: [ 'nameFirst', 'nameLast' ],
            validator: new StringValidator({
                message: yiiT.t('paymentPage', 'text.your_name_length', {
                    '{min}': NAME_MIN_LENGTH,
                    '{max}': NAME_MAX_LENGTH,
                }),
                min: NAME_MIN_LENGTH,
                max: NAME_MAX_LENGTH,
            }),
            scenario: this.VALIDATION_SCENARIO
        });

        rules.push({
            attributes: [ 'nameFirst', 'nameLast' ],
            validator: new RegexpValidator({
                message: yiiT.t('paymentPage', 'text.use_only_letters'),
                regexp: new RegExp('^[A-Za-zÂÀÁÄÉÈÊËÍÏÖÓÜäáèàâêéëïíóöüß ]{2,}$'),
            }),
            scenario: this.VALIDATION_SCENARIO,
        });

        return rules;
    },

    /**
     * Check if error response contains one of fields from form.
     * If it have at least one - it's fully validation error
     * @protected
     */
    onError: function (model, response) {
        if (_.intersection(_.keys(response), this._requiredFields()).length) {
            this.set('haveValidationErrors', true);
        }
    },

});
export default PaymentPageUserFieldsPaymentModel;
