'use strict';

import { userLink, chatLink, secureReproxy } from 'components/application/helpers';
import getThemeStyles from './getThemeStyles';
const themeStyles = getThemeStyles([
    'Button',
    'Card',
    'Checkbox',
    'Select',
    'Loader',
    'Tab',
    'Tag',
    'Popover',
    'Tooltip',
    'Input',
    'Steps',
    'Radio'
]);

/**
 * @class tpl
 * @type {{render: tpl.render}}
 */
var tpl = {
    /**
     * @param {Function} template
     * @param {Object|void} data
     * @return {String}
     * @public
     */
    render: function render(template, data = {}) {
        var output = '';

        var extendedData = data;

        if (typeof template === 'function') {
            _.defaults(extendedData, {
                _t: yiiT.t.bind(yiiT),
                userLink,
                chatLink,
                secureReproxy,
                ui: themeStyles
            });

            output = template(extendedData);
        } else {
            throw new TypeError('Type of template should be a function. You\'ve provided ' + template);
        }

        return output;
    }
};

export default tpl;
