import Backbone from 'backbone';
import logger from '@core/logger';
import defaultController from 'components/cache/defaultController';

/**
 * @class AbstractRouter
 * @extends Backbone.Router
 */
export default Backbone.Router.extend({

    /**
     * @type {String}
     */
    prevPlace: "",

    /**
     * @type {String}
     */
    place: "",

    /**
     * Determines whether site navigation is blocked
     * e.g. @see VideoChatView.js
     * use lockNavigation() and unlockNavigation methods to change
     * @type {boolean}
     * @private
     */
    _isNavigationLocked: false,

    params: null,
    controllers: null,
    currentRoute: {},

    /**
     * @param {string} fragment
     * @param {Object} options
     * @param {Object} params
     */
    navigateByRoute: function (fragment, options, params) {
        Backbone.history.navigate(fragment, options, params);
    },

    navigate: function(fragment, options, params) {
        if (fragment && fragment.substr(0, 2) === "\/#") {
            logger.sendWarning(`Fragment "${fragment}" is deprecated url format. Should use url without #`);
            fragment = `\/${fragment.substr(2)}`;
        }

        this.params = params;

        // If navigation is locked and route changes, return
        // e.g. on chat route switching the recipient should work fine with navigation locked
        if (
            this._isNavigationLocked &&
            fragment && fragment.split('/')[1] !== this.currentRoute.name
        ) {
            this.trigger('navigationLocked', fragment);
            return this;
        }

        this.navigateByRoute(fragment, options, params);
        return this;
    },

    navigateToDefaultPage: function (navigateOptions) {
        this.navigate(defaultController() || 'search',
            _.defaults({ trigger: true }, navigateOptions));
    },

    /**
     * Locks site navigation
     * @public
     */
    lockNavigation: function () {
        this._isNavigationLocked = true;
    },

    /**
     * Unlocks site navigation
     * @public
     */
    unlockNavigation: function () {
        this._isNavigationLocked = false;
    },

    /**
     * @abstact
     */
    run: function () {
        throw new Error('[AbstractRouter] no implementation for run method.');
    },

});
