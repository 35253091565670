import openLoadingWindow from '@core/utils/routing/openLoadingWindow';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';
import { IN_APP_NAME } from '@core/application/constants/bootstrapParams';

import PaymentPageAlternativePaymentModel from './PaymentPageAlternativePaymentModel';
import setOrderIdAfterExternalPay from '@phoenix/payment/common/utils/setOrderIdAfterExternalPay';

const PaymentPageCCBillPaymentModel = PaymentPageAlternativePaymentModel.extend({
    FORCE_REDIRECT: 0,

    /**
     * @override
     * @param {object} data
     * @param {string} data.url
     * @param {string} data.orderId
     */
    __redirectScenario(data) {
        this.trigger('pay:redirect', data);

        const { url, orderId } = data;

        if (orderId) {
            setOrderIdAfterExternalPay({ orderId, via: this.get('via') });
        }

        // Dont open new tab for inApp browsers
        this.tab = getBootstrapParam(IN_APP_NAME) === InAppBrowserName.NORMAL_BROWSER ? openLoadingWindow() : null;

        if (this.tab) {
            this.tab.location = url;
        } else {
            window.app.gotoUrl(url);
        }
    },
});

export default PaymentPageCCBillPaymentModel;
