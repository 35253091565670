import {getClientInstance} from '@core/graphql/client';
import PAYMENT_PAGE_VISITS_QUERY from '@phoenix/payment/pages/graphql/queries/paymentPageVisits.gql';

const updatePaymentPageVisitsInCache = async () => {
  const client = getClientInstance();

  const {data: paymentVisitsData} = await client.query({
    query: PAYMENT_PAGE_VISITS_QUERY,
  });

  const paymentPageVisits =
    paymentVisitsData.payment.paymentPageVisits || 0;

  client.writeQuery({
    query: PAYMENT_PAGE_VISITS_QUERY,
    data: {
      payment: {
        paymentPageVisits: paymentPageVisits + 1,
        __typename: 'Payment',
      },
    },
  });
};

export default updatePaymentPageVisitsInCache;