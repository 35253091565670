import {getClientInstance} from '@core/graphql/client';
import DEFAULT_ROUTE_QUERY from '@core/utils/routing/graphql/queries/defaultRoute.gql';

/**
 * @deprecated
 * @return {?string}
 */
export default () =>
  getClientInstance().readQuery({
    query: DEFAULT_ROUTE_QUERY,
  }).site?.defaultRoute?.replace(/^\/(?=.)/, ''); // We need leading slash for '/', but not for other routes
