import Validator from 'app/mobSite/components/application/Validator.js';

var CardValidator = Validator.extend({
    defaults: {
        message: '',
        acceptedCardLengthsByBins: {},
    },

    _validateValue: function (cardNumber) {
        return cardNumber && this._checkCardLength(cardNumber) &&
            this._checkMoonAlgorithm(cardNumber) && this._checkFirstDigit(cardNumber);
    },

    _checkMoonAlgorithm: function (cardNumber) {
        var cardRevert = cardNumber.toString().split('').reverse();
        var sum = _.reduce(cardRevert, function (result, value, index) {
            var val = parseInt(value);
            if (index % 2 !== 0) {
                val = val * 2;
                if (val > 9) {
                    val -= 9;
                }
            }
            return result + val;
        }, 0);
        return !(sum % 10);
    },

    _checkFirstDigit: function (cardNumber) {
        return Number(cardNumber.substring(0, 1)) !== 0;
    },

    _checkCardLength: function (cardNumber) {
        var acceptedCardLengths = this.options.acceptedCardLengthsByBins[this.__getCardBin(cardNumber)];
        return _.isEmpty(acceptedCardLengths) || _.includes(acceptedCardLengths, cardNumber.length);
    },

    __getCardBin: function (cardNumber) {
        return cardNumber.substring(0, 6);
    },

});
export default CardValidator;