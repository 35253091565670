/**
 * Merchant validation model. Used to get merchat session.
 * @see https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778021-onvalidatemerchant
 */
const PaymentPageApplePayMerchantValidationModel = BaseModel.extend({
    defaults: {
        validationURL: '',
    },

    url: function() {
        return `/pay/appleSession?url=${this.get('validationURL')}`;
    },
});

export default PaymentPageApplePayMerchantValidationModel;
