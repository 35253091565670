import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import PaymentPagePaymentBaseModel from 'components/paymentPage/models/PaymentPagePaymentBaseModel';
import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import PopupType from '@phoenix/remarketing/offerPopup/constants/PopupType';
import { REMARKETING_POPUP } from '@phoenix/remarketing/offerPopup/constants/remarketingViaList';
import URI from '@core/utils/url';

/**
 * @class RemarketingPopupAlternativePayModel
 * @extends PaymentPagePaymentBaseModel
 */
export default PaymentPagePaymentBaseModel.extend({
    urlRoot: '/pay/alternative/isAjaxMode/true',

    url: function () {
        return URI(this.urlRoot)
          .addSearch({
              processor: this._getMethod(),
              prevVia: this.get('prevVia'),
              via: this.get('via'),
              popupType: this.get('popupType'),
          }).toString();
    },

    defaults: {
        /**
         * One of sepa method names
         * @type {null|string}
         */
        method: null,
        /**
         * @type {string}
         */
        prevVia: REMARKETING_POPUP,
        /**
         * @type {string}
         */
        via: 'membership',
        /**
         * @type {string}
         */
        popupType: PopupType.REMARKETING_POPUP,
        /**
         * @type {string}
         */
        scenario: PaymentScenario.ONECLICK,
    },

    data: function () {
        const data = omitBy(this.toJSON(), isNil);

        if (isEmpty(data.selectedAdditionalPackages)) {
            delete data.selectedAdditionalPackages;
        }

        return {
            [this.getFormName()]: data,
            processor: this._getMethod(),
        };
    },
});
