import PAYMENT_METHODS, {WALLET_METHODS} from "@core/payment/common/constants/paymentMethods";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import { getCookie } from '@core/utils/cookie';
import {
    APPLE_PAY_TEST_ENVIRONMENT,
    GOOGLE_PAY_TEST_ENVIRONMENT
} from '@core/payment/common/constants/walletVisualTestCookies';

/**
 * Keys for visual wallet buttons test
 * @type {Object}
 */
const TEST_WALLET_KEYS = {
    defaultButtonText: 'button.pay_with_card',
    prePayButtonText: 'button.continue_with_card'
};

/**
 * Get title settings for wallet pay
 * @param paymentMethodButtonText
 * @param {array} allowedWalletMethods
 * @returns {{}}
 */
const getWalletPayButtonText = function (paymentMethodButtonText, allowedWalletMethods) {
  const enabledWalletMethods = Boolean(allowedWalletMethods && allowedWalletMethods.length);

  // Return keys for pay buttons for visual wallet buttons test
  if ((getCookie(APPLE_PAY_TEST_ENVIRONMENT) || getCookie(GOOGLE_PAY_TEST_ENVIRONMENT)) && enabledWalletMethods) {
    return TEST_WALLET_KEYS;
  }

  // Need check isArray, because rest api returns empty array instead of Null
  const withWalletPayButtonText = !isArray(paymentMethodButtonText)
    && isObject(paymentMethodButtonText)
    && enabledWalletMethods;

  if (!withWalletPayButtonText) {
    return null;
  }

  let method = '';

  if (allowedWalletMethods.includes(PAYMENT_METHODS.APPLE_PAY)) {
    method = PAYMENT_METHODS.APPLE_PAY;
  } else if (allowedWalletMethods.includes(PAYMENT_METHODS.GOOGLE_PAY)) {
    method = PAYMENT_METHODS.GOOGLE_PAY;
  } else if (allowedWalletMethods.includes(PAYMENT_METHODS.PAY_PAL_V2)) {
    method = PAYMENT_METHODS.PAY_PAL_V2;
  } else if (allowedWalletMethods.includes(PAYMENT_METHODS.PAY_PAL)) {
    method = PAYMENT_METHODS.PAY_PAL;
  }

  return paymentMethodButtonText[method];
};

/**
 * Return title settings {defaultButtonText , prePayButtonText} @see PaymentPageModel (mob/web)
 * @param {string|PayButtonText} payButtonText
 * @param {array?} allowedWalletMethods
 * @returns {Object}
 */
const getPayButtonTextSettings = (payButtonText, allowedWalletMethods) => {

  if (!isObject(payButtonText)) {
    return {};
  }

  const walletPayButtonText = getWalletPayButtonText(payButtonText.paymentMethodButtonText, allowedWalletMethods);

  return walletPayButtonText || payButtonText;
};

export default getPayButtonTextSettings;