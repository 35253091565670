import Validator from 'components/application/Validator';

var NumberValidator = Validator.extend({
    defaults: {
        message: '',

        /**
         * @var {string} key for tracking to define message
         */
        key: ''
    },

    _validateValue: function(value) {
        return String(value).match(/^\d+$/);
    }
});
export default NumberValidator;
