import findKey from 'lodash/findKey';

import {
    VERIFICATION_PACKAGE_DESCRIPTION,
    DESCRIPTION_SUFFIX,
    DESCRIPTION_WITH_BILLING_POLICY_SUFFIX
} from '@core/payment/widgets/packageDescription/constants/footerRuleSuffixes';

const getSelectedPackageDescriptionRule = ({ footerParts, packageId }) => {
    if (!packageId || !footerParts) {
        return null;
    }

    const key = findKey(
        footerParts,
        (data, key) => key.includes(DESCRIPTION_SUFFIX) &&
            data.footerPackageData?.packageList.includes(Number(packageId))
    );

    if (!key) {
        return null;
    }

    return {
        key,
        isWithAgeVerification: key.includes(VERIFICATION_PACKAGE_DESCRIPTION),
        isWithBillingPolicy: key.includes(DESCRIPTION_WITH_BILLING_POLICY_SUFFIX),
        ...footerParts[key],
    };
};

export default getSelectedPackageDescriptionRule;
