import map from 'lodash/map';
import locale from 'components/cache/locale';

import dayjs from '@core/date/utils/dayjs';

/**
 * @param {String} id
 * @param {Boolean} fromPromocode
 * @return {string}
 */
function userLink(id, fromPromocode) {
  return (
    '/user/view/id/' +
    id +
    (fromPromocode ? '/fromPromocode/' + fromPromocode : '')
  );
}

/**
 * @param {String} id
 * @return {string}
 */
function chatLink(id) {
  return '/chat/with/' + id;
}

/**
 * @param {String} id
 * @param {Boolean} fromPromocode
 * @return {string}
 */
function userMediaLink(id, fromPromocode) {
  return (
    '/search/userMedia/id/' +
    id +
    (fromPromocode ? '/fromPromocode/' + fromPromocode : '')
  );
}

/**
 * Check device OS
 * @returns {boolean} true if IOS devise, false otherwise
 */
function isIosMobileDevice() {
  return Boolean(window.navigator.userAgent.match(/iPhone|iPad|iPod/i));
}

/**
 * @param {String} url
 * @return {*}
 */
function secureReproxy(url) {
  if (typeof url === 'string' || url instanceof String) {
    return '/site/proxy?url=' + encodeURIComponent(url);
  }
  return false;
}

/**
 * Generate string value like "5 hours ago"
 *
 * from 0 to 1 hour   - "n minutes ago"
 * from 1 to 24 hours - "n hours ago"
 * from 24 hour       - "n days ago"
 *
 * @param {String} time
 * @returns {*}
 */
function getTimeAgoString(time) {
  if (typeof dayjs === 'undefined') {
    return;
  }

  return dayjs(parseInt(time))
    .lang(locale())
    .fromNow();
}

/**
 * Check if url is absolute or relative
 * @param {string} url
 * @returns {boolean}
 */
function isAbsoluteUrl(url) {
  return /^https?:\/\//i.test(url);
}

/**
 * Check is string is html markup
 * @param {string} str
 * @returns {boolean}
 */
function isHTML(str) {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

/**
 * Skip service attribute change on model and call callback if there is other changes.
 *
 * @see LazyLoadModelBehavior.js
 * @see LoadOnDemandBehavior.js
 * @return {Function} Wrapper for callback
 */
function skipChangeServiceAttribute(callback) {
  return function(model) {
    const skipAttributeList = [
      'loading',
      'ready',
      'saveStateOnly',
      'isRequestChangeState',
    ];
    if (!_.isEmpty(_.omit(model.changedAttributes(), skipAttributeList))) {
      return callback.apply(this, arguments);
    }
  };
}

function getFileExtension(path) {
  var fileName = path.split(/(\\|\/)/g).pop(),
    lastIndex = fileName.lastIndexOf('.');

  if (lastIndex < 1) {
    return '';
  }

  return fileName.substr(lastIndex + 1).toLowerCase();
}

/**
 * converts string from html to DOM fragment
 * @param {string} html
 * @returns {NodeListOf<ChildNode>}
 * @private
 */
function htmlStringToElements(html) {
  const template = document.createElement('template');
  template.innerHTML = html;

  return template.content.childNodes;
}

/**
 * Check if specified css feature is supported by the browser
 * @param property
 * @param value
 * @param isPrefixForValue
 * @param isNoPrefixes
 */
function isCssFeatureSupporting(
  property,
  value,
  isPrefixForValue,
  isNoPrefixes,
) {
  if (typeof CSS === 'undefined') {
    return false;
  }

  const prefixes = ['-webkit-', '-moz-', '-ms-', '-o-'];
  var result = '(' + property + ': ' + value + ')';

  if (!isNoPrefixes) {
    prefixes.forEach(function(prefix) {
      if (isPrefixForValue) {
        result = result + ' or (' + property + ': ' + prefix + value + ')';
        return;
      }
      result = result + ' or (' + prefix + property + ': ' + value + ')';
    });
  }

  return CSS.supports(result);
}

/**
 * IBAN check digit calculate
 * https://www.ibantest.com/en/how-is-the-iban-check-digit-calculated
 * @param {string} country
 * @param {string} bankCode
 * @param {string} accountNumber
 * @return {number}
 */
function getIbanDigit(country, bankCode, accountNumber) {
  const countryAsNumber = map(country, (char) => char.charCodeAt() - 55).join(
    '',
  );

  // Since the iban is greater than the maximum integer, a conversion is necessary
  let block;
  let iban = `${bankCode + accountNumber + countryAsNumber}00`;

  while (iban.length > 2) {
    block = iban.slice(0, 9);
    iban = (parseInt(block, 10) % 97) + iban.slice(block.length);
  }

  return 98 - (iban % 97);
}

export {
  userLink,
  userMediaLink,
  chatLink,
  isIosMobileDevice,
  getTimeAgoString,
  secureReproxy,
  isAbsoluteUrl,
  isHTML,
  getFileExtension,
  htmlStringToElements,
  isCssFeatureSupporting,
  getIbanDigit,
  skipChangeServiceAttribute,
};
