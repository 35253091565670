import URI from '@core/utils/url';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import ROUTES from '@core/application/constants/routes';
import cachePurchasedPackageData from '@core/payment/utils/cachePurchasedPackageData';
import { DECLINE_VIA } from '@core/payment/common/constants/declineVia';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';
import getDeclineScreenTemplate from '@core/payment/widgets/failForm/utils/getDeclineScreenTemplate';
import isPaySuccessUrl from '@core/utils/url/isPaySuccessUrl';
import isPayUrl from '@core/utils/url/isPayUrl';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import { ENABLE_REACT_PAY } from '@core/application/constants/bootstrapParams';
import activateFeaturesAfterSuccessByVia from '@core/payment/payProcess/paymentFlow/methods/activateFeaturesAfterSuccessByVia';


import invalidateSearchLivecamAfterSuccess
from '@phoenix/payment/payProcess/paymentFlow/methods/invalidateSearchLivecamAfterSuccess';
import { DESCRIPTION_LIMIT_VIA } from '@phoenix/ai/constants/via';
import aiAssistantStorage from '@phoenix/ai/utils/aiAssistantStorage';
import setOrderIdAfterExternalPay from '@phoenix/payment/common/utils/setOrderIdAfterExternalPay';
import updateCachesAfterPayment from '@phoenix/payment/payProcess/utils/updateCachesAfterPayment';

import { isAbsoluteUrl } from 'app/components/application/helpers';
import PaymentPageExternalListenerModel from 'app/components/paymentPageExternal/models/PaymentPageExternalListenerModel';
import PaymentPageExternalPopupView from 'app/components/paymentPageExternal/views/PaymentPageExternalPopupView';
import { isHTML } from 'components/application/helpers';
import clearUrl from 'components/application/clearUrl';

/**
 * @class PaymentPagePaymentBaseModel
 * @see BaseModel
 */
export default BaseModel.extend({
    /**
     * React PP hotfix.
     * Prevent backbone response meta redirect
     * @see BaseSync.js
     */
    SKIP_RESPONSE_META_ERROR_REDIRECT: false,

    /**
     * React PP hotfix.
     * If truthy success order id is handled by paymentFlow.
     */
    REACT_3DS_PAYMENT: false,

    /**
     * React PP external payment flow
     * Used for prevent backbone external redirects
     */
    REACT_REDIRECT_PAYMENT: false,

    /**
     * Allows you to turn off redirection after a response from the server for external payment
     * @todo In the second part of the iteration, remove this constant, and for all cases,
     *      for all alternative methods, give this parameter as 0 at this stage, a redirect
     *      without 304 code only works for sofort
     * @protected
     * @const {number}
     */
    FORCE_REDIRECT: 1,

    THREE_D_SECURE_URL: '/pay/threeDSecureIframe',

    FORM_NAME_SUFFIX: 'PaymentForm',

    /**
     * @public
     * @override
     */
    initialize: function () {
        this.on('sync', this.__onSync);
        this.on('error', this.__onError);
    },

    /**
     * @protected
     * @override
     */
    beforeSyncSuccess: function ({ data, model }) {
        /**
         * Cache the selected package when the user navigates to external payment site
         * and restore the selected package if the user returns.
         * @see cachePurchasedPackageData.js
         * @public
         */
        cachePurchasedPackageData({ data, model });
    },

    /**
     * @public
     * @return {boolean}
     */
    isExternalPayment: function () {
        if (this.REACT_3DS_PAYMENT) {
            return false;
        }

        return !!(this.get('iframeUrl') || this.get('acsUrl') || this.get('url') && this.FORCE_REDIRECT);
    },

    /**
     * @public
     * @return {boolean}
     */
    is3DSecurePayment: function () {
        return !(this.get('iframeUrl') || this.get('url'));
    },

    /**
     * @puiblic
     * @return {string}
     */
    getExternalPaymentUrl: function () {
        const externalUrl = this.get('iframeUrl') || this.get('url') || this.THREE_D_SECURE_URL;

        if (isAbsoluteUrl(externalUrl)) {
            return externalUrl;
        }

        return this.getUrlPrefix() + externalUrl;
    },

    /**
     * @public
     * @return {boolean}
     */
    isAltOneClick: function () {
        return this.isAlt() && this.isOneClick();
    },

    /**
     * @public
     * @return {boolean}
     */
    isAlt: function () {
        return this.get('method') !== PAYMENT_METHODS.CARD;
    },

    /**
     * @public
     * @return {boolean}
     */
    isOneClick: function () {
        return this.getScenario() === PaymentScenario.ONECLICK;
    },

    /**
     * @public
     */
    pay: function () {
        console.error('Attempt to pay with non valid method');
    },

    /**
     * @return {string}
     * @private
     */
    getFormName: function () {
        return _.upperFirst(this._getMethod()) + this.FORM_NAME_SUFFIX;
    },

    /**
     * Navigate to next page
     * @public
     */
    navigateToNextPage: function () {
        let url = this.get('redirectUrl');

        if (_.isArray(url) && url.length === 1) {
            url = url[0];
        }

        if (_.isObject(url)) {
            const urlObject = URI(url[0]);
            delete url[0];
            urlObject.search(url);
            url = urlObject.toString();
        }

        if (isPayUrl(url)) {
            /**
             * This stub needs for react pp success page, because success page needs orderId in url params
             * to show package
             * @see PayReactCommonRoute - Reset all prev orders att head of script
             *      PaymentPageSuccess - initPaymentSuccessOrder get oder id from url
             * @todo How to fix correctly? remove BB rm popup and BB PP and use react versions where link to
             *       next page are made on frontend
             */
            if (isPaySuccessUrl(url) && getBootstrapParam(ENABLE_REACT_PAY)) {
                url = `${url}&orderId=${this.get('orderId')}`;
            }

            window.app.goToPP(url);
            return;
        }

        window.app.router.navigate(clearUrl(url), {
            trigger: true
        });

        updateCachesAfterPayment();
    },

    /**
     * @protected
     */
    _getMethod: function () {
        return this.get('method');
    },

    /**
     * @public
     */
    getBannedBins() {
        return null;
    },

    /**
     * @public
     */
    setBinEntity() {},

    /**
     * @public
     */
    acceptedCardLengths() {},

    /**
     * @public
     */
    acceptedCvvLength() {},

    /**
     * @public
     */
    invalidateCardBin() {},

    /**
     * @public
     */
    nonLocalCardBin() {},

    /**
     * Scenario when the external payment takes place
     * @protected
     * @abstract
     */
    _payExternalSuccessScenario: function () {},

    /**
     * Scenario when payment does not pass or an error occurs
     * @protected
     */
    _payFailScenario: function () {},

    /**
     * @private
     * @param {Object} model
     * @param {Object} metaDescriptionOrJqXHR It may be jqXHR or
     *                 data.meta.description. @see BaseModel::sync
     *                 success() and error()
     * @param {Object} options Options passed to model.save().
     */
    __onError: function (model, metaDescriptionOrJqXHR, options) {
        /**
         * Special case. Server can return plain html markup.
         * It's used in IDeal alt-method to call iframe and attach
         * there plain html inside
         * @see PaymentPageIdealFormView
         */
        if (isHTML(metaDescriptionOrJqXHR.responseText)) { // Hope it's jqXHR
            this._startExternalPayment(metaDescriptionOrJqXHR.responseText);
            return;
        }

        let declineData = {};

        if (options && options.xhr && options.xhr.responseText) {
            const json = JSON.parse(options.xhr.responseText);

            declineData = json.data;
        } else {
            declineData = metaDescriptionOrJqXHR; // Hope it's metaDescription
        }

        this._errorScenario(declineData);
    },

    /**
     * @protected
     * @param data {Object} Error response data
     */
    _errorScenario: function (data) {
        const redirectUrl = URI(data.redirectUrl || this.getDeclineUrl());

        if (!redirectUrl.hasSearch('returnPath')) {
            redirectUrl.addSearch('returnPath', ROUTES.SEARCH);
        }

        this.set('redirectUrl', redirectUrl.toString());
        this._payFailScenario();
        this.trigger('pay:fail', {
            ...data,
            method: this.get('method'),
            declineScreenTemplate: getDeclineScreenTemplate(data.declineScreenTemplate)
        });
    },

    /**
     * @private
     * @param {BaseModel} model
     * @param {Object} data
     */
    __onSync: function (model, data) {
        if (this.REACT_REDIRECT_PAYMENT && data.orderId && (data.url || data.redirectUrl)) {
            this.trigger('pay:reactRedirectPayment', data);

            return;
        }

        if (this.get('3dsRedirectUrl')) {
            /**
             * if 3dsRedirectUrl parameter (link) comes, you need to redirect the user to it
             * so we transfer it to redirectUrl so that the redirect will work in the view
             */
            data.redirectUrl = this.get('3dsRedirectUrl');
        } else if (this.isExternalPayment()) {
            this.trigger('pay:startExternalPayment', data);

            this._startExternalPayment();

            return;
        }

        if (data.url && data.orderId && !this.FORCE_REDIRECT) {
            this.__redirectScenario(data);

            return;
        }

        this.__completeScenario(data);

        if (!data.status) {
            this._errorScenario(data);
            return;
        }

        this.__successScenario(data);
    },

    /**
     * Allows you to save the parameters and send the user to the desired external processor
     * (relevant only if this.FORCE_REDIRECT is 0)
     * @param {object} data
     * @private
     */
    __redirectScenario(data) {
        this.trigger('pay:redirect', data);

        /**
         * In order to overwrite the order ID field when paying again on the decline page on payment page
         * without redirect from the popup
         */
        if (data.orderId) {
            setOrderIdAfterExternalPay({ orderId: data.orderId, via: this.get('via') });
        }

        window.app.gotoUrl(data.url);
    },

    /**
     * @param data {Object} Payment success data.
     * @private
     */
    __successScenario(data) {
        if (
            // Order id is handled by react payment flow
            !this.REACT_3DS_PAYMENT &&
            // We don`t have success page on iframe PP, so we don`t set orderId
            !this.get('replaceRedirectToPostMessage')
        ) {
            this.__setSuccessOrderId(data.orderId);
        }

        this.trigger('pay:success', data);

        invalidateSearchLivecamAfterSuccess({ paymentResult: {
            status: true,
        }});
    },

    __completeScenario(data) {
        data.model = this;
        this.trigger('pay:complete', data);
    },

    /**
     * @param orderId {string}
     * @private
     */
    __setSuccessOrderId(orderId) {
        orderId = orderId || this.get('orderId');

        if (orderId) {
            if (this.get('prevVia') === DESCRIPTION_LIMIT_VIA) {
                aiAssistantStorage.setShow(true);
            }

            activateFeaturesAfterSuccessByVia();
            PaymentPageSuccessOrders.add(orderId, this.get('via'));
        }
    },

    /**
     * External success handler
     * @param {object} data  Payment success data.
     * @private
     */
    __onExternalSuccess: function (data) {
        this._payExternalSuccessScenario();
        this.__successScenario(data);
    },

    /**
     * Start external payment
     * @protected
     * @param {String} html Iframe content
     */
    _startExternalPayment: function (html) {
        const is3dSecure = this.is3DSecurePayment();
        const externalListenerModel = this.getExternalPayModelWithListeners();

        externalListenerModel.set({
            orderId: this.get('orderId'),
            action: this.get('via'),
        });

        window.app.popup.open(PaymentPageExternalPopupView, {
            title: yiiT.t('paymentPage', 'title.payment_page'),
            trackingName: 'pending3Ds',
            // Temporary solution. After migrate on react set correct popup loading tracking!
            autoLoadTracking: true,
            url: html ? null : this.getExternalPaymentUrl(),
            html,
            is3dSecure,
            model: externalListenerModel,
            showCloseButton: false,
            failVia: DECLINE_VIA
        });

        this.unset('iframeUrl');
        this.unset('acsUrl');
    },

    /**
     * @public
     * @return {PaymentPageExternalListenerModel}
     */
    getExternalPayModelWithListeners() {
        if (this.externalModel) {
            this.externalModel.startListenPendingInteraction();
            return this.externalModel;
        }

        this.externalModel = new PaymentPageExternalListenerModel();

        this.listenTo(this.externalModel, 'success', this.__onExternalSuccess);
        this.listenTo(this.externalModel, 'complete', this.__completeScenario);
        this.listenTo(this.externalModel, 'fail', this._errorScenario);
        this.listenTo(this.externalModel, 'cancel', this.__cancelScenario);

        return this.externalModel;
    },

    /**
     * @public
     */
    getDeclineUrl() {
        return URI('/pay/' + this.get('via')).setSearch({
            via: DECLINE_VIA,
        }).toString();
    },

    /**
     * @public
     */
    __cancelScenario() {
        this.trigger('pay:cancel');
    }
});
