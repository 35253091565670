import Validator from 'app/mobSite/components/application/Validator.js';

var RegexpValidator = Validator.extend({
    defaults: {
        /**
         * @param {String}
         */
        message: '',

        /**
         * @param {RegExp}
         */
        regexp: null,
    },

    _validateValue: function (value) {
        return this.options.regexp.test(value);
    },
});
export default RegexpValidator;
