import PaymentPagePackageModel from './PaymentPagePackageModel';

/**
 * @class PaymentPagePackageCollection
 * @extends BaseCollection
 */
export default BaseCollection.extend({

    /**
     * Packages with extra days discounts
     * @todo Move data to server side after logic updates on backend
     * @constant
     */
    EXTRA_DAYS_PACKAGES_IDS: [1750, 1751],

    model: PaymentPagePackageModel,

    /**
     * Need to add extra days for extra packages
     * @todo Flag about extra days must arrive from backend
     * @param {Array} data
     */
    parse(data) {
        return data.map(item => {
            item = this.__normalizeData(item);

            return item;
        });
    },

    /**
     * Set is_active in packages for each method separately
     * @public
     */
    setActive: function(id, method) {
        if (!this.isPackageExist(id)) {
            return;
        }

        method = method || this.findWhere({
            id: id,
        }).get('originalMethod');

        _.each(this.where({
            originalMethod: method,
        }), model => {
            model.set('is_active', model.get('id') === id);
        });
    },

    /**
     * @param {Object} data (package)
     * @private
     */
    __normalizeData(data) {
        data.is_default = Boolean(data.is_default*1);
        data.is_active = data.is_default;

        data.price = this.__unifyPrice(data.price);

        if (data.discounted_price) {
            data.discounted_price = this.__unifyPrice(data.discounted_price);
        }

        // Replace type if alternative exists
        if (data.package.alter_type) {
            data.package.type = data.package.alter_type;
        }

        return data;
    },

    /**
     * @param {Object} price
     * @pritvate
     */
    __unifyPrice(price) {
        if (price.per_period) {
            return price;
        }

        if (!price.hi && !price.lo) {
            price.amount = price.amount.toString();
            price.hi = price.amount.split('.')[0];
            price.lo = price.amount.split('.')[1];
        }

        price.per_period = {
            hi: price.hi,
            lo: price.lo,
        };

        return price;
    },


    /**
     * @public
     * @param id {string}
     * @return {boolean}
     */
    isPackageExist: function (id) {
        return !!this.findWhere({id: id});
    }
});
