/**
 * Remove domain from url
 * @deprecated Remove after move payment page to react
 * @public
 * @return {string}
 * @param {string} url
 */
const clearUrl = url => {
    if (!url) {
        return '';
    }

    return url.replace(new RegExp(`^${window.location.origin}`), '');
};

export default clearUrl;
