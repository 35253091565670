import PaymentScenario from '@core/payment/common/constants/paymentScenario';

import PaymentPageUserFieldsPaymentModel from './PaymentPageUserFieldsPaymentModel';

/**
 * @class PaymentPageIdealPaymentModel
 * @extends PaymentPageUserFieldsPaymentModel
 * @author Pavel Davydov <pavel.davydov@together.com>
 */
export default class PaymentPageIdealPaymentModel extends PaymentPageUserFieldsPaymentModel.extend({

    data: function() {
        const data = PaymentPageUserFieldsPaymentModel.prototype.data.call(this, arguments);
        const form = data[this.getFormName()];

        if (form.sepaMandateFields && form.scenario === PaymentScenario.ONECLICK) {
            form.isMaskedForm = true;
        }
        /**
         * Don't need in request
         */
        delete form.sepaMandateFields;

        if (!form.bic) {
            delete form.bic;
        }

        return data;
    },

}) {

};