import { localStorage, sessionStorage } from '@core/utils/storage';

/**
 * Temporary workaround for old code to work with updated storage wrapper without any changes.
 * @class Storage
 * @param {String} [type]
 */
const Storage = (type = 'localStorage') => {
    const storage = type === 'sessionStorage' ? sessionStorage : localStorage;
    // alias for compatibility
    storage.clearStorage = storage.clear;
    return storage;
};

export default Storage;
