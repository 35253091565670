/**
 * Remember processing fee data.
 * @param {Object} model
 * @param {Object} data
 * @param {Function} next
 */
const PaymentPageProcessingFeeBanner = function (model, data, next) {
    model.set('processingFeeBannerData', data);
    next();
};

export default PaymentPageProcessingFeeBanner;
