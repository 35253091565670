/**
 * check bin validation, invalidate bin in model if it's invalid
 * @param model {model}
 * @param data {object}
 * @param next {function}
 */
const PaymentPageBinValidation = function (model, data, next) {
    const bin = model.get('card_number').substring(0, 6);
    if (data.isBanned) {
        model.invalidateCardBin(bin);
    }
    if (data.isNonLocalProcessingBanned) {
        model.nonLocalCardBin(bin);
    }

    next();
};

export default PaymentPageBinValidation;
