/**
 * @param options
 * @constructor
 */
var Validator = function(options) {
    this.options = {};

    _.each(this.defaults, _.bind(function(defaultValue, key) {
        this.options[key] = (options[key]) ? (options[key]) : defaultValue;
    }, this));

    this.initialize.apply(this, arguments);
};

Validator.prototype = {
    defaults: {},

    initialize: function() {},

    /**
     * @param model
     * @param validateAttributes
     * @param attributes
     */
    validate: function(model, validateAttributes, attributes) {
        validateAttributes.forEach(function(attribute) {
            this._validateAttribute(model, attribute, attributes);
        }, this);
    },

    /**
     * @param model
     * @param attribute
     * @param attributes
     * @private
     */
    _validateAttribute: function(model, attribute, attributes) {
        var value = attributes[attribute];

        if (!this._validateValue(value)) {
            model.addError(attribute, this.options);
        }
    },

    _validateValue: function(value) {}
};

Validator.extend = Backbone.Model.extend;
export default Validator;
