import Validator from 'components/application/Validator';
import LocalStorage from 'components/storage/storage';
import iban from 'iban';

/**
 * International Bank Account Number validator
 * It's just an adaptation of plugin (https://github.com/arhs/iban.js/) into internal validation system
 * @extends Validator
 * @author Davydov Pavel <pavel.davydov@together.com>
 */
export default Validator.extend({

    defaults: {
        message: ''
    },

    VALIDATE_SKIP_KEY: 'IbanValidation::skip',

    /**
     * @overriden
     */
    initialize: function() {
        this.storage = new LocalStorage();
    },

    /**
     * @overriden
     */
    _validateValue: function (value) {
        /**
         * @todo This is needed for testing purposes
         */
        if (this.storage.getItem(this.VALIDATE_SKIP_KEY)) {
            return true;
        }

        return iban.isValid(value);
    }

});
