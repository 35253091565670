import random from 'lodash/random';

import { getClientInstance } from '@core/graphql/client';
import getTypeNameItems from '@core/graphql/utils/getTypeNameItems';
import PRESET from '@core/user/photo/constants/PhotoPreset';

import RANDOM_USERS_QUERY from '@phoenix/search/list/graphql/queries/randomUsers.gql';
import PHOTO_DATA_QUERY from '@phoenix/user/photo/graphql/queries/photoData.gql';
import createPhotoUrl from '@phoenix/user/photo/utils/createPhotoUrl';

// Need to show the same user that was on previous step(-s) on the payment page
let alreadyViewedUser = null;

/**
 * Adapt user data from GraphQL data to Backbone model data
 * @param {Object} user
 * @return {Object}
 */
export const normalizeUserData = user => {
    user = {
        ...user,
        ...user.profile,
    };

    user.geo = user.profile?.location;
    user.screenName = user.profile.login;
    user.gender = user.profile.gender;

    user.statuses = {
        onlineStatus: user.statuses.isOnline,
        recentlyOnlineStatus: user.statuses.isRecentlyOnline,
        newStatus: user.statuses.isNew,
    };

    user.photos = {
        ...user.photos,
        ...user.photos.primaryPhoto,
        url: user.photos.primaryPhoto?.cachedUrl,
        attributes: {
            level: user.photos.primaryPhoto?.level,
        },
    };

    return user;
};

/**
 * @param {String|Undefined} viaProfileId
 * @param {Boolean} isUserMotivation
 * @param {BaseModel} userFromModel
 * @param {Query} query
 * @param {BaseModel} PaymentPageUserModel
 */
const getUser = ({ viaProfileId, isUserMotivation, userFromModel, query, PaymentPageUserModel }) => {
    return new Promise(resolve => {
        if (alreadyViewedUser) {
            resolve(alreadyViewedUser);
            return;
        }

        const client = getClientInstance();

        if (viaProfileId) {
            const userData = client.readQuery({
                query,
                variables: { userId: viaProfileId }
            });

            if (userData) {
                let user = normalizeUserData(userData.user);
                user = new PaymentPageUserModel(user);
                alreadyViewedUser = user;
                resolve(user);
                return;
            }
        } else {
            // Get all users in cache and filter them by having cached url
            const users = getTypeNameItems({
                typename: 'UserData',
                query,
                setVariables: item => ({ userId: item.id })
            }).filter(item => {
                if (item.user?.profile?.statuses?.isAdmin) {
                    return false;
                }

                return item.user?.profile?.photos?.primaryPhoto?.cachedUrl;
            });

            const { length } = users;

            if (length) {
                const userData = users[random(length - 1)];

                if (userData) {
                    let user = normalizeUserData(userData.user);
                    user = new PaymentPageUserModel(user);
                    alreadyViewedUser = user;
                    resolve(user);
                    return;
                }
            }
        }

        if ( userFromModel && (!isUserMotivation || userFromModel.id === viaProfileId)) {
            alreadyViewedUser = userFromModel;
            resolve(userFromModel);
            return;
        }

        client
            .query({
                query: RANDOM_USERS_QUERY,
                variables: { count: 1 },
                fetchPolicy: 'network-only',
            })
            .then(({ data }) => {
                // Set null, to indicate thant search have no user's.
                let user = (data?.search?.randomUsers?.resultingUsers || [])[0] || null;

                if (user) {
                    user = normalizeUserData(user);

                    client.query({ query: PHOTO_DATA_QUERY }).then(data => {
                        user.photos.url = window.IS_INTEGRATION_TEST_ENVIRONMENT ?
                            '/photo/show/id/*?' :
                            createPhotoUrl({
                                ...user.photos,
                                ...data.site,
                                preset: PRESET.AVATAR,
                            });
                        user = new PaymentPageUserModel(user);
                        alreadyViewedUser = user;
                        resolve(user);
                    });
                } else {
                    resolve(user);
                }
            });
    });
};

export default getUser;
