import css from '../styles/PaymentPageExternal.css';

/**
 * @class PaymentPageExternalBasePopupView
 * @see BaseView
 */
export default BaseView.extend({

    className: css.external,

    events: {
        'click ._paymentPageExternalCloseFrame': 'onCloseFrameClick',
        'click ._paymentPageExternalCloseConfirm': 'onCloseConfirmClick',
        'click ._popupClose': 'closePopup'
    },

    template: require('../templates/PaymentPageExternalLayout.pug'),

    render() {
        this.renderTemplate();
        this.region.ready();
        this._renderForm();

        return this;
    },

    /**
     * Need to be realized
     * @protected
     */
    _renderForm() {},

    /**
     * Close 3ds iframe and open confirm form
     * @public
     */
    onCloseFrameClick() {
        window.app.popup.setTitle(yiiT.t('paymentPage', 'text.please_wait'));
        this.toggleContent();
    },

    /**
     * Close confirm form and open 3ds iframe
     * @public
     */
    onCloseConfirmClick() {
        window.app.popup.setTitle(yiiT.t('paymentPage', 'title.payment_page'));
        this.toggleContent();
    },

    /**
     * Switch between contents inside popup
     * @public
     */
    toggleContent() {
        this.$('#paymentPageExternalCloseConfirm').toggleClass(css.hidden);
        this.$('#paymentPageExternalFrame').toggleClass(css.hidden);
    },

    /**
     * @public
     */
    closePopup() {
        window.app.popup.closePopup();
    }
});
