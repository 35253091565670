/**
 * List of all entry-points on frontend-service
 * @type {Object.<String.String>}
 */
const ENTRY_POINTS = {
  /**
   * Entry point for sites witch main purpose is to sell paid subscriptions
   * to the site and some special features
   */
  DATING: 'dating',

  /**
   * Entry point for sites witch main purpose is to sell tickets
   * on real or offline dating events
   */
  SPEED_DATING: 'speedDating',
};

export default ENTRY_POINTS;
