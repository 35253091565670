import getHistory from '@core/application/utils/getHistory';

var $ = require('jquery');
import _ from 'underscore';
import logger from '@core/logger';
import { getRelativeUrl } from '@core/utils/url/getUrl';
import { getClientInstance } from '@core/graphql/client';
import CSRF_TOKEN_QUERY from '@core/graphql/graphql/queries/csrfToken.gql';
import getUserAgentParser from '@core/utils/getUserAgentParser';
import { setFailed } from '@core/payment/common/utils/setPaymentProcessingStatus';

const writeCsrfToken = csrfToken => {
    getClientInstance().writeQuery({
        query: CSRF_TOKEN_QUERY,
        data: {
            site: {
                csrfToken,
                __typename: 'Site',
            }
        }
    });
};

var BaseSync = {
    GET_TYPE: 'GET',
    POST_TYPE: 'POST',

    /**
     * Add this API version to all requests of this model
     */
    _apiVersion: 1,

    UPLOAD_PROGRESS_NAME: 'upload_progress',

    DOWNLOAD_PROGRESS_NAME: 'download_progress',

    /**
     * @returns {string} prefix of API requests with api version
     * @public
     */
    getUrlPrefix: function () {
        return '/api/v' + this._apiVersion;
    },

    /**
     * @param method {string} @see methodMap in backbone
     * @returns {string} url to send request
     * @private
     */
    _getUrl: function (method) {
        return this.getUrlPrefix() + (_.isFunction(this.url) ? this.url(method) : this.url);
    },

    data: function (method) {
        return {
            data: JSON.stringify(this.toJSON())
        };
    },

    /**
     * @return {Object}
     * @protected
     */
    fetchRequestData: function () {
        return {
            data: {}
        };
    },

    getData: function (method) {
        if (method === 'create' || method === 'update' || method === 'delete') {
            var output = _.isFunction(this.data) ? this.data(method) : this.data;

            const data = getClientInstance().readQuery({ query: CSRF_TOKEN_QUERY });
            const { csrfToken } = data.site || {};

            if (csrfToken) {
                if (this._isJson(output)) {
                    output = JSON.parse(output);
                    output.CSRF_TOKEN = csrfToken;
                    output = JSON.stringify(output);
                } else {
                    output.CSRF_TOKEN = csrfToken;
                }
            }
        } else {
            var output = _.isFunction(this.fetchRequestData) ? this.fetchRequestData() : this.fetchRequestData;
        }

        return output;
    },

    /**
     * @param str
     * @return {boolean}
     */
    _isJson: function (str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }

        return true;
    },

    defineType: function (options, method) { // hack for correct define method type
        if (options.type) {
            return options.type;
        } else if (method === 'create' || method === 'update' || method === 'delete') {
            return this.POST_TYPE;
        }
        return this.GET_TYPE;
    },

    beforeSyncSuccess: function () {},

    sync: function (method, model, options) {
        options = options || {};

        var params = {
            type: this.defineType(options, method),
            dataType: options.dataType || 'json'
        };

        const store = this.localStorage;
        if (store && method === 'read') {
            /* When networkOnly - true and model had localStorage,
             we destroy local storage and get data only from server. */
            if (options.networkOnly) {
                store.clear();
            }
            const response = store.find();
            if (response) {
                this.set(response, { parse: true });
                this.trigger('sync', model, response, options);
                // prevent ajax request
                params.beforeSend = () => false;
            }
        }

        if (!options.url) {
            params.url = getRelativeUrl(this._getUrl(method));
        }

        if (!options.data) {
            params.data = this.getData(method);
        }

        var error = options.error;
        options.error = function (jqXHR, textStatus, errorThrown) {
            var status = (!$.isEmptyObject(jqXHR) && jqXHR.status) ? jqXHR.status : null;

            if (status == 400 && !options.noRepeat) {
                // renewal CSRF Token
                try {
                    this.fillCsrfToken(JSON.parse(jqXHR.responseText));
                } catch (e) {
                    logger.sendError('fillCsrfToken error');
                }

                // Restore options
                delete options.xhr;
                options.error = error;
                options.success = success;

                // No repeat request on error again
                options.noRepeat = true;

                // Repeat request
                this.sync(method, model, options);
                return;
            }

            if (status == 403) {
                window.app.gotoUrl('forbidden');
            }

            if (error) error(jqXHR, textStatus, errorThrown);
        }.bind(this);

        var success = options.success,
            redirect = options.redirect;

        options.success = function (data, textStatus, jqXHR) {
            this.beforeSyncSuccess({ data, model });

            this.fillCsrfToken(data);

            /**
             * React PP hotfix.
             * Show the default error instead of the meta redirect.
             * In react PP unable to handle error correctly for server answer with meta.redirect = '/pay/result/fail'.
             * There is no /pay/result/fail route on react pp and show 404 page.
             */
            const skipResponseMetaErrorRedirect = model.SKIP_RESPONSE_META_ERROR_REDIRECT &&
              data?.meta?.redirect === '/pay/result/fail';

            if (!$.isEmptyObject(data) && !_.isEmpty(data.status)) {
                if (data.status === 'error') {
                    if (!$.isEmptyObject(data.meta)) {
                        if (data.meta.redirect && !skipResponseMetaErrorRedirect) {
                            if (redirect) {
                                var redirectStatus = redirect(data, textStatus, jqXHR);
                                if (redirectStatus) {
                                    this._safeRedirect(data.meta.redirect);
                                }
                            } else {
                                this._safeRedirect(data.meta.redirect);
                            }
                        } else if (data.meta.description) {
                            if (error) error(data.meta.description, textStatus, jqXHR);
                        } else {
                            if (error) error({});
                        }
                    } else {
                        if (error) error({});
                    }

                    if (model.SKIP_RESPONSE_META_ERROR_REDIRECT) {
                        setFailed();
                    }
                } else if (data.meta && data.meta.code && data.meta.code == '302' && data.meta.redirect) {
                    this._safeRedirect(data.meta.redirect);
                } else {
                    if (data.data) {
                        if (success) success(data.data, textStatus, jqXHR);
                    } else {
                        if (success) success(data);
                    }
                }
            } else {
                if (success) success(data, textStatus, jqXHR);
            }
        }.bind(this);

        var complete = options.complete;
        options.complete = function () {
            this.xhrs = _.without(this.xhrs, xhr);
            if (complete) complete.apply(arguments);
        }.bind(this);

        if (options.listenProgress) {
            options.xhr = () => {
                const xhr = new window.XMLHttpRequest();
                this.__addProgressListeners(xhr);
                return xhr;
            };
        }

        Object.assign(params, options);

        // no caching ajax request for IOS safari browsers (TN-152911)
        const isIos = getUserAgentParser().getOS().name === 'iOS';
        if ((params.type === this.GET_TYPE) && isIos) {
            Object.assign(params, {
                cache: false,
            });
        }

        const xhr = options.xhr = $.ajax(params);
        this.xhrs = this.xhrs || [];
        this.xhrs.push(xhr);
        model.trigger('request', model, xhr, options);
        return xhr;
    },
    /**
     * Abort all current sync requests
     * @protected
     */
    abortSync: function () {
        if (!this.xhrs) return;
        _.each(this.xhrs, function (xhr) {
            xhr.abort();
        });
        this.xhrs = [];
    },
    /**
     * Fill CSRF token from request data
     *
     * @private
     * @param {object} data request data
     * @returns {BaseSync}
     */
    fillCsrfToken: function (data) {
        if ($.isEmptyObject(data)) {
            return this;
        }
        if (!$.isEmptyObject(data.csrfToken)) {
            writeCsrfToken(data.csrfToken.value);
        }
        if (!$.isEmptyObject(data.data) && data.data.csrfToken && !$.isEmptyObject(data.data.csrfToken)) {
            writeCsrfToken(data.data.csrfToken.value);
        }
        return this;
    },

    /**
     * @private
     * @param url
     * @return {boolean}
     */
    _isApiUrl: function (url) {
        return url.indexOf(this.getUrlPrefix()) === 0;
    },

    /**
     * check that it is not api url and redirect, show error otherwise
     * @private
     */
    _safeRedirect: function (url) {
        if (!this._isApiUrl(url)) {
            window.app.gotoUrl(url);
            return;
        }

        // call function 'replace' manually for prevent change url to '/notFound'
        getHistory().replace('/notFound');
    },

    /**
     * Add listeners for upload and download progress
     * @protected
     */
    __addProgressListeners: function (xhr) {
        // Upload progress
        xhr.upload.addEventListener('progress', this.__getProgressCallback(this.UPLOAD_PROGRESS_NAME), false);
        // Download progress
        xhr.addEventListener('progress', this.__getProgressCallback(this.DOWNLOAD_PROGRESS_NAME), false);
    },


    /**
     * Create call back function for
     * @param eventName
     * @return {function(*)}
     * @private
     */
    __getProgressCallback(eventName) {
        return event => {
            if (event.lengthComputable) {
                const share = event.loaded / event.total;
                this.trigger(eventName, { event, share });
            }
        };
    },
};

export default BaseSync;
