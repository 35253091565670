import t from '@core/translations/translate';
import logger from '@core/logger';

const fieldsTranslates = {
    nameFirst: () => t('paymentPageCommDoo', 'text.nameFirst'),
    firstName: () => t('paymentPageCommDoo', 'text.nameFirst'),
    nameLast: () => t('paymentPageCommDoo', 'error.is_required_nameLast'),
    lastName: () => t('paymentPageCommDoo', 'error.is_required_nameLast'),
    iban: () => t('paymentPageCommDoo', 'text.iban'),
    street: () => t('paymentPageCommDoo', 'error.is_required_street'),
    houseNumber: () => t('paymentPageCommDoo', 'text.houseNumber'),
    postalCode: () => t('paymentPageCommDoo', 'text.postalCode'),
    city: () => t('paymentPageCommDoo', 'text.city'),
    phoneNumber: () => t('paymentPageCommDoo', 'text.phoneNumber'),
    numberHouse: () => t('paymentPageCommDoo', 'text.numberHouse'),
    accountAndBank: () => t('paymentPageCommDoo', 'text.accountAndBank'),
    accountNumber: () => t('paymentPageCommDoo', 'text.accountNumber'),
    bankCode: () => t('paymentPageCommDoo', 'text.bankCode'),
    email: () => t('paymentPage', 'title.email'),
};

/**
 * @param {string} attribute
 * @return {string|[]}
 */
const getFieldsTranslate = attribute => {
    if (fieldsTranslates[attribute]) {
        return fieldsTranslates[attribute]();
    }

    logger.sendWarning(`[getFieldsTranslate] LOST TRANSLATE: (feature: paymentPageCommDoo, key: text.${attribute})`);
    return attribute;
};

export default getFieldsTranslate;
