import isUndefined from 'lodash/isUndefined';

import STEPS from '@core/payment/paymentPending/constants/steps';
import trackPaymentPendingPageStep from '@core/tracking/trackPaymentPendingPageStep/trackPaymentPendingPageStep';

import logger from '@core/logger';


/**
 * When change focus
 */
const handleEvent = () => {
    let step;
    const state = window.document.visibilityState;
    if (state === 'visible') {
        step = STEPS.APPEARED;
    } else if (state === 'hidden') {
        step = STEPS.DISAPPEARED;
    }

    if (!isUndefined(step)) {
        trackPaymentPendingPageStep({
            orderId: currentOrderId,
            step,
        });
    }
};

/**
 * Actual order id for focus listeners
 * @type {null|string}
 */
let currentOrderId = null;

/**
 * Set order id for listeners
 * @param {string} orderId
 */
export const setOrderId = orderId => {
    currentOrderId = orderId;
};

/**
 * Set page focus listeners
 * @param {string} orderId
 */
export const setFocusListeners = orderId => {
    currentOrderId = orderId;

    if (!isUndefined(window.document.hidden)) {
        window.document.addEventListener('visibilitychange', handleEvent, false);
    } else {
        logger.sendWarning('focusListener: don\'t allowed window.document.hidden');
    }
};

/**
 * Remove focus listeners
 */
export const removeFocusListeners = () => {
    currentOrderId = null;

    window.document.removeEventListener('visibilitychange', handleEvent);
};
