/**
 * @class MethodModel
 * @see BaseModel
 */
const MethodModel = BaseModel.extend({
    defaults: {
        /**
         * Is active method flag (for activate tab of selected method)
         * @type {boolean|null}
         */
        isActive: null,

        /**
         * Method name which may not contain the original name, since used when cloning a method
         * @type {string|null}
         */
        method: null,

        /**
         * Original unique method name
         * @type {string|null}
         */
        originalMethod: null,
    },
});

export default MethodModel;
