import PaymentPageModel from 'app/mobSite/components/paymentPage/models/PaymentPageModel';

/**
 * Model for grab data of remarketing offer page
 * @class PaymentPageRemarketingOfferModel
 * @extends PaymentPageModel
 */
export default PaymentPageModel.extend({
    /**
     * @override
     * @see PaymentPageModel.urlRoot
     */
    urlRoot: '/pay/remarketingOffer/isJsonMode/true',

    /**
     * @override
     * @see PaymentPageModel.actionId
     */
    actionId: 'remarketingOffer',
});
