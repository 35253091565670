import Validator from '../Validator';

const StringWithNumberValidator = Validator.extend({

    defaults: {
        message: '',
    },

    /**
     * @override
     */
    _validateValue: function (value) {
        return /[0-9]/.test(value);
    }
});
export default StringWithNumberValidator;
