/**
 * User interface used on payment page.
 */
var PaymentPageUserModel = BaseModel.extend({
    defaults: {
        id: null,
        onlineStatus: null,
        screenName: null,
        photos: {
            url: null,
            attributes: null
        }
    }
}, {
    fromPaymentModel: function (data) {
        data.onlineStatus = this.getOnlineStatus(data.statuses);
        return new PaymentPageUserModel(data);
    },

    fromStoriesModel: function(model) {
        return new PaymentPageUserModel({
            id: model.get('user').userId,
            onlineStatus: this.getOnlineStatus(model.get('user').statuses),
            screenName: model.get('user').login,
            photos: {
                url: model.get('user').primaryPhoto.photoUrl,
                attributes: model.get('user').primaryPhoto.attributes
            }
        });
    },

    fromUserModel: function(model) {
        return new PaymentPageUserModel({
            id: model.get('user').id,
            onlineStatus: this.getOnlineStatus(model.get('user').statuses),
            screenName: model.get('user').screenName,
            photos: {
                url: model.get('user').photoUrl,
                attributes: model.get('user').photoAttributes
            }
        });
    },
    fromMessengerModel: function(model) {
        return new PaymentPageUserModel({
            id: model.get('user').id,
            onlineStatus: this.getOnlineStatus(model.get('user').statuses),
            screenName: model.get('user').login,
            photos: {
                url: model.get('user').photos.url,
                attributes: model.get('user').photos.attributes
            }
        });
    },

    fromNotificationModel: function(model) {
        return new PaymentPageUserModel({
            id: model.get('user_id'),
            screenName: model.get('screenname'),
            photos: {
                url: model.get('photo'),
                attributes: model.get('photoAttributes')
            }
        });
    },

    fromUserListWidgetModel: function(model) {
        return new PaymentPageUserModel({
            id: model.get('id'),
            onlineStatus: this.getOnlineStatus(model.get('statuses')),
            screenName: model.get('login'),
            photos: {
                url: model.get('photos').url,
                attributes: model.get('photos').attributes
            }
        });
    },

    fromHONUser: function(model) {
        var primaryPhoto = _.find(model.get('photos'), function(photo) {
            return photo.is_primary;
        });

        return new PaymentPageUserModel({
            id: model.get('id'),
            onlineStatus: this.getOnlineStatus(model.get('userStatus')),
            screenName: model.get('name'),
            photos: {
                url: primaryPhoto ? primaryPhoto.avatar : model.get('photo'),
                attributes: primaryPhoto ? primaryPhoto.attributes : {}
            }
        });
    },

    fromHONpopupUser: function (model) {
        var primaryPhoto = model.collection.findWhere({
            is_primary: 1
        });

        return new PaymentPageUserModel({
            id: model.get('id'),
            screenName: model.get('name'),
            onlineStatus: this.getOnlineStatus(model.get('statuses')),
            photos: {
                url: primaryPhoto.get('avatar'),
                attributes: {
                    level: primaryPhoto.get('level')
                }
            }
        });
    },

    /**
     * parse user statuses and return user online Status
     * @return {string}
     */
    getOnlineStatus: function (statuses) {
        var onlineStatus = '';

        _.each(statuses, function (value, status) {

            if (!value) {
                return;
            }

            switch (status) {
                case 'recentlyOnlineStatus':
                case 'recentlyOnline':
                case 'recently':
                    onlineStatus = 'recent';
                    break;
                case 'onlineStatus':
                case 'online':
                    onlineStatus = 'online';
                    break;
            }
        });

        return onlineStatus;
    }
});
export default PaymentPageUserModel;
