import Validator from 'components/application/Validator';

export default Validator.extend({
    defaults: {
        message: '',

        /**
         * @var {string} key for tracking to define message
         */
        key: '',
        invalidBins: [],
    },

    _validateValue: function (cardNumber) {
        return cardNumber && this._checkBin(cardNumber);
    },

    _checkBin: function (cardNumber) {
        return !_.includes(this.options.invalidBins, this.__getCardBin(cardNumber));
    },

    __getCardBin: function (cardNumber) {
        return cardNumber.substring(0, 6);
    }
});
