/**
 * Need to prevent buggy behavior when user is redirected with reload
 * but still can do unneeded actions (like make payment order multiple times)
 * @deprecated Remove after move payment page to react
 * @param {Event} event
 * @protected
 */
const disableClickHandler = event => {
    event.stopPropagation();
    event.preventDefault();
};

export default disableClickHandler;
