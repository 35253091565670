import Validator from 'app/mobSite/components/application/Validator.js';

var NotZeroValidator = Validator.extend({
    defaults: {
        message: ''
    },

    _validateValue: function (value) {
        return String(value) !== '0';
    }
});
export default NotZeroValidator;
