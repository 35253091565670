import getThemeStyles from 'components/application/getThemeStyles';
import tpl from 'components/application/tpl';
import css from '../styles/PaymentPageExternal.css';
import PaymentPagePendingView from 'components/paymentPageExternal/views/PaymentPagePendingView';
import PaymentPageExternalBasePopupView from 'components/paymentPageExternal/views/PaymentPageExternalBasePopupView';

/**
 * @class PaymentPageExternalPopupView
 * @see BaseView
 */
export default PaymentPageExternalBasePopupView.extend({

    className: css.external,

    options: {
        /**
         * iframe url
         * @type {string}
         */
        url: '',

        /**
         * Custom HTML to append inside iframe.
         * Avoid to use with 'url' option.
         * @type {string}
         */
        html: '',

        /**
         * is this view
         * three days secure popup
         * @type {boolean}
         */
        is3dSecure: false,
    },

    initialize: function () {
        this.model.startListenPostMessage();
        this.listenTo(this.model, 'success fail', this.closePopup);
        this.listenTo(this.model, 'pending', this.__onModelPending);
        this.on('remove', this.onRemove);

        this.themeStyles = getThemeStyles(['Loader']);
    },

    _renderForm: function () {
        this.$('#paymentPageExternalContent').html(tpl.render(require('../templates/PaymentPageExternalPopup.pug'), {
            url: this.options.url
        }));
        this.$('._paymentPageExternalForm').one('load', this.onFormLoad.bind(this));
        this.region.ready();
    },

    /**
     * @public
     */
    onFormLoad: function () {
        const {html} = this.options;
        this.$('._iframeWrap').removeClass(this.themeStyles.loader.active);

        if (html) {
            /**
             * Can't do it in jQuery way with:
             *   this.$('._paymentPageExternalForm').contents().find('html').html(html);
             * Due to problem with wrong attaching of script to parent document
             */
            const doc = this.$('._paymentPageExternalForm')[0].contentWindow.document;

            doc.open();
            doc.write(html);
            doc.close();
        }
    },

    __onModelPending: function() {
        this.closePopup();
        window.app.popup.open(PaymentPagePendingView, {
            title: yiiT.t('paymentPage', 'title.payment_page'),
            model: this.model,
            showCloseButton: false,
            trackingName: 'pendingPage',
            // Temporary solution. After migrate on react set correct popup loading tracking!
            autoLoadTracking: true,
        });
    },

    /**
     * @public
     */
    onRemove: function () {
        this.model.stopListenPostMessage();
        this.model.spaCancelPayment();
    },
});
