import isNull from 'lodash/isNull';

import logger from '@core/logger';
import { getClientInstance } from '@core/graphql/client';
import IS_PAID_QUERY from '@phoenix/application/graphql/queries/isPaid.gql';

import PaymentPageFeaturesModel from 'components/paymentPage/models/PaymentPageFeaturesModel';
import PaymentPageModel from 'components/paymentPage/models/PaymentPageModel';
import PaymentPageRemarketingOfferModel from 'components/paymentPage/models/PaymentPageRemarketingOfferModel';

/**
 * Get payment page model, according user payment status
 * @param {object} params
 * @param {null|bool} isPaid
 * @return {PaymentPageModel}
 */
const getModelByStatus = (params = {}, isPaid = null) => {
    let isUserPaid = isPaid;
    if (isNull(isPaid)) {
        isUserPaid = getClientInstance().readQuery({ query: IS_PAID_QUERY }).payment.accountStatus.isPaid;
    }

    return isUserPaid ?
        window.app.instanceRegister.get('PaymentPageFeaturesModel', PaymentPageFeaturesModel, params) :
        window.app.instanceRegister.get('PaymentPageModel', PaymentPageModel, params);
};

var PaymentPageModelFactory = function() {};

_.extend(PaymentPageModelFactory.prototype, {
    /**
     * @public
     * @param paymentStage {string}
     * @return {*|BaseModel|*}
     */
    getModel: function (paymentStage) {
        if (!paymentStage) {
            logger.sendWarning('Trying to run PaymentPageModelFactory with undefined paymentStage');
        }

        switch (paymentStage) {
            case 'features':
                return window.app.instanceRegister.get('PaymentPageFeaturesModel', PaymentPageFeaturesModel);
            case 'remarketingOffer':
                return window.app.instanceRegister.get('PaymentPageRemarketingOfferModel', PaymentPageRemarketingOfferModel);
            default:
                return window.app.instanceRegister.get('PaymentPageModel', PaymentPageModel);
        }
    },

    /**
     * @public
     * @see getModelByStatus function
     */
    getModelByStatus,
});

/**
 * @static
 * @public
 * @see getModelByStatus function
 * @return {PaymentPageModel}
 */
PaymentPageModelFactory.getModelByStatus = getModelByStatus;

export default PaymentPageModelFactory;
