/**
 * Needed to implement tab and payment model for Sepa alternative payment method experiment
 * with Bank Account Number and Bunk Sort Code fields
 * maintenance.20221114.poliakov.BU_175028
 */
export const ACCOUNT_AND_BANK = 'accountAndBank';

export const ACCOUNT_NUMBER = 'accountNumber';

export const BANK_CODE = 'bankCode';

export const EXTRA_TAB_NAME = 'extraTabName';

/**
 * Value of IBAN active tab
 */
export const IBAN = 'iban';
