import getBrowserDetails from '@core/payment/payProcess/utils/getBrowserDetails';
import isEmpty from 'lodash/isEmpty';
import { CARD_WALLET_FORM_NAME } from '@core/payment/payProcess/constants/formNames';

import currentUserId from 'components/cache/currentUserId';
import siteName from 'components/cache/siteName';
import PaymentPagePaymentBaseModel from './PaymentPagePaymentBaseModel';

/**
 * Wallet Pay payment model. Used to complete payment.
 * @class WalletPayPaymentModel
 * @extends PaymentPagePaymentBaseModel
 */
const WalletPayPaymentModel = PaymentPagePaymentBaseModel.extend({
    urlRoot: '/pay/pay/isJsonMode/true',

    defaults: {
        via: '',
        user_id: '',
        domain: '',
        locale: '',
        country_code: '',
        package_id: '',
        product_id: '',
        currency_code: '',
        hidePaymentForm: 0,
        prevVia: '',
        method: '',
        walletToken: '',
        cardWalletType: null,
    },

    /**
     * @override
     */
    url() {
        return `${this.urlRoot}?via=${this.get('via')}&prevVia=${this.get('prevVia')}`;
    },

    /**
     * @override
     */
    data() {
        const data = {
            ...this.toJSON(),
            browserDetails: getBrowserDetails(),
            user_id: currentUserId(),
        };

        if (!data.domain) {
            data.domain = siteName();
        }

        if (isEmpty(data.selectedAdditionalPackages)) {
            delete data.selectedAdditionalPackages;
        }

        delete data.method;

        const formName = this.get('formName') || CARD_WALLET_FORM_NAME;

        return { [formName]: data };
    },
});

export default WalletPayPaymentModel;
