import t from '@core/translations/translate';
import logger from '@core/logger';

/**
 * @param {String} title
 * @return {String}
 */
const getSubtitleTranslate = title => {
    const translationsMapping = {
        '1 Week Remarketing Membership': () => t('paymentPage', 'title.1 Week Remarketing Membership'),
        '1 Months Remarketing Membership': () => t('paymentPage', 'title.1 Months Remarketing Membership'),
    };

    if (translationsMapping[title]) {
        return translationsMapping[title]();
    }

    logger.sendWarning(`[getSubtitleTranslate] LOST TRANSLATE: (feature: paymentPage, key: ${title})`);

    return title;
};

export default getSubtitleTranslate;
