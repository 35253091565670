import PopupService from '@core/popup/utils/PopupService';
import PaymentPagePaymentBaseModel from 'app/components/paymentPage/models/PaymentPagePaymentBaseModel';
import PaymentPageExternalPopupView from 'app/mobSite/components/paymentPageExternal/views/PaymentPageExternalPopupView';

/**
 * @class PaymentPagePaymentBaseModel
 * @see BaseModel
 */
export default PaymentPagePaymentBaseModel.extend({
    /**
     * @override
     */
    getExternalPayModelWithListeners() {
        if (this.externalModel) {
            this.externalModel.startListenPendingInteraction();
            return this.externalModel;
        }

        PaymentPagePaymentBaseModel.prototype.getExternalPayModelWithListeners.apply(this, arguments);

        return this.externalModel;
    },

    /**
     * @override
     */
    _startExternalPayment: function (html) {
        if (html) {
            document.open();
            document.write(html);
            document.close();
            return;
        }

        // Close popup (such us special offer microfeature popup) before show abort external pay poup
        PopupService.closePopup();

        const is3dSecure = this.is3DSecurePayment();
        const externalListenerModel = this.getExternalPayModelWithListeners();

        externalListenerModel.set({
            orderId: this.get('orderId'),
            action: this.get('via'),
        });

        window.app.popup.open(PaymentPageExternalPopupView, {
            title: yiiT.t('paymentPage', 'title.payment_page'),
            trackingName: 'pending3Ds',
            // Temporary solution. After migrate on react set correct popup loading tracking!
            autoLoadTracking: true,
            url: this.getExternalPaymentUrl(),
            is3dSecure,
            model: externalListenerModel,
            showCloseButton: false,
        });

        this.unset('iframeUrl');
        this.unset('acsUrl');
    },
});
