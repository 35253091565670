import dayjs from '@core/date/utils/dayjs';

import CircleProgressView from './CircleProgressView';

export default class CircleProgressTimerView extends CircleProgressView.extend({
    /**
     * Correct received data, because of different time stamp
     */
    TIME_CORRECTION: 1000,
    MIN_PROGRESS_OFFSET_PART: 0,

    options: {
        timerDuration: 0,
        endTime: 0,
        withPercentage: false,
        size: '',
        /**
         * @type {Number}
         */
        viewBox: 80,
        feature: '',
        rotate: false,
        /**
         * @type {Boolean}
         */
        invert: false,
        /**
         * @type {Boolean}
         */
        inverseColor: false,
        /**
         * @type {Boolean}
         */
        darkSkinTheme: false,
        /**
         * @type {Boolean}
         */
        withTime: false,

        /**
         * use for make infinite timer
         * @type {Boolean}
         */
        infiniteTimer: false,
        /**
         * @type {String}
         */
        startPosition: 'right',

        /**
         * if we need change color of timer or icon in default
         * @type {Boolean}
         */
        primaryColorValue: true,

        /**
         * @type {function}
         */
        onTimerOver: () => {},
    },

}) {
    initialize() {}

    render() {
        this.renderTemplate(this.__getTemplateData());
        this.__startProgress();
        return this;
    }

    /**
     * @return {object}
     * @private
     */
    __getTemplateData() {
        return {
            size: this.options.size,
            viewBox: this.options.viewBox,
            rotate: this.options.rotate,
            feature: this.options.feature,
            startPosition: this.options.startPosition,
            inverse: this.options.inverseColor,
            darkSkinTheme: this.options.darkSkinTheme,
            primaryColorValue: this.options.primaryColorValue,
        };
    }
    /**
     * Method for initializing loader animation
     * @private
     */
    __initPercentageUpdate() {
        if (!this.options.withPercentage && !this.options.withTime) {
            this.$('._percentageOrTime').hide();
        }

        const interval = setInterval(() => {
            let currentPercentage = this.__getCurrentPercentage();

            if (currentPercentage >= 100) {
                this.options.onTimerOver();
                clearInterval(interval);

                if (this.options.infiniteTimer) {
                    this.__reInitTimer();
                }
                return;
            }

            this.__setPercentageOrTime(Math.ceil(currentPercentage));

            if (this.options.invert) {
                currentPercentage = 100 - currentPercentage;
            }

            const offset = (currentPercentage / 100) * (1 - this.MIN_PROGRESS_OFFSET_PART) * this.__circleLength;
            this.__$progressCircle.css({
                'stroke-dashoffset': offset
            });

        }, 500);

        this.on('remove', function () {
            clearInterval(interval);
        });
    }

    /**
     * Current circle can contain percent(as example 30%) or time(as example 00:03).
     * @private
     * @param {Number} percent
     */
    __setPercentageOrTime(percent) {
        if (this.options.withTime) {
            this.$('._percentageOrTime').text(
                dayjs().startOf('day')
                    .second(Math.round(this.options.endTime - new Date().getTime()) / this.TIME_CORRECTION)
                    .format('mm:ss')
            );
        } else {
            this.$('._percentageOrTime').text(percent + '%');
        }
    }

    /**
     * @returns {Number}
     * @private
     */
    __getCurrentPercentage() {
        const currentTime = new Date().getTime();
        // Sometimes timerExpireAt - new Date().getTime() = -1 because some time to execute script.
        // That's why when call new Date().getTime() in different part code we have different value.
        // @see app/components/paymentDiscount/views/PaymentDiscountBannerView.js
        if (this.options.timerDuration <= 0) {
            return 100;
        }
        const currentPercentage = (100 - ((this.options.endTime - currentTime) / this.options.timerDuration) * 100);

        return currentPercentage >= 0 ? currentPercentage : 0;
    }

    __startProgress() {
        this.__$progressCircle.css({
            'stroke-dasharray': this.__circleLength,
            'stroke-dashoffset': this.__startOffset,
        });

        this.__initPercentageUpdate();
    }

    /**
     * re init timer for inifinite counter
     * @private
     */
    __reInitTimer() {
        this.options.endTime = new Date().getTime() + this.options.timerDuration;

        this.__startProgress();
    }
};
