'use strict';

import Backbone from 'backbone';
import BaseSync from 'components/application/BaseSync';

/**
 * @class BaseCollection
 * @extends Backbone.Collection
 */
var BaseCollection = Backbone.Collection.extend(_.extend({}, BaseSync, {
    model: BaseModel,

    /**
     * @param {Object[]|BaseModel[]} models
     * @param {Object|void} options
     */
    constructor: function (models, options) {
        options || (options = {});

        // That's why we've overridden Backbone.Collection.prototype.constructor
        this.options = options;

        if (options.model) this.model = options.model;
        if (options.comparator !== void 0) this.comparator = options.comparator;
        this._reset();
        this.initialize.apply(this, arguments);
        if (models) this.reset(models, _.extend({ silent: true }, options));
    }
}));

export default BaseCollection;
