import {WALLET_METHODS} from '@core/payment/common/constants/paymentMethods';
import {
  APPLE_PAY_TEST_ENVIRONMENT,
  PAY_PAL_TEST_ENVIRONMENT,
  GOOGLE_PAY_TEST_ENVIRONMENT,
} from '@core/payment/common/constants/walletVisualTestCookies';
import {getCookie} from '@core/utils/cookie';
import canMakeApplePayPayments from '@core/payment/widgets/applePay/utils/canMakeApplePayPayments';
import getGooglePayPaymentsClient from '@core/payment/widgets/googlePay/utils/getGooglePayPaymentsClient';

const GET_METHODS_TIMEOUT = 1000;

/**
 * Wallet allowed methods list getter
 * @param {object} altMethodsSettings
 * @param {object} packages
 * @param {object} activePackage
 * @return {Promise<[]>}
 */
async function getWalletAllowedMethods({
  altMethodsSettings,
  packages,
  withPaypal,
  activePackage,
}) {
  const methods = [];

  if (
    getCookie(APPLE_PAY_TEST_ENVIRONMENT) ||
    (altMethodsSettings[WALLET_METHODS.APPLE_PAY] &&
      activePackage?.get('tokenPrice') &&
      (await canMakeApplePayPayments(
        altMethodsSettings[WALLET_METHODS.APPLE_PAY].merchantId
      )))
  ) {
    methods.push(WALLET_METHODS.APPLE_PAY);
  }

  if (
    getCookie(GOOGLE_PAY_TEST_ENVIRONMENT) ||
    (altMethodsSettings[WALLET_METHODS.GOOGLE_PAY] &&
      activePackage?.get('tokenPrice') &&
      Boolean(
        await getGooglePayPaymentsClient(
          altMethodsSettings[WALLET_METHODS.GOOGLE_PAY]
        )
      ))
  ) {
    methods.push(WALLET_METHODS.GOOGLE_PAY);
  }

  if (!withPaypal) {
    return methods;
  }

  if (
    getCookie(PAY_PAL_TEST_ENVIRONMENT) || packages?.[WALLET_METHODS.PAY_PAL]) {
    methods.push(WALLET_METHODS.PAY_PAL);
  }

  if (getCookie(PAY_PAL_TEST_ENVIRONMENT) || packages?.[WALLET_METHODS.PAY_PAL_V2]) {
    methods.push(WALLET_METHODS.PAY_PAL_V2);
  }

  return methods;
}

const withTimeoutPromise = (callback, timeout, fallback) => {
  return async (...props) => {
    const timeoutPromise = new Promise(
      resolve => setTimeout(
        () => resolve(fallback),
        timeout
      )
    );
    return Promise.race([ callback(...props), timeoutPromise ]);
  };
};

export default withTimeoutPromise(getWalletAllowedMethods, GET_METHODS_TIMEOUT, []);
