import Validator from 'app/components/application/Validator';

const EqualValidator = Validator.extend({
    defaults: {
        message: '',
        unequalFields: [],
    },

    /**
     * @override
     */
    validate: function(model, validateAttributes, attributes) {
        const targetName = validateAttributes[0];

        if (!targetName) {
            return;
        }

        const value = _.toLower(attributes[targetName]);
        const values = this.options.unequalFields.map((attr) => _.toLower(attributes[attr]));

        if (values.includes(value)) {
            model.addError(targetName, this.options);
        }
    },
});
export default EqualValidator;
