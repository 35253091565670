import AppView from 'app/components/app/views/AppView.js';

import InstanceRegister from 'components/application/InstanceRegister';
import css from '@phoenix/application/styles/AppLayout.css';

/**
 * Use React + GraphQL instead.
 * @deprecated
 */
export default AppView.extend({

    className: [ css.root, css.fullSize ],

    ready: function () {
        if (!this._isAllowed()) {
            return;
        }

        this.instanceRegister = new InstanceRegister();
        this.initGlobalEvents();
        this._initPaymentPageModelFactory();

        if (window.navigator && window.navigator.connection && window.navigator.connection.type) {
            $.helper.setCookie('networkType', window.navigator.connection.type, { expires: 1 });
        }

        $(window).resize(function () {
            Backbone.trigger('WindowResize');
        });
    },

    initGlobalEvents: function () {
        /**
         * Apply global UI helpers.
         * @todo Move each helper to the corresponding view.
         */
        require('components/application/uiHelpers');
    },

});
