import { ucfirst } from '@core/utils/string';
import logger from '@core/logger';

/*
    In cases of autofill card number input can trigger more than one validation chains, so we
    need to handle not only payment method change but also PaymentPageDirectiveManager reinitializations.
*/
let xhr = null;

const CARD_NUMBER_DEPENDENT_DIRECTIVES = [ 'binInfo', 'binValidation' ];

/**
 * Executes the given directives for the payment page.
 *
 * @param {Object} model - The main model of the payment page.
 * @param {Object} directiveModel - The directive model containing specific data for each directive.
 * @param {Array} directives - The list of directives to be processed.
 * @param {Function} callback - The callback function to be executed after all directives are processed.
 */
const processDirective = (model, directiveModel, directives, callback) => {
    // If all possible directives implemented, run callback
    if (!directives.length) {
        callback();

        return;
    }

    const action = directives.pop() || '';
    const directiveName = `PaymentPage${ucfirst(action)}.js`;
    const next = () => processDirective(model, directiveModel, directives, callback);

    if (CARD_NUMBER_DEPENDENT_DIRECTIVES.includes(action) && !model.get('card_number')) {
        next();

        return;
    }

    try {
        // Try to load directive file and run it
        let directive = require(`components/paymentPage/directives/${directiveName}`);
        // Handle case if directive is es6 module
        if (directive.default) {
            directive = directive.default;
        }

        directive(model, directiveModel.get(action), next);
    } catch (e) {
        // Run next directives and log error
        processDirective(model, directiveModel, directives, callback);

        if (e.code === 'MODULE_NOT_FOUND') {
            logger.sendWarning(
                `PaymentPageDirectiveManager: no method according directive ${action}`
            );
        } else {
            logger.sendError(
                `PaymentPageDirectiveManager: error executing directive ${action} - ${e.message}`
            );
        }
    }
};

/**
 * Manages the payment page directive by fetching or saving the directive model and executing callbacks.
 * @class PaymentPageDirectiveManager
 */
export default class PaymentPageDirectiveManager {
    /**
     * @param {Object} options
     * @param {Object} options.model
     * @param {Object} options.directiveModel
     * @param {Function} options.callback
     */
    constructor(options) {
        this.model = options.model;
        this.directiveModel = options.directiveModel;
        this.callback = options.callback || function () {}; // Can be no callback at all
        this.initialize();
    }

    /**
     * Initializes the method by performing necessary actions.
     *
     * @return {void}
     */
    initialize() {
        this.abort();

        if (this.directiveModel.getIsFetching()) {
            return;
        }

        if (this.directiveModel.get('directive')) {
            this.onDirectiveModelReady(this.model, this.callback)(this.directiveModel);
        } else {
            // TODO: Make difference based on requestType to make save or fetch
            xhr = this.directiveModel.save({}, {
                success: this.onDirectiveModelReady(this.model, this.callback),
                error: () => {
                    this.callback();
                    this.directiveModel.setIsFetching(false);
                },
            });

            this.directiveModel.setIsFetching(true);
        }
    }

    /**
     * Executes the callback function when the directive model is ready.
     *
     * @param {Object} model - The main model.
     * @param {Function} callback - The function to be executed when the directive model is ready.
     *
     * @return {Function} - A function that can be executed to perform post-processing
     * after the directive model is ready.
     */
    onDirectiveModelReady(model, callback) {
        /*
            We run closure here because we want directives run on PP model they were called and not
            replaced with new model (when method or package is changed)
        */
        return directiveModel => {
            const directives = [...directiveModel.get('directive')];

            directiveModel.setIsFetching(false);
            processDirective(model, directiveModel, directives, callback);
            xhr = null;
        };
    }

    /**
     * Abort validation of directives if they are running
     */
    abort() {
        if (xhr) {
            xhr.abort();
            xhr = null;
        }
    }
}
