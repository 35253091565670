import SafeMode from '@phoenix/user/profile/current/constants/SafeMode';

/**
 * @class SafeModeModel
 * @see BaseView
 */
var SafeModeModel =  BaseModel.extend({

    url: '/safeMode/set',

    defaults: {
        /**
         * Property name, of data that will be send to server
         * @type {string}
         */
        dataProperty: 'mode'
    },

    initialize: function() {
        this._setModes();
    },

    data: function() {
        return {
            safeMode: this.get('mode')
        };
    },

    /**
     * @returns {boolean}
     * @public
     */
    isSafeModeFull() {
        return this.get('mode') === SafeMode.FULL;
    },

    /**
     * @returns {boolean}
     * @public
     */
    isSafeModeBasic() {
        return this.get('mode') === SafeMode.BASIC;
    },

    /**
     * Sets all available safeMode types.
     * @private
     */
    _setModes: function() {
        const modes = {
            off: {
                title: yiiT.t('safeMode', 'text.safeMode.off'),
                text: yiiT.t('safeMode', 'text.mode_description.off', { '{tipLink}':
                    this.get('tipLink')
                })
            },
            basic: {
                title: yiiT.t('safeMode', 'text.safeMode.basic'),
                text: yiiT.t('safeMode', 'text.mode_description.basic')
            },
            full: {
                title: yiiT.t('safeMode', 'text.safeMode.full'),
                text: yiiT.t('safeMode', 'text.mode_description.full')
            }
        };

        this.set('title', yiiT.t('safeMode', 'title.safe_mode'));
        this.set('modes', _.pick(modes, this.get('availableModes')));
    }

});

export default SafeModeModel;
