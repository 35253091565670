import Backbone from 'backbone';
import _ from 'underscore';
import BaseSync from './BaseSync';

/**
 * @class BaseModel
 * @extends Backbone.Model
 * @extends BaseSync
 */
var BaseModel = Backbone.Model.extend(_.extend({}, BaseSync, {
    /**
     * Set scenario, use in validate by rules
     *
     * @param {string} scenario validation scenario
     * @returns {BaseModel|*}
     */
    setScenario(scenario) {
        this._scenario = scenario;

        this.trigger('scenarioChanged');

        return this;
    },

    /**
     * Gets current scenario
     *
     * @see setScenario() method
     * @returns {String}
     */
    getScenario: function() {
        return this._scenario;
    },

    /**
     * Remove model without server request
     * @returns {void}
     */
    remove: function() {
        this.stopListening();
        this.trigger('destroy', this, this.collection);
    },

    /**
     * Validate data by rules
     * You can add rules or override this method for custom validation
     *
     * @example
     * var ContactModel = BaseModel.extend(
     *     rules: function() {
     *         return [
     *             {
     *                 attributes: ['reasonId', 'subject', 'message'],
     *                 validator: new RequiredValidator({
     *                     message: yiiT.t('Contact', 'Field is required')
     *                 }),
     *                 scenario: ['feedback', 'complaint']
     *             },
     *             {
     *                 attributes: ['complaintId'],
     *                 validator: new RequiredValidator({
     *                     message: yiiT.t('Contact', 'Complaint type is required')
     *                 }),
     *                 scenario: 'complaint'
     *             }
     *         ];
     *     }
     * );
     *
     * var model = new ContactModel();
     * model.setScenario('feedback').save();
     *
     * @see default validators in the folder: app/components/application/validators
     * You can add custom validators, put it in the "validators" folder in your component
     *
     * @returns {null|object}
     */
    validate: function(attributes, options) {
        if (!this.rules) return null;

        this.clearErrors();

        if (_.isEmpty(this._initializedRules) || (options && options.isNeededChangeOfValidationRules)) {
            this._initializedRules = this.rules();
        }

        this._initializedRules.forEach(function(rule) {
            if (rule.scenario) {
                var scenarioList = _.isArray(rule.scenario) ? rule.scenario : [rule.scenario];
                if (scenarioList.indexOf(this._scenario) == -1) {
                    return;
                }
            }

            // TODO: add description for validateAttributes
            var validateAttributes = (options && options.validateAttributes) ?
                _.intersection(rule.attributes, options.validateAttributes) : rule.attributes;

            rule.validator.validate(this, validateAttributes, attributes);
        }, this);

        return this.hasErrors() ? this.getErrors() : null;
    },

    /**
     * @override
     * @param attrs
     * @param options
     * @returns {boolean}
     * @private
     */
    _validate: function(attrs, options) {
        options = options || {};

        if (Backbone.Model.prototype._validate.apply(this, arguments)) {
            return true;
        }

        // run complete on validation error for same behaviour on request error and validation error
        options.complete && options.complete();
        // and custom handler for validation errors
        options.invalid && options.invalid(this, this.getErrors());

        return false;
    },

    /**
     * Add error for attribute
     *
     * @param {string} attribute
     * @param {string} error
     * @returns {BaseModel}
     */
    addError: function(attribute, error) {
        if (!_.isObject(this.validationError)) {
            this.validationError = {};
        }

        if (!_.isArray(this.validationError[attribute])) {
            this.validationError[attribute] = [];
            /**
             * initialization of 'keys' - array which is using for tracking
             * to avoid passing of big data through tracking
             */
            this.validationError[attribute]['keys'] = [];
        }

        // Lay key with error message, to get it when get an error
        this.validationError[attribute].push(error.message);
        error.key && this.validationError[attribute]['keys'].push(error.key);

        return this;
    },

    /**
     * Returns all errors by last validation
     * Attention: work correctly only if you use rules
     *
     * @example
     * {
     *     attribute1: [
     *         'error1',
     *         'error2'
     *     ]
     *     attribute2: [
     *         'error1'
     *     ]
     * }
     *
     * @returns {Object}
     */
    getErrors: function() {
        return this.validationError;
    },

    /**
     * True if last validation returns errors
     * If you add attribute name, than check errors only for this attribute
     * Attention: work correctly only if you use rules
     *
     * @param {string} attribute name, optional
     * @returns {boolean}
     */
    hasErrors: function(attribute) {
        return !_.isEmpty(this.validationError) && (attribute ? !_.isEmpty(this.validationError[attribute]) : true);
    },

    /**
     * Clear last validation errors
     * Attention: work correctly only if you use rules
     *
     * @returns {BaseModel}
     */
    clearErrors: function() {
        this.validationError = {};
        return this;
    },

    getErrorCode: function(model, response, jqXHR) {
        if(jqXHR && jqXHR.xhr && jqXHR.xhr.status == 200) {
            var responseText = JSON.parse(jqXHR.xhr.responseText);

            if (responseText.meta && responseText.meta.code) {
                return responseText.meta.code;
            }
        } else if(response && response.status) {
            return response.status;
        }
        return null;
    },

    /**
     * For partial validation
     * Cases when we need to validate a few attributes, not all.
     * This behaviour can collect and apply a list of attributes which need to validate
     *
     * Expample: validate only fields which user fill.
     */
    addValidateAttribute: function(name) {
        this._validateAttributes = this._validateAttributes || [];
        this._validateAttributes.push(name);

        return this;
    },

    removeValidateAttribute: function(name) {
        var index = this._validateAttributes && this._validateAttributes.indexOf(name);
        if (index > -1) {
            this._validateAttributes.splice(index, 1);
        }

        return this;
    },

    resetValidateAttributes: function() {
        this._validateAttributes = [];
    },

    getValidateAttributes: function() {
        return this._validateAttributes;
    },

    partialValidate: function(attributes, options) {
        if (_.isEmpty(this._validateAttributes)) {
            return {};
        }

        return this.validate(attributes, _.extend({
            validateAttributes: this._validateAttributes,
            isNeededChangeOfValidationRules: options && options.isNeededChangeOfValidationRules
        }, options));
    },

    /**
     * Changed attribute functionality.
     */
    getChangedAttributes: function() {
        if (!this._changedAttributes) {
            this._changedAttributes = [];
        }

        return this._changedAttributes;
    },

    addChangedAttribute: function(name) {
        var attributes = this.getChangedAttributes();

        if (attributes.indexOf(name) != -1) {
            return;
        }

        attributes.push(name);
    },

    filterChangedAttributes: function(attributes) {
        return _.pick(attributes, this.getChangedAttributes());
    },

    resetChangedAttributes: function() {
        this._changedAttributes = null;
    },

    /**
     * Set attributes to defaults
     * @public
     */
    resetToDefaults: function() {
        this.set(this.defaults);
    },

}));

/**
 * Singleton
 * @param attributes
 * @param options
 * @param isNew
 * @returns {BaseModel|*}
 */
BaseModel.getInstance = function(attributes, options, isNew) {
    if (!this.instance || isNew) {
        this.instance = new this(attributes, options);
    }
    else if (options || attributes) {
        this.instance.set(attributes || options);
    }

    return this.instance;
};

export default BaseModel;
