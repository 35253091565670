export const DONT_PUSH_ROUTES = [
    {
        action: "report",
        name: "user"
    },
    {
        action: "info",
        name: "user"
    },
    {
        action: "trusted",
        name: "pay"
    },
    {
        action: 'retryPermission',
        name: 'pay',
    },
];

export default DONT_PUSH_ROUTES;