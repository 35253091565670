import logger from '@core/logger';
import isGay from '@core/user/utils/isGay';
import isLesbian from '@core/user/utils/isLesbian';

import {
    BACKBONE_PACKAGES_TITLE_TRANSLATES,
    BACKBONE_PACKAGES_TITLE_TRANSLATES_ADULT,
    BACKBONE_PACKAGES_TITLE_TRANSLATES_LESBIAN,
    BACKBONE_PACKAGES_TITLE_TRANSLATES_GAY
} from '@phoenix/payment/common/constants/packageTitleTranslations';

import currentUserGenderAndOrientation from 'components/cache/currentUserGenderAndOrientation';

/**
 * @param {String} title
 * @param {String} packageName
 * @return {String}
 */
const getPaymentPackageShortTranslation = (title, packageName) => {
    const isAdult = window.app.microfeatures && window.app.microfeatures.isAdultDescription();

    const { gender, sexualOrientation } = currentUserGenderAndOrientation();

    if (isLesbian(gender, sexualOrientation) && BACKBONE_PACKAGES_TITLE_TRANSLATES_LESBIAN[title]) {
        return BACKBONE_PACKAGES_TITLE_TRANSLATES_LESBIAN[title];
    }

    if (isGay(gender, sexualOrientation) && BACKBONE_PACKAGES_TITLE_TRANSLATES_GAY[title]) {
        return BACKBONE_PACKAGES_TITLE_TRANSLATES_GAY[title];
    }

    if (isAdult && BACKBONE_PACKAGES_TITLE_TRANSLATES_ADULT[title]) {
        return BACKBONE_PACKAGES_TITLE_TRANSLATES_ADULT[title];
    }
    if (BACKBONE_PACKAGES_TITLE_TRANSLATES[title]) {
        return BACKBONE_PACKAGES_TITLE_TRANSLATES[title];
    }

    /**
     * Check packageName translation, to save backward compatibility
     */
    if (BACKBONE_PACKAGES_TITLE_TRANSLATES[packageName]) {
        return BACKBONE_PACKAGES_TITLE_TRANSLATES[packageName];
    }

    /**
     * @todo All missed translates should be added to BACKBONE_PACKAGES_TITLE_TRANSLATES
     */
    logger.sendWarning(`LOST TRANSLATE: (feature: paymentPagePackage, key: ${title}, key2: ${packageName})`);

    return packageName;
};

export default getPaymentPackageShortTranslation;
