import openLoadingWindow from '@core/utils/routing/openLoadingWindow';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';
import { IN_APP_NAME } from '@core/application/constants/bootstrapParams';

import PaymentPageAlternativePaymentModel from './PaymentPageAlternativePaymentModel';

const PaymentPagePaypalPaymentModel = PaymentPageAlternativePaymentModel.extend({
    FORCE_REDIRECT: 0,

    isExternalPayment() {
        return !this.isOneclick();
    },

    _startExternalPayment() {
        if (this.isOneclick()) {
            return;
        }

        if (this.tab) {
            this.tab.location = this.getExternalPaymentUrl();
        } else {
            window.location = this.getExternalPaymentUrl();
        }
    },

    pay() {
        PaymentPageAlternativePaymentModel.prototype.pay.apply(this, arguments);

        // Dont open loading window for inapp browsers becase they are buggy.
        this.tab = getBootstrapParam(IN_APP_NAME) === InAppBrowserName.NORMAL_BROWSER &&
            this.isExternalPayment() ? openLoadingWindow() : null;
    },
});

export default PaymentPagePaypalPaymentModel;
