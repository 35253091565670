import {IBAN_ONE_CLICK_FORM_NAME} from '@core/payment/payProcess/constants/formNames';

import RemarketingPopupAlternativePayModel from './RemarketingPopupAlternativePayModel';

/**
 * @class RemarketingPopupIbanPayModel
 * @see RemarketingPopupAlternativePayModel
 */
const RemarketingPopupIbanPayModel = RemarketingPopupAlternativePayModel.extend({
    urlRoot: '/pay/ibanOneClick',

    /**
     * @protected
     * @return {String}
     */
    getFormName: function () {
        return IBAN_ONE_CLICK_FORM_NAME;
    },
});

export default RemarketingPopupIbanPayModel;
