import Validator from 'components/application/Validator';

var RequiredValidator = Validator.extend({
    defaults: {
        message: ''
    },

    _validateValue(val) {
        return Boolean(val);
    }
});

export default RequiredValidator;
