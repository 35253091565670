import PaymentPageModel from 'components/paymentPage/models/PaymentPageModel';

/**
 * @class PaymentPageRemarketingOfferModel
 * @see PaymentPageModel
 * @see BaseModel
 */
export default PaymentPageModel.extend({
    urlRoot: '/pay/remarketingOffer/isJsonMode/true',

    actionId: 'remarketingOffer',

    /**
     * There is no motivation scene for RemarketingOffer page
     * @overriden
     */
    getCorrectedScene: function() {
        return '';
    }
});
