import URI from '@core/utils/url';
import getThemeStyles from './getThemeStyles';
const themeStyles = getThemeStyles(['Select']);

$.gotoUrl = function (url, byTopWindow) {
    if (url.startsWith('/pay')) {
        const urlObject = URI(url);
        if (!urlObject.hasSearch('returnPath')) {
            urlObject.addSearch('returnPath', window.location.pathname);
            url = urlObject.toString();
        }
    }

    if (byTopWindow) {
        top.window.location.href = url;
    } else {
        // Check if url isn't the same as pathname
        // for avoiding cyclic redirect
        if (window.location.pathname !== ('/' + url)) {
            window.location.href = url;
        }
    }
};

$.helper = {
    getCookieDomain: function () {
        return '.' + window.location.host.replace(/^m|www\./, '');
    },
    getCookie: function (name) {
        var matches = document.cookie.match(new RegExp(
            '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    setCookie: function (name, value, options = {}) {
        var expires = options.expires;

        if (typeof expires === 'number' && expires) {
            var d = new Date();
            d.setTime(d.getTime() + expires*1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }
        if (options.path === undefined) options.path = '/';

        value = encodeURIComponent(value);

        var updatedCookie = name + '=' + value;

        for (var propName in options) {
            updatedCookie += '; ' + propName;
            var propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += '=' + propValue;
            }
        }

        document.cookie = updatedCookie;
    },
    deleteCookie: function (name) {
        this.setCookie(name, '', { expires: -1 });
    }
};

/**
 * @deprecated
 */
$.fn.tabs = function () {
    this.find('[data-tabs]').each(function () {
        var self = $(this);
        var pages = self.find('[data-tabs-page]');

        pages.on('click', function () {
            pages.removeClass('active');
            $(this).addClass('active');
            self.find('[data-tabs-content]').removeClass('active');
            var currentPage = $(this).attr('data-tabs-page');
            self.find('[data-tabs-content='+currentPage+']').addClass('active');
        });
        self.find('[data-tabs-page]:first').trigger('click');
    });
    return this;
};

/**
 * jQuery plugin for display blocks like accordion
 * @deprecated
 */
(function ($) {
    var methods = {
        /**
         * Init accordion block change event
         */
        init: function (options) {
            var settings = $.extend({
                toggleActiveBlock: true
            }, options);

            var parent = $(this);
            var blocks = $(this).find('[data-accordion-block]');
            blocks.on('click', '[data-accordion-label]', function (e) {
                methods.open.apply(parent, [
                    $(this).parents('[data-accordion-block]'),
                    settings.toggleActiveBlock
                ]);
            });
        },

        /**
         * @param block {object} block which will be open
         * @param toggleActiveBlock {boolean} if true and block is already active - it will be close
         */
        open: function (block, toggleActiveBlock) {
            var blocks = $(this).find('[data-accordion-block]');
            block = $(block);

            if (toggleActiveBlock && block.hasClass('active')) {
                block.removeClass('active');
                return;
            }
            blocks.removeClass('active');
            block.addClass('active');
        }
    };

    $.fn.accordion = function (method) {
        method = method || 'init';
        var params = _.tail(arguments);
        this.find('[data-accordion]').each(function () {
            methods[method].apply(this, params);
        });
        return this;
    };
})($);

/**
 * @deprecated
 */
$.fn.selectWrap = function(params = {}) {
    var selects = this.find('[data-select-block]');
    selects.each(function () {
        var self = $(this);
        var select = self.find('select');
        let isTouched = false;

        select.on('touchstart', ()=>{
            if (!params.isIOS) return;
            isTouched=true;
        });

        select.on('blur', function () {
            const { isIOS, isIOSErr } = params;

            if (!isIOS || isIOSErr || !isTouched ) return;

            // Needed to prevent issue with IOS multi selects data change
            params.isIOSErr = true;
            params.timer = setTimeout(() => {
                params.isIOSErr = false;
            }, 900);
            isTouched =false;
        });

        select.on(`change ${params.isIOS && 'blur'}`, function (e) {
            const { isIOS, isIOSErr, timer } = params;

            // Needed to prevent issue with IOS multi selects data change
            if (isIOS && isIOSErr && e.type ==='change' ) {
                clearTimeout(timer);
                params.isIOSErr= false;
                return;
            }

            var options = select.find('option:selected');
            var value = '';
            if (options.length) {
                value = _.map(select.find('option:selected'), function (option) {
                    return option.text;
                }).join('<br>');
            } else {
                value = select.attr('placeholder');
            }
            if (Number(value)) {
                self.find('._selectBlockValue').removeClass(themeStyles.select.placeholder);
            } else {
                self.find('._selectBlockValue').addClass(themeStyles.select.placeholder);
            }
            self.find('[data-select-block-value]').html(value);
        });
        select.trigger('change');
    });
    return this;
};
