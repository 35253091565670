import isEqual from 'lodash/isEqual';

import scrollSaver from '@core/utils/scroll/ScrollSaver';
import { sessionStorage } from '@core/utils/storage/storage';

import ReverseIterator from 'plugins/iterator/ReverseIterator';

import defaultController from 'components/cache/defaultController';
import currentUserId from 'components/cache/currentUserId';
import { PAY_RESULT_ROUTE } from './MobConstants';

// Back button functionality which tries to cover next use cases:
// - /search -> /chat/with/id -> (back) -> /search
// - /search -> /chat/with/id -> /pay/membership -> (back) -> /chat/with/id -> (back) -> /search

/**
 * Result page visited flag because result page in DONT_PUSH_ROUTES for mob site
 * @type {boolean}
 */
let resultPageVisited = false;

var BackButton = function () {
    this.initialize.apply(this, arguments);
};

_.extend(BackButton.prototype, Backbone.Events, {
    initialize: function (options) {
        this.stack = new BackButtonStack(options);
        this.defaultRoute = (options && options.defaultRoute) || defaultController() || '/search';
        this.DONT_PUSH_ROUTES = options.DONT_PUSH_ROUTES;

        this.listenTo(Backbone, 'ControllerRun', this.pushRoute);

        window.addEventListener('scroll', () => {
            const size = this.stack.size();

            if (size) {
                this.stack.offset(size - 1).scrollTop = $(document).scrollTop();
            }
        });
    },

    pushRoute: function (route) {
        this.stack.push({
            action: route.action,
            name: route.name,
            pathname: location.pathname,
            search: location.search,
            scrollTop: 0
        });
    },

    /**
     * @public
     * @param {Boolean} allowPayment
     * @param {Boolean} skipPaymentRoutes - flag to get last not payment flag
     * @return {Object}
     */
    getBackRoute: function (allowPayment, skipPaymentRoutes) {
        const route = this.stack.pop();
        let uri = route ? route.pathname : this.defaultRoute;
        const scrollTop = route && route.scrollTop || '';

        /**
         * 1 if we stay at current route
         * 2 not pay route flag and last step payment route
         * 3 get last not payment route (s.u. lifetime payment page)
         */
        if (
            this._isCurrentRoute(route) ||
            (!allowPayment && this.isPaymentRoute(route)) ||
            (allowPayment && skipPaymentRoutes && this.isPaymentRoute(route))) {
            return this.getBackRoute(allowPayment, skipPaymentRoutes);
        }

        if (route?.search) {
            uri += route.search;
        }

        return { route, uri, scrollTop };
    },

    /**
     * back to previous page, or default page.
     * see homepage split
     * @param {Boolean} allowPayment
     * @param {Boolean} skipPaymentRoutes
     */
    back: function (allowPayment, skipPaymentRoutes) {
        const { route, uri, scrollTop } = this.getBackRoute(allowPayment, skipPaymentRoutes);

        window.app.router.navigate(uri, { trigger: true });

        if (scrollTop) {
            $(document.body).scrollTop(scrollTop);
            scrollSaver.rememberPosition({element:document.body, alias:route.name, position:scrollTop});
        }
    },

    getPreviousRoute: function () {
        const allRoutes = this.stack.getAllStashedRoutes();

        if (allRoutes.length) {
            const previousRoute = allRoutes[allRoutes.length - 2];
            return previousRoute ? previousRoute.pathname : false;
        }

        return false;
    },

    /**
     * Is result page visited
     * @public
     * @return {boolean}
     */
    isResultPageVisited() {
        return resultPageVisited;
    },

    /**
     * Result page visited false
     * @public
     */
    clearResultPageVisited() {
        this.stack.clearResult();
    },

    _isCurrentRoute: function (route) {
        if (!route || !route.action) return false;
        return route.pathname === window.location.pathname;
    },

    /**
     * @public
     * @param {String} route
     * @return {Boolean}
     */
    isPaymentRoute: function (route) {
        if (!route || !route.name) return false;
        return route.name == 'pay';
    }
});

var BackButtonStack = function () {
    this.initialize.apply(this, arguments);
};

BackButtonStack.prototype = {
    EXCEPTION_PAGES: ['/account/index/page/accountChangeEmail'],
    OVERRIDE_ROUTES: [
        {
            action: 'index',
            name: 'search'
        }
    ],

    initialize: function (options) {
        const id = currentUserId();
        this.storageKey = `BackButtonStack:${id}`;
        this.resultStorageKey = `BackButtonResult:${id}`;
        this.items = sessionStorage.getItem(this.storageKey) || [];
        resultPageVisited = sessionStorage.getItem(this.resultStorageKey, false);
        this.DONT_PUSH_ROUTES = options.DONT_PUSH_ROUTES;
    },

    push: function (route) {
        var length = this.items.length;
        // Do not allow duplication for the last route (except forced this.OVERRIDE_ROUTES)
        var lastPushedRoute = this.items[length - 1];
        if (
            length !== 0 &&
            lastPushedRoute.name === route.name &&
            lastPushedRoute.action === route.action &&
            lastPushedRoute.pathname === route.pathname &&
            this.EXCEPTION_PAGES.indexOf(route.pathname) == -1
        ) {
            if (_.filter(this.OVERRIDE_ROUTES, { action: route.action, name: route.name }).length > 0) {
                lastPushedRoute.pathname = route.pathname;
            }
            return;
        }

        const { action, name, pathname } = route;

        if (_.filter(this.DONT_PUSH_ROUTES, { action, name }).length > 0) {
            if (isEqual(PAY_RESULT_ROUTE, { action, name }) && pathname !== '/pay/result/fail') {
                resultPageVisited = true;

                sessionStorage.setItem(this.resultStorageKey, resultPageVisited);
            }

            return;
        }

        this.items.push(route);

        // Max 10 items.
        if (this.items.length >= 10) {
            this.items.shift();
        }
        sessionStorage.setItem(this.storageKey, this.items);
    },

    pop: function () {
        var route = this.items.pop();
        sessionStorage.setItem(this.storageKey, this.items);
        return route;
    },

    /**
     * remove all history
     */
    clear() {
        this.clearResult();

        this.stack.clear();
    },

    /**
     * @public
     * Clear result page visited flag
     */
    clearResult() {
        resultPageVisited = false;

        sessionStorage.setItem(this.resultStorageKey, resultPageVisited);
    },

    getAllStashedRoutes: function () {
        return this.items;
    },

    /**
     * Get history stack size
     * @public
     * @return {Number}
     */
    size: function () {
        return this.items.length;
    },

    /**
     * Get history stack item by position
     * @public
     * @param {Number} i - item's position number
     * @return {array|null}
     */
    offset: function (i) {
        return this.items[i];
    },

    /**
     * Get back button history stack iterator
     * @public
     * @return {ReverseIterator}
     */
    iterator: function () {
        return new ReverseIterator(this.items.slice(0));
    }
};

export default BackButton;

