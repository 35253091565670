/* eslint-disable prefer-template, no-var */
/* eslint-disable vars-on-top */

/**
 * Version for use only in script for creating the script for injection parameters
 * (In this script you cannot use dependencies and global constants of the webpack since
 * the script will be embedded as a string in the script introduction script with parameters).
 * DO NOT RELY ON BABEL OR WEBPACK TRANSFORMATIONS inside this function,
 * because it's used as a text to inject into js. So USE ONLY CROSS-BROWSER features here.
 * @param {String} hostname
 * @return {{isMobSite: boolean, sitename: string}}
 */
export default function parseHostnameBase(hostname) {
  // Capture subdomain, domain (sitename) and top-level domain name.
  var DOMAIN_REGEXP =
    /((m|www)[.-])?((can|usm|use|uss|act|ant|eur|gbr|lam|int)[.-])?(seduz-me|encontra-me)?(.*?)[.-](net|com[.-]br|com[.-]mx|com[.-]de|com|co[.-]uk|co[.-]za|ch|cl|co|cz|dk|no|se|me|mx|pl|ai|pt|ro|hu|in|it|es|fr|de|be|dating|jp|app|live|ru|kr|sg|tw|hk|ph|id)(\.|-|$)(.*)?/;

  var matches = hostname.match(DOMAIN_REGEXP);

  if (!matches) {
    throw new Error("Can't parse hostname: " + hostname);
  }

  var subDomain = matches[3] ? matches[4] + '.' : '';

  var topLevelDomain = matches[7].replace(/-/, '.');

  var secondLevelDomain = matches[5] || matches[6];

  return {
    isMobSite: matches[2] === 'm',
    sitename: subDomain + secondLevelDomain + '.' + topLevelDomain,
  };
}
