import {getClientInstance} from '@core/graphql/client';
import CURRENT_USER_SEXUAL_ORIENTATION_QUERY
  from '@core/user/profile/current/graphql/queries/currentUserSexualOrientation.gql';

/**
 * Returns current user gender and sexualOrientation from apollo cache.
 * @deprecated
 * @return {Object}
 */
export default () => {
  const { gender, attributes: { sexualOrientation }} =
      getClientInstance().readQuery({ query: CURRENT_USER_SEXUAL_ORIENTATION_QUERY }).myUser.profile;
  return {gender, sexualOrientation};
};
