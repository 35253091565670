import PaymentPageAlternativePaymentModel from 'app/mobSite/components/paymentPageExternal/models/PaymentPageAlternativePaymentModel';
import RequiredValidator from 'app/mobSite/components/application/validators/RequiredValidator.js';
import NumberValidator from 'app/mobSite/components/application/validators/NumberValidator.js';
import RegexpValidator from 'app/mobSite/components/application/validators/RegexpValidator.js';
import StringValidator from 'app/mobSite/components/application/validators/StringValidator.js';
import EMAIL_REGEXP from '@phoenix/payment/forms/common/constants/emailRegExp';
import { PHONE_LENGTH_MIN, PHONE_LENGTH_MAX } from '@phoenix/payment/forms/common/constants/phoneFieldLength';

const PaymentPageKonbiniPaymentModel = PaymentPageAlternativePaymentModel.extend({

    rules: function () {
        return [
            {
                attributes: ['email'],
                validator: new RegexpValidator({
                    message: yiiT.t('paymentPage', 'error.email.invalid'),
                    regexp: EMAIL_REGEXP,
                }),
                scenario: 'initial'
            },
            {
                attributes: ['firstName', 'lastName', 'phoneNumber', 'convenienceStore', 'email'],
                validator: new RequiredValidator({
                    message: yiiT.t('paymentPage', 'error.required_field')
                }),
                scenario: 'initial'
            },
            {
                attributes: ['phoneNumber'],
                validator: new NumberValidator({
                    message: yiiT.t('paymentPage', 'error.phone.invalid')
                }),
                scenario: 'initial'
            },
            {
                attributes: ['phoneNumber'],
                validator: new StringValidator({
                    message: yiiT.t('paymentPage', 'error.phone.length', {
                        '{min}': PHONE_LENGTH_MIN,
                        '{max}': PHONE_LENGTH_MAX
                    }),
                    min: PHONE_LENGTH_MIN,
                    max: PHONE_LENGTH_MAX
                }),
                scenario: 'initial'
            }

        ];
    }
});

export default PaymentPageKonbiniPaymentModel;
