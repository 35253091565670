import paymentPendingVar from '@core/graphql/vars/paymentPendingVar';
import STEPS from '@core/payment/paymentPending/constants/steps';
import trackPaymentPendingPageStep from '@core/tracking/trackPaymentPendingPageStep/trackPaymentPendingPageStep';

import CircleProgressTimerView from 'app/components/progress/views/CircleProgressTimerView';
import {
    removeFocusListeners,
    setFocusListeners,
    setOrderId
} from 'app/components/paymentPageExternal/utils/focusListener';

/**
 * Pending popup, that visualize delay before we  receive answer from processor
 * @see PaymentPageExternalBaseView (for close confirm)
 */
export default BaseView.extend({
    TIMEOUT: 300000,

    options: {
        /**
         * callback for different success logic
         */
        callback: false
    },

    regions: {
        progress: '._progress',
    },

    template: require('../templates/PaymentPagePending.pug'),

    /**
     * @override
     */
    async initialize() {
        this.listenTo(this.model, 'closePendingPopup fail', this.__onModelResults);
        this.on('remove', this.__onRemove);

        this.listenTo(this.model, 'change:orderId', (model, orderId) => setOrderId(orderId));
        setFocusListeners(this.model.get('orderId'));

        this.model.start3dsStatusChecker();
        await this.model.check3dsStatus();
    },

    render: function () {
        this.renderTemplate();
        this.__renderProgress();

        trackPaymentPendingPageStep({
            orderId: this.model.get('orderId'),
            step: STEPS.DISPLAYED,
        });
    },

    /**
     * Render progress
     * @private
     */
    __renderProgress: function() {
        this.regions.progress.render(CircleProgressTimerView, {
            invert: true,
            withTime: true,
            rotate: false,
            size: 'large',
            viewBox: '120',
            startPosition: 'top',
            timerDuration: this.TIMEOUT,
            endTime: new Date().getTime() + this.TIMEOUT,
        });

        this.__startTimeout();
    },

    /**
     * Start timeout handler
     * @private
     */
    __startTimeout: function() {
        this.__timeoutHandler = window.setTimeout(this.__onTimeout.bind(this), this.TIMEOUT);
    },

    /**
     * Timeout handler
     * @private
     */
    __onTimeout: function() {
        this.model.setTimeoutResponse();
    },

    /**
     * Need listen closePendingPopup instead of success to resolve conflict of popups. This view subscribe to success
     * after PaymentPagePaymentBaseModel, that also trigger pay:success and then open microfeatures success popup.
     * @see PaymentPageExternalListenerModel:__successResponse
     * @private
     */
    __onModelResults() {
        window.app.popup.closePopup();
    },

    /**
     * Stop timeout handler.
     * @private
     */
    __onRemove: function() {
        removeFocusListeners();

        if (this.__timeoutHandler) {
            window.clearTimeout(this.__timeoutHandler);
        }

        paymentPendingVar(false);
        this.model.stopListenPendingInteraction();
        this.model.stop3dsStatusChecker();
    },
});
