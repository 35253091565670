import isEmpty from 'lodash/isEmpty';
import isCardHolderValid from '@core/payment/forms/card/utils/isCardHolderValid';

import Validator from 'components/application/Validator';

/**
 *
 */
var CardHolderValidator = Validator.extend({
    defaults: {
        message: '',

        /**
         * @var {string} key for tracking to define message
         */
        key: ''
    },

    _validateValue(value) {
        /**
         * Because it is not a validator for a required field
         * for required field validation use RequiredValidator
         */
        if (isEmpty(value)) {
            return true;
        }

        return isCardHolderValid(value);
    },
});
export default CardHolderValidator;
