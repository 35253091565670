import {toKebabCase} from '@core/utils/string';

(function(){
    'use strict';

    /**
     * Add page and subpage classes to body
     * Classes:
     *  - page - controller name
     *  - subpage - action name. If action is an index, we pass this class.
     *
     * Attention: this method removes any class on body which start on "page-" or "subpage-"
     *
     * @param controller.name controller name
     * @param controller.action action name
     */
    function addBodyClasses(controller) {
        var $body = $('body');

        // remove old classes with prefixes "page-" and "subpage-"
        $body.removeClass(function(index, classes) {
            return classes.split(' ').filter(function(className) {
                return (className.indexOf('is-page-') === 0) || (className.indexOf('is-subpage-') === 0);
            }).join(' ');
        });

        $body.addClass(`is-page-${toKebabCase(controller.name)}`);
        if (controller.action != 'index') {
            $body.addClass(`is-subpage-${toKebabCase(controller.action)}`);
        }
    }

    Backbone.on('ControllerRun', addBodyClasses);
})();