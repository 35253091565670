import t from '@core/translations/translate';
import compactPriceValue, { PRICE_CHAR_THOUSANDS_VALUE } from '@core/billingHistory/utils/compactPriceValue';
import getBenefitTranslation from '@core/payment/widgets/package/utils/getBenefitTranslation';

import {WEEK_SPEAK_PACKAGE_NAME} from "@phoenix/coins/specialOffer/constants/specialOfferPackageNames";

import getPaymentPackageShortTranslation from '../utils/getPaymentPackageShortTranslation';

/**
 * @class PaymentPagePackageModel
 * @extends BaseModel
 */
export default BaseModel.extend({
    /**
     * get package price
     * @returns {object}
     * @public
     */
    getPrice() {
        let currentPrice = this.get('discounted_price') || this.get('price');
        let oldPrice = this.get('discounted_price') ? this.get('price') : false;

        return {
            current: this.__compactPrice(currentPrice),
            old: this.__compactPrice(oldPrice),
        };
    },

    /**
     * @param {string} [amount] - price, which will be formatted (current by default)
     * @public
     */
    getFormattedPrice(amount) {
        return this.formatPrice(amount || this.getPrice().current.amount);
    },

    /**
     * @public
     * @param {number} amount
     * @return {string}
     */
    formatPrice(amount) {
        const literal = this.get('price').currency.literal;

        return (literal.prefix || '') + amount + (literal.suffix || '');
    },

    /**
     * @public
     */
    setActive() {
        this.collection.setActive(this.get('id'), this.get('originalMethod'));
    },

    /**
     * @return {string}
     */
    getTitle() {
        // if package is special week speak - return title
        if (this.get('package_name') === WEEK_SPEAK_PACKAGE_NAME) return this.get('package').title;

        return getPaymentPackageShortTranslation(this.get('package').title, this.get('package_name'));
    },

    /**
     * Format price
     * @param price
     * @return Object {
     *     amount: {number}
     *     per_period: {
     *         hi: {number}
     *         lo: {number}
     *     }
     * }
     * @private
     */
    __compactPrice: function(price) {
        if (!price || !this.get('package').isShortPriceEnabled) {
            return price;
        }

        let per_period = {};

        if (price.per_period.hi.length >= PRICE_CHAR_THOUSANDS_VALUE) {
            per_period.hi = this.compactPriceValue(price.per_period.hi);
            per_period.lo = '';
        } else {
            per_period = price.per_period;
        }

        return {
            amount: this.compactPriceValue(price.amount.split('.')[0]),
            per_period,
        };
    },

    /**
     * @param value {String}
     * @returns {String}
     * @public
     */
    compactPriceValue: function (value) {
        if (!this.get('package').isShortPriceEnabled) {
            return value;
        }

        return compactPriceValue(value);
    },

    /**
     * @return {string}
     * @param benefit {string}
     * @private
     */
    __getBenefitTranslation(benefit) {
        switch (benefit) {
            case 'bonus-message':
                return t('paymentPagePackage', 'title.bonus-message', {
                    '{n}': this.collection.options.paymentModel.getAdditionalPackageByParams(
                        this.get('method'),
                        this.get('package').id,
                        { additionalPackageType: 'message' }
                    )?.messageQuantity || 0,
                });
            default:
                return getBenefitTranslation(benefit);
        }
    },

    /**
     * @public
     * @return {Array}
     */
    getPackageInfo() {
        const benefits = this.get('benefits') || [];
        const additionalBenefits = this.get('additionalBenefits') || [];
        const result = [];

        benefits.forEach(key => {
            const label = this.__getBenefitTranslation(key);

            if (label) {
                result.push({ label, key });
            }
        });

        additionalBenefits.forEach(benefit => {
            result.push({
                label: this.__getBenefitTranslation(benefit),
                key: benefit
            });
        });

        return result;
    },

    /**
     * @param {number|string} price
     * @public
     */
    hasCommaDelimiter: function (price) {
        return String(price).indexOf(',') >= 0;
    },
});
