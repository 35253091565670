import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';

import PaymentPageCardPaymentModel from 'app/mobSite/components/paymentPage/models/PaymentPageCardPaymentModel';
import {DECLINE_VIA} from '@core/payment/common/constants/declineVia';

/**
 * @class MicrofeaturesPaymentModel
 * @extends PaymentPageCardPaymentModel
 */
const MicrofeaturesPaymentModel = PaymentPageCardPaymentModel.extend({

    defaults: {
        /**
         * For payment and decline url
         */
        via: 'microFeatures',

        /**
         * Via for decline page
         * @var {string|null}
         */
        feature: null,

        /**
         * @type {number}
         * flag to repeat credits
         */
        isInstantRepeat: 1,
    },

    /**
     * Since the script will be called after the pop-up for iframe opens,
     * the current pop-up will be no longer available, because this script
     * is driven into the callback function
     * @var {function}
     */
    successCallback: function() {},

    /**
     * @public
     * @param {function} successCallback
     */
    setSuccessCallback: function(successCallback) {
        this.successCallback = successCallback;
    },

    /**
     * @override
     */
    _payExternalSuccessScenario() {
        if (this.successCallback) {
            this.successCallback();
        }
    },

    /**
     * @override
     * @param data {Object} Error response data
     */
    _errorScenario: function (data) {
        MicrofeaturesPaymentModel.__super__._errorScenario.call(this, arguments);
        this.navigateToNextPage();
    },

    /**
     * Setting decline url with an external function so as not to
     * throw a large number of parameters into the model
     * @public
     * @param {string} declineUrl
     */
    setDeclineUrl(declineUrl) {
        this.declineUrl = declineUrl;
    },

    /**
     * @override
     */
    getDeclineUrl: function() {
        return generatePayUrl({
            stage: PAYMENT_ACTIONS.MICRO_FEATURES,
            urlParams: {
                via: DECLINE_VIA,
                prevVia: this.get('feature'),
                packageId: this.get('package_id'),
            },
        });
    },
});

export default MicrofeaturesPaymentModel;
