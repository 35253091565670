import Validator from '../Validator';

export default Validator.extend({
    defaults: {
        message: ''
    },

    _validateValue: function (value) {
        return String(value) !== '0';
    }
});
