import parseHostname from 'util/parseHostname';
import isMobileViewportCached from '@core/responsive/isMobileViewportCached';

/**
 * Renders AppView
 */
export default function renderAppView(addPortal) {
  const {sitename} = parseHostname(window.location.hostname);

  const AppView = isMobileViewportCached()
    ? require('components/app/views/AppMobView').default
    : require('components/app/views/AppWebView').default;

  new AppView({
    id: null,
    el: '#root',
    sitename,
    addPortal,
  });
}
