import paymentPendingVar from '@core/graphql/vars/paymentPendingVar';
import STEPS from '@core/payment/paymentPending/constants/steps';
import trackPaymentPendingPageStep from '@core/tracking/trackPaymentPendingPageStep/trackPaymentPendingPageStep';

import CircleProgressTimerView from 'components/progress/views/CircleProgressTimerView';
import { removeFocusListeners, setFocusListeners, setOrderId } from '../utils/focusListener';
import css from '../../paymentPage/styles/PaymentPage.css';

/**
 * Pending page
 */
const PaymentPagePendingView = BaseView.extend({
    TIMEOUT: 300000,

    regions: {
        header: '._paymentPageHeader',
        progress: '._progress',
    },

    template: require('../templates/PaymentPagePending.pug'),

    /**
     * @see Backbone.View.initialize
     */
    async initialize() {
        this.on('remove', this.__onRemove);
        this.listenTo(this.model, 'success fail', this.__onModelResults);

        this.listenTo(this.model, 'change:orderId', (model, orderId) => setOrderId(orderId));
        setFocusListeners(this.model.get('orderId'));

        this.cssModifiers = {
            [css.columnLayout]: true,
        };

        this.model.start3dsStatusChecker();
        await this.model.check3dsStatus();
    },

    render: function () {
        this.renderTemplate();
        this.__renderProgress();
        this.region.ready();

        trackPaymentPendingPageStep({
            orderId: this.model.get('orderId'),
            step: STEPS.DISPLAYED,
        });
    },

    /**
     * Render progress
     * @private
     */
    __renderProgress: function () {
        this.regions.progress.render(CircleProgressTimerView, {
            invert: true,
            withTime: true,
            roate: false,
            size: 'large',
            viewBox: '120',
            startPosition: 'top',
            timerDuration: this.TIMEOUT,
            endTime: new Date().getTime() + this.TIMEOUT,
        });

        this.__startTimeout();
    },

    /**
     * Start timeout handler
     * @private
     */
    __startTimeout: function() {
        this.__timeoutHandler = window.setTimeout(this.__onTimeout.bind(this), this.TIMEOUT);
    },

    /**
     * Timeout handler
     * @private
     */
    __onTimeout: function() {
        this.model.setTimeoutResponse();
    },

    __onModelResults: function() {
        window.app.popup.closePopup();
    },

    /**
     * Stop timeout handler.
     * @private
     */
    __onRemove: function() {
        removeFocusListeners();

        if (this.__timeoutHandler) {
            window.clearTimeout(this.__timeoutHandler);
        }

        paymentPendingVar(false);
        this.model.stopListenPendingInteraction();
        this.model.stop3dsStatusChecker();
    }
});

export default PaymentPagePendingView;
