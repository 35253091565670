import t from '@core/translations/translate';
import logger from '@core/logger';

/**
 * @param {String} key
 * @param {Object} params
 * @return {String}
 */
const getPageButtonTranslate = (key, params = {}) => {
    const translationsMapping = {
        'text.continue': () => t('paymentPage', 'text.continue'),
        'text.continue_to_payment': () => t('paymentPage', 'text.continue_to_payment'),
        'button.continue_with_ideal': () => t('paymentPage', 'button.continue_with_ideal'),
        'button.continue_with_bancontact': () => t('paymentPage', 'button.continue_with_bancontact'),
        'button.continue_with_eps': () => t('paymentPage', 'button.continue_with_eps'),
        'button.continue_with_sepa': () => t('paymentPage', 'button.continue_with_sepa'),
        'button.pay_now': () => t('paymentPage', 'button.pay_now'),
        'button.order_now_for_payment': () => t('paymentPage', 'button.order_now_for_payment'),
        'button.continue_with_card': () => t('paymentPage', 'button.continue_with_card'),
        'button.pay_with_card': () => t('paymentPage', 'button.pay_with_card'),
        'button.add_to_subscription': () => t('paymentPage', 'button.add_to_subscription'),
        'button.i_accept_and_pay': () => t('paymentPage', 'button.i_accept_and_pay'),
        'button.microIncognito': () => t('paymentPage', 'button.microIncognito'),
        'button.flirtAlarm': () => t('paymentPage', 'button.flirtAlarm'),
        'button.sexAlarm': () => t('paymentPage', 'button.sexAlarm'),
        'button.topInChat': () => t('paymentPage', 'button.topInChat'),
        'button.superBoost': () => t('paymentPage', 'button.superBoost'),
        'button.priorityMan': () => t('paymentPage', 'button.priorityMan'),
        'button.moreLikes': () => t('paymentPage', 'button.moreLikes'),
        'button.primeDater': () => t('paymentPage', 'button.primeDater'),
        'button.topDater': () => t('paymentPage', 'button.topDater'),
        'button.pay_with_paypal': () => t('paymentPage', 'button.pay_with_paypal'),
        'button.freeCommunication': () => t('paymentPage', 'button.freeCommunication'),
        'button.get_it': () => t('remarketingPopup', 'button.get_it'),
        'text.get_it_now': () => t('remarketingPopup', 'text.get_it_now'),
        'button.agree_and_continue': () => t('paymentPage', 'button.agree_and_continue'),
        'button.make_payment': () => t('funnelPaid', 'button.make_payment', params),
    };

    if (translationsMapping[key]) {
        return translationsMapping[key]();
    }

    logger.sendWarning(`[getPageButtonTranslate] LOST TRANSLATE: (feature: paymentPage, key: ${key})`);

    return key;
};

export default getPageButtonTranslate;
