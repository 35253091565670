import PaymentPageFeaturesModel from './PaymentPageFeaturesModel';

/**
 * @class PaymentPageCreditModel
 * @see PaymentPageFeaturesModel
 */
export default PaymentPageFeaturesModel.extend({
    urlRoot: '/pay/credit/isJsonMode/true',

    actionId: 'credit',

    defaults: {
        ...PaymentPageFeaturesModel.prototype.defaults,
        isInstantRepeat: null,
    },

    initialize() {
        PaymentPageFeaturesModel.prototype.initialize.apply(this, arguments);

        this.on('sync', this.__initInstantRepeat);
    },

    /**
     * @override
     * @return {Boolean}
     */
    isAllowedCreditAutofill() {
        const additionalTerms = this.get('additionalTerms');
        const additionalTermsStatusMap = this.get('additionalTermsStatusMap');

        return additionalTerms?.includes('creditAutofillAgreement') &&
          additionalTermsStatusMap?.creditAutofillAgreement;
    },

    /**
     * @private
     */
    __initInstantRepeat() {
        if (this.isAllowedCreditAutofill()) {
            this.set('isInstantRepeat', true);
        }
    },

    /**
     * @override
     * @returns {String}
     */
    getPrevVia() {
        return this.get('prevVia');
    },
});
