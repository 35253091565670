import PaymentPagePaymentBaseModel from './PaymentPagePaymentBaseModel';

/**
 * Midas Pay payment model. Used to complete payment.
 * @class MidasPayPaymentModel
 * @extends PaymentPagePaymentBaseModel
 */
const MidasPayPaymentModel = PaymentPagePaymentBaseModel.extend({
  urlRoot: '/pay/pay/isJsonMode/true',

  defaults: {
    paymentSessionId: '',
    package_id: '',
    via: '',
    prevVia: '',
    hidePaymentForm: 0,
  },

  /**
   * @override
   */
  url() {
    return `${this.urlRoot}?via=${this.get('via')}&prevVia=${this.get('prevVia')}`;
  },

  /**
   * @override
   */
  data() {
    // eslint-disable-next-line camelcase
    const {paymentSessionId, package_id, via, prevVia, hidePaymentForm} =
      this.toJSON();

    return {
      [this.getFormName()]: {
        paymentSessionId,
        // eslint-disable-next-line camelcase
        package_id,
        via,
        prevVia,
        hidePaymentForm,
      },
    };
  },
});

export default MidasPayPaymentModel;
