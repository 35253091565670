import InstanceRegister from 'components/application/InstanceRegister';
import AppView from './AppView';

import css from '@phoenix/application/styles/AppLayout.css';

/**
 * Use React + GraphQL instead.
 * @deprecated
 * @class AppWebView
 * @extends AppView
 */
export default AppView.extend({

    className: [css.root, css.widthLimited],

    ready: function ready() {
        if (!this._isAllowed()) {
            return;
        }

        this.instanceRegister = new InstanceRegister();

        this._initPaymentPageModelFactory();

        if (window.navigator && window.navigator.connection && window.navigator.connection.type) {
            $.helper.setCookie('networkType', window.navigator.connection.type, { expires: 1 });
        }

        $(window).resize(function () {
            Backbone.trigger('WindowResize');
        });
    },
});
