import defer from 'lodash/defer';

import isDeviceWithTouchScreen from '@core/utils/device/isDeviceWithTouchScreen';

import isLargeTabletViewport from 'components/application/isLargeTabletViewport';
import getThemeStyles from './getThemeStyles';
const themeStyles = getThemeStyles([ 'Button', 'Checkbox', 'Input', 'Tab', 'Popover', 'Select', 'Tooltip', 'Radio' ]);
import accordionCss from 'components/mainStyles/styles/Accordion.css';

'use strict';

const closeAllPopovers = () => {
    $('._popover, ._popoverToggle, ._popoverOnHover')
        .removeClass([ 'is-active', getThemeStyles(['Popover']).popover.active ].join(' '));
};

/**
 * UI interactivity implementation for materialUI web skin
 * All code was written in form, that you don't need to initialize in every component your UI. All handlers are placed on document, and delegated
 *
 * TODO: All those code must be rewritten and all widgets must migrate to separated UI components in terms of Backbone.
 */

/**
 * Buttons. Add ripple effect like in material design UI
 * @todo Remove global classes when CSS-modules will be implemented sitewide
 */
$(document).on('click', '.' + themeStyles.button.button, function (e) {
    var $this = $(this);
    var $ripple, d;

    if (!$this.find(themeStyles.button.ripple).length) {
        $this.prepend('<span class="' + themeStyles.button.rippleContainer + '"><span class="' + themeStyles.button.ripple + '"></span></span>');
    }
    $ripple = $this.find('.' + themeStyles.button.ripple);

    if (!$ripple.height() && !$ripple.width()) {
        d = Math.max($this.outerWidth(), $this.outerHeight());
    }
    $ripple.css({
        height: d,
        width: d,
        top: e.pageY - $this.offset().top - d/2 + 'px',
        left: e.pageX - $this.offset().left - d/2 + 'px'
    });

    $this.find('.' + themeStyles.button.rippleContainer).one('animationend webkitAnimationEnd oanimationend MSAnimationEnd', function (e) {
        $(e.currentTarget).remove();
    });
});

/**
 * Checkboxes
 */
$(document).on('change', '._checkbox', function (e) {
    var $this = $(this);
    var $icon = $this.find('i');
    var iconOn = 'icon__checkbox-on';
    var iconOff = 'icon__checkbox-off';
    if (!$this.hasClass(themeStyles.checkbox.disabled)) {
        var isChecked = $this.find('input')[0].checked;
        $this.toggleClass(themeStyles.checkbox.active, isChecked);
        if (isChecked) {
            $icon.removeClass(iconOff).addClass(iconOn);
        } else {
            $icon.removeClass(iconOn).addClass(iconOff);
        }
    } else {
        // Prevent checkbox change
        $this.find('input')[0].checked = false;
        $icon.removeClass(iconOn).addClass(iconOff);
    }
    $this.removeClass(themeStyles.checkbox.focused); // Prevent focusing
    if(!$this.hasClass(themeStyles.checkbox.withoutAnimated)) {
        $this.one('animationend webkitAnimationEnd oanimationend MSAnimationEnd', () => $this.removeClass(themeStyles.checkbox.animated));
        $this.addClass(themeStyles.checkbox.animated);
    }
});

/**
 * Radioboxes
 */
$(document).on('change', '._radio', function (e) {
    var $this = $(this);
    var $icon = $this.find('i');
    var iconOn = 'icon__radio-on';
    var iconOff = 'icon__radio-off';
    if (!$this.hasClass(themeStyles.radio.disabled)) {
        // Remove all classes from same radio-s
        $('input[name="' + $this.find('input').attr('name') + '"]')
          .closest('._radio')
          .removeClass(themeStyles.radio.active)
          .removeClass(themeStyles.radio.animated)
          .find('i').addClass(iconOff).removeClass(iconOn);
        // Add class only to current
        $this.one('animationend webkitAnimationEnd oanimationend MSAnimationEnd', () => $this.removeClass(themeStyles.radio.animated));
        $this.addClass(themeStyles.radio.active).addClass(themeStyles.radio.animated);
        $icon.removeClass(iconOff).addClass(iconOn);
    } else {
        // Prevent checkbox change
        $this.find('input')[0].checked = false;
        $icon.removeClass(iconOn).addClass(iconOff);
    }
    $this.removeClass(themeStyles.radio.focused); // Prevent focusing
});

// Add active classes on ready state
$(document).ready(function () {
    // Need to may this as hook on component render... maybe
    $('._checkbox').each(function () {
        var $this = $(this);
        var $icon = $this.find('i');
        var iconOn = 'icon__checkbox-on';
        var iconOff = 'icon__checkbox-off';
        if ($this.find('input').is(':checked')) {
            $this.addClass(themeStyles.checkbox.active);
            $icon.removeClass(iconOff).addClass(iconOn);
        }
    });
    $('._radio').each(function () {
        var $this = $(this);
        if ($this.find('input').is(':checked')) {
            $this.addClass(themeStyles.radio.active);
        }
    });
});

// Focus state for radio and checboxes
$(document).on('focus', '._checkbox', function () {
    if (isDeviceWithTouchScreen) {
        return;
    }
    $(this).addClass(themeStyles.checkbox.focused);
});
$(document).on('focus', '._radio', function (event) {
    if ($(event.target).is('a') || isDeviceWithTouchScreen) {
        return;
    }
    $(this).addClass(themeStyles.radio.focused);
});

$(document).on('blur', '._checkbox', function () {
    $(this).removeClass(themeStyles.checkbox.focused);
});
$(document).on('blur', '._radio', function () {
    $(this).removeClass(themeStyles.radio.focused);
});

$(document).on('click', '._radio', function (event) {
    if ($(event.target).is('a')) {
        return;
    }
    $(this).addClass(themeStyles.radio.animated).removeClass(themeStyles.radio.focused);
});

$(document).on('mouseenter', '._checkbox ._checkboxContainer', function () {
    if ($(this).hasClass('_preventHover') || isDeviceWithTouchScreen) {
        return;
    }
    $(this).closest('._checkbox').addClass(themeStyles.checkbox.hover);
});
$(document).on('mouseenter', '._radio ._radioContainer', function () {
    if ($(this).hasClass('_preventHover') || isDeviceWithTouchScreen) {
        return;
    }
    $(this).closest('._radio').addClass(themeStyles.radio.hover);
});

$(document).on('mouseleave', '._checkbox ._checkboxContainer', function () {
    $(this).closest('._checkbox').removeClass(themeStyles.checkbox.hover);
});
$(document).on('mouseleave', '._radio ._radioContainer', function () {
    $(this).closest('._radio').removeClass(themeStyles.radio.hover);
});

$(document).on('animationend webkitAnimationEnd oanimationend MSAnimationEnd', '._checkbox ._checkboxContainer', function () {
    $(this).closest('._checkbox').removeClass(themeStyles.checkbox.animated);
});

$(document).on('animationend webkitAnimationEnd oanimationend MSAnimationEnd', '._radio ._radioContainer', function () {
    $(this).closest('._radio').removeClass(themeStyles.radio.animated);
});

/**
 * Inputs
 */
$(document).on('focus', 'input, textarea', function () {
    const inputElement = $(this).closest('._input, ._textarea');

    if (themeStyles.input.touched && !inputElement.hasClass(themeStyles.input.touched)) {
        inputElement.addClass(themeStyles.input.touched);
    }

    inputElement.addClass(themeStyles.input.focused);
});

const blurInput = target => {
    const $element = $(target);

    const hasValue = Boolean($element.val().length);
    $element.closest('._input').toggleClass(themeStyles.input.withValue, hasValue);
    $element.closest('._input, ._textarea').removeClass(themeStyles.input.focused);
};

$(document).on('blur', 'input, textarea', ({ currentTarget }) => blurInput(currentTarget));

// We need to listen to form reset to clean BB inputs states when they are not in focus
$(document).on('reset', 'form', function ({ currentTarget }) {
    // Avoid clearing forms without BB inputs
    if ($(`._input.${themeStyles.input.withValue}, ._textarea`).length) {
        $(currentTarget).find('input, textarea').each(function () {
            // need to delay action because form reset event fires before inputs were cleared
            defer(() => blurInput(this));
        });
    }
});

/**
 * Selects
 */

$(document).on('click', '._select', function (e) {
    var $this = $(this);

    if ($(e.target).closest('[data-select-item]').length) {
        $this.removeClass(themeStyles.select.active);
        return;
    }
    $this.toggleClass(themeStyles.select.active);
});

// Close all possible opened select boxes on outside click
$(document).on('click', function (e) {
    var $target = $(e.target),
      $select = $('._select');

    if (!($target.closest('._select').length)) {
        $select.removeClass(themeStyles.select.active);
    } else {
        $select.not($target.closest('._select')).removeClass(themeStyles.select.active);
    }
});

// Mobile implementation for inputs (handle change events if we click directly in <select> tag)
$(document).on('change', '._selectInput', function (e) {
    var $this = $(this),
      $parent = $this.closest('._select'),
      $selectedValue = $(this).find('option:selected'),
      value = '';

    if ($selectedValue.length) {
        value = _.map($selectedValue, option => option.text).join(', ');
    } else {
        value = $parent.attr('placeholder');
    }

    // replace values quotes to special symbol to avoid bug with no shielded quotes
    value = value && value.replace('"', '&quot;');

    // TODO(ilya.zub): Handle click on active element and do nothing
    $parent.find('._selectValue').html(value);
    $parent.find('[data-select-item]').removeClass(themeStyles.select.active);
    $parent.find('[data-select-item="' + value + '"]').addClass(themeStyles.select.active);
});

// Select item and change main value
$(document).on('click', '[data-select-item]', function (e) {
    var $this = $(this),
      $select = $this.closest('._select');

    // TODO(ilya.zub): Handle click on active element and do nothing
    $select.find('._selectInput').val($this.data('select-item')).trigger('change');
    $select.find('._selectValue').html($this.html());
    $select.find('[data-select-item]').removeClass(themeStyles.select.active);
    $select.find('[data-select-item="' + $this.data('select-item') + '"]').addClass(themeStyles.select.active);
});

/**
 * Tabs
 */
$(document).on('click', '._tabItem', function (e) {
    var $this = $(this),
      $siblingTab = $this.siblings();

    $siblingTab.removeClass(themeStyles.tab.active);
    $siblingTab.each(function (index, item) {
        var $currentItem = $(item);
        if ($currentItem.hasClass('._tabItem')) {
            $($currentItem.filter('._tabItem')).addClass(themeStyles.tab.hidden);
        }
    });

    $($this.filter('._tabItem')).removeClass(themeStyles.tab.hidden);
    $this.addClass(themeStyles.tab.active);
});

/**
 * Accordion
 */
$(document).on('click', '._accordionToggle', function (e) {
    var $this = $(this),
      $currentBlock = $this.closest('._accordionBlock');

    $this.closest('._accordion').find('._accordionBlock').removeClass(accordionCss.toggled);
    $currentBlock.addClass(accordionCss.toggled);
});

/**
 * Tooltips
 */
$(document).on('mouseenter', '._tooltipHover', function () {
    $(this).find('._tooltip').addClass(themeStyles.tooltip.active);
});

$(document).on('mouseleave', '._tooltipHover', function () {
    $(this).find('._tooltip').removeClass(themeStyles.tooltip.active);
});

/**
 * Popovers
 */
// Popover can be called by hover and by click
$(document).on('click', '._popoverToggle', function (e) {
    var $this = $(this);

    if ($(e.target).is('._tooltip')) {
        return;
    }

    /**
     * check isEnabled dynamic popover's orientation
     */
    if ($this.data('dynamic') !== undefined) {
        const popoverMenu = $this.children('._popover');
        const dynamicWrapperParent = $this.closest('[data-dynamic-wrap]').parent();
        const mainWrapperHeight = dynamicWrapperParent.parent().height();

        const thisWidgetInfoOffset = dynamicWrapperParent.position().top;
        const popoverMenuHeight = popoverMenu.height();

        popoverMenu.removeClass(themeStyles.popover.topRight + ' ' + themeStyles.popover.bottomRight);

        /**
         * check the difference between popover menu's height
         * and popover menu wrap's offset from top container's edge
         * to set correct popover's orientation
         */
        if ((popoverMenuHeight + thisWidgetInfoOffset + dynamicWrapperParent.height()) > mainWrapperHeight) {
            popoverMenu.addClass(themeStyles.popover.topRight);
        } else {
            popoverMenu.addClass(themeStyles.popover.bottomRight);
        }
    }

    if (
      !$(e.target).closest('._popover').length &&
      !$(e.target).closest('._popoverOnHover').length &&
      !($this.hasClass(themeStyles.popover.active)) &&
      !($this.hasClass(themeStyles.popover.disabled))
    ) {
        closeAllPopovers();
        // Set active only current popover, prevent opening popovers inside this popover
        // We have to fire trigger on DOM object to handle event when popover is activated
        $this.addClass(themeStyles.popover.active).trigger('activatePopover');
        $this.find('._popover:first').addClass(themeStyles.popover.active);
    } else if (
      !$(e.target).closest('._popover').length &&
      ($this.hasClass(themeStyles.popover.active))
    ) {
        closeAllPopovers();
    }
});

$(document).on('click', '._popoverClose', closeAllPopovers);

/**
 * if we in tablet device we must change mouse events on clicks for better user expirience of user
 * we must on tablet change mouse event on click events for hover popovers
 * touchstart event for ipad
 */
if (isLargeTabletViewport()) {
    const clickEventType = document.ontouchstart !== null ? 'click' : 'touchstart';

    $(document).on(clickEventType, '._popoverHover', function (e) {
        if ($(this).closest('._popoverToggle').length || $(this).hasClass('._popoverToggle')) {
            return;
        }

        const $popoverContainer = $(this).find('._popoverOnHover');

        closeAllPopovers();

        if (!$popoverContainer.hasClass(themeStyles.popover.active)) {
            $popoverContainer.addClass(themeStyles.popover.active);
        }
    });
} else if (!isDeviceWithTouchScreen) {
    $(document).on('mouseenter', '._popoverHover', function () {
        $(this).find('._popoverOnHover').addClass(themeStyles.popover.active);
    });

    $(document).on('mouseleave', '._popoverHover', function () {
        $(this).find('._popoverOnHover').removeClass(themeStyles.popover.active);
    });
}


// Close all possible opened popovers on outside click
// touchstart event for ipad
$(document).on('click touchstart', function (e) {
    var $target = $(e.target);

    /**
     * while we have mixed popovers in hierarchy of elements, we must knew that
     * is tablet device and it popover has closest _popoverHover
     */
    if (isLargeTabletViewport() && $target.closest('._popoverHover').length) {
        if (!($target.closest('._popoverHover').length || $target.hasClass('._popoverHover'))) {
            closeAllPopovers();
        }
        return;
    }

    if (!($target.closest('._popoverToggle').length || $target.hasClass('._popoverToggle'))) {
        closeAllPopovers();
    }
});

$.fn.selectRange = function () {
    var $selectFrom = this.find('._selectFrom');
    var $selectTo = this.find('._selectTo');
    var $selectFromInput = $selectFrom.find('._selectInput');
    var $selectToInput = $selectTo .find('._selectInput');

    $(document).on('change', [ $selectFromInput, $selectToInput ], function () {
        var minValue = $selectFromInput.val();
        var maxValue = $selectToInput .val();

        $selectFrom.find('[data-select-item]').each(function (number, el) {
            $(el).data('select-item') > maxValue ? $(el).hide() : $(el).show();
        });
        $selectTo .find('[data-select-item]').each(function (number, el) {
            $(el).data('select-item') < minValue ? $(el).hide() : $(el).show();
        });
    }).trigger('change');

    return this;
};
