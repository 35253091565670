import Validator from 'app/mobSite/components/application/Validator.js';

var InvalidBinValidator = Validator.extend({
    defaults: {
        message: '',
        invalidBins: [],
    },

    _validateValue: function (cardNumber) {
        return cardNumber && this.__checkBin(cardNumber);
    },

    __checkBin: function (cardNumber) {
        return !_.includes(this.options.invalidBins, this.__getCardBin(cardNumber));
    },

    __getCardBin: function (cardNumber) {
        return cardNumber.substring(0, 6);
    }
});
export default InvalidBinValidator;
